import Table from "app/components/commonComponent/table/Table";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import ReimbursementInvoiceTableRow from "./ReimbursementInvoiceTableRow";
import { searchParams } from "app/helpers/commonInterface";
import { getReimbursementInvoiceList } from "app/reducers/reimbursement/reimbursementAction";
import ReimbursementInvoiceForm from "../ReimbursementInvoiceForm";
import { useGetSearchObject } from "app/helpers/customHooks";
import { ClassNames } from "@emotion/react";

interface props {
  reimbursementInvoiceDetails: any;
  reimbursementInvoiceListDispatch: Function;
}

const HEADERS = [
  {
    columnName: "Reimbursement No.",
    id: "BillNumber",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Client Name",
    id: "ClientName",
    className: "min-w-125px",
  },
  {
    columnName: "Task",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Firm",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Total Amount",
    id: "TotalAmount",
    className: "min-w-125px",
  },
  {
    columnName: "Due Amount",
    id: "PaidAmount",
    className: "min-w-125px",
  },
  {
    columnName: "Date",
    id: "Date",
    className: "min-w-125px",
  },
  {
    columnName: "Billing Status",
    id: "PaymentStatus",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-125px text-end",
  },
];

const ReimbursementInvoiceTable: FC<props> = ({
  reimbursementInvoiceDetails,
  reimbursementInvoiceListDispatch,
}) => {
  const {searchObj} = useGetSearchObject();
  const [showBillEditForm, setShowBillEditForm] = useState<{
    show: boolean;
    billId: number | undefined;
  }>({
    show: false,
    billId: 0,
  });

  useEffect(() => {
    reimbursementInvoiceListDispatch(searchObj);
  }, [reimbursementInvoiceListDispatch, searchObj]);

  return (
    <>
      <Table
        checkBoxColumn={{ show: false }}
        error={reimbursementInvoiceDetails.error}
        headers={HEADERS}
        success={reimbursementInvoiceDetails.reimbursementInvoiceList?.success}
        loading={reimbursementInvoiceDetails.loading}
        paginate={{
          show: true,
          pagination: reimbursementInvoiceDetails.reimbursementInvoiceList?.pagination,
          PageNumber: Number(searchObj.PageNumber || 1),
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={reimbursementInvoiceDetails.reimbursementInvoiceList?.data?.records}
        tableRow={(row: any, index: number) => (
          <ReimbursementInvoiceTableRow
            row={row}
            key={`row-${index}-${row.id}`}
            setShowBillEditForm={setShowBillEditForm}
          />
        )}
      />

      <ReimbursementInvoiceForm
        showInvoice={{
          show: showBillEditForm.show,
          billId: showBillEditForm.billId,
        }}
        closeModel={() => setShowBillEditForm({ show: false, billId: 0 })}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    reimbursementInvoiceDetails: state.reimbursementInvoiceList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    reimbursementInvoiceListDispatch: (searchObj: searchParams) =>
      dispatch(getReimbursementInvoiceList(searchObj)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReimbursementInvoiceTable);
