import { ResetButton } from "app/components/commonComponent/buttons/Buttons";
import { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

interface props {
  show: boolean;
  onHide: any;
  onConfirm: any;
}

export const CancelTransactionPopup: FC<props> = ({
  show,
  onHide,
  onConfirm,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsDisabled(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>Cancel Transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body className="fs-5 fw-semibold">
        Are you sure you want to cancel this transaction?
      </Modal.Body>
      <Modal.Footer>
        <ResetButton
          className="btn btn-light btn-sm btn-active-light-primary"
          name="No"
          onClickCallback={() => onHide()}
        />
        <ResetButton
          className="btn btn-danger btn-sm btn-active-dark-danger"
          name="Yes"
          onClickCallback={() => onConfirm()}
        />
      </Modal.Footer>
    </Modal>
  );
};
