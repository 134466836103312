import { connect } from "react-redux";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { absolutePath } from "../../../../helpers/relativePath";
import { EDIT_TASK_TYPE } from "../../../../helpers/routes";
import { updateTaskTypeStatus } from "../../../../reducers/taskType/taskTypeAction";
import { changeTextToFirstUppercase } from "../../../../helpers/helperFunction";
import { KTSVG } from "../../../../../_metronic/helpers";
import StatusCell from "../../../commonComponent/table/StatusCell";
import UserActionsCell from "../../../commonComponent/table/UserActionsCell";
import { PERIOD } from "app/helpers/constants";

interface row {
  Name: string;
  IsRepeatative: false;
  CategoryName: string;
  Id: number;
  Repeatation: string;
  Period: number;
  IsActive: boolean;
}
interface props {
  row: row;
  updateTaskTypeStatusDispatch: Function;
}

const TableRow: FC<props> = ({ row, updateTaskTypeStatusDispatch }) => {
  const navigate = useNavigate();

  const editRecord = () => {
    navigate(absolutePath(`${EDIT_TASK_TYPE}/${row.Id}`));
  };

  const updateList = (id: number, callBack: Function) => {
    updateTaskTypeStatusDispatch(row.Id, callBack);
  };

  return (
    <tr>
      <td className="ps-2">{row?.Name || "-"}</td>
      <td>{row.CategoryName || "-"}</td>
      <td>
        {row?.IsRepeatative
          ? `${changeTextToFirstUppercase(row.Repeatation)} (Repetitive)`
          : "One Time Task"}
      </td>
      <td>{PERIOD[row.Period] || "-"}</td>

      <td>
        <StatusCell isActive={row.IsActive} />
      </td>
      <td>
        <div className="d-flex gap-3 justify-content-end pe-5">
          <button
            className={`p-2 btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
            title="Edit"
            onClick={editRecord}
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
          <UserActionsCell
            updateStatusDispatch={updateList}
            isActive={row?.IsActive}
            id={row?.Id}
          />
        </div>
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTaskTypeStatusDispatch: (typeId: number, successCallback: Function) =>
      dispatch(updateTaskTypeStatus(typeId, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(TableRow);
