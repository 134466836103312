// import { SidebarMenuMain } from "./SidebarMenuMain";

// const SidebarMenu = () => {
//   // flex-column-fluid
//   return (
//     <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
//       <div
//         // style={{ height: "770px" }}
//         id="kt_app_sidebar_menu_wrapper"
//         // className="app-sidebar-wrapper hover-scroll-overlay-y mt-5"
//         className="app-sidebar-wrapper hover-scroll-overlay-y my-5 overflow-auto"
//         data-kt-scroll="true"
//         data-kt-scroll-activate="true"
//         data-kt-scroll-height="auto"
//         data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
//         data-kt-scroll-wrappers="#kt_app_sidebar_menu"
//         data-kt-scroll-offset="5px"
//         data-kt-scroll-save-state="true"
//       >
//         <div
//           className="menu menu-column menu-rounded menu-sub-indention px-3"
//           id="#kt_app_sidebar_menu"
//           data-kt-menu="true"
//           data-kt-menu-expand="false"
//         >
//           <SidebarMenuMain />
//         </div>
//       </div>
//     </div>
//   );
// };

// export { SidebarMenu };


import { useEffect } from "react";
import { SidebarMenuMain } from "./SidebarMenuMain";
declare global {
  interface Window {
    KTScroll: any;
    KTMenu: any;
  }
}


const SidebarMenu = () => {
  useEffect(() => {
    const updateSidebarHeight = () => {
      const sidebarWrapper = document.getElementById("kt_app_sidebar_menu_wrapper");
      if (sidebarWrapper) {
        const windowHeight = window.innerHeight;
        const headerHeight = document.getElementById("kt_app_sidebar_logo")?.offsetHeight || 0;
        const footerHeight = document.getElementById("kt_app_sidebar_footer")?.offsetHeight || 0;
        const padding = 20; 
  
        const calculatedHeight = windowHeight - (headerHeight + footerHeight + padding);
        sidebarWrapper.style.height = `${calculatedHeight}px`;
      }
      
      if (window.KTScroll) {
        window.KTScroll.update();
      }
    };
  
    updateSidebarHeight();
    window.addEventListener("resize", updateSidebarHeight); 
  
    return () => {
      window.removeEventListener("resize", updateSidebarHeight);
    };
  }, []);
  
  
  return (
    <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
      <div
        id="kt_app_sidebar_menu_wrapper"
        className="app-sidebar-wrapper hover-scroll-overlay-y my-5 overflow-auto"
        data-kt-scroll="true"
        data-kt-scroll-activate="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
        data-kt-scroll-wrappers="#kt_app_sidebar_menu"
        data-kt-scroll-offset="5px"
        data-kt-scroll-save-state="true"
      >
        <div
          className="menu menu-column menu-rounded menu-sub-indention px-3"
          id="kt_app_sidebar_menu"
          data-kt-menu="true"
          data-kt-menu-expand="false"
        >
          <SidebarMenuMain />
        </div>
      </div>
    </div>
  );
};

export { SidebarMenu };

