import { connect } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC } from "react";
import { object, string, number, date } from "yup";
import { useNavigate } from "react-router-dom";

import { addUserData } from "../../../helpers/commonInterface";
import { addUserAction } from "../../../reducers/user/userAction";
import { adminHomePage } from "../main/admin/AdminMain";
import { absolutePath } from "../../../helpers/relativePath";
import BankDetailsForm from "./component/BankDetailsForm";
import { emptyObject } from "../../../helpers/helperFunction";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import hasPermission, { actionsRole } from "../auth/core/permission";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { USER_LIST } from "../../../helpers/routes";
import { userListPage } from "./UserList";
import UserDetailsForm from "./component/UserDetailsForm";
import { useSendDataChangeWarning } from "app/helpers/webSocketHelper";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";

interface props {
  addUserDispatch: Function;
  profileDetails: any;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

export interface addUserInitialValue {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  password: string;
  registrationDate: string;
  joiningDate: string;
  ifscCode: string;
  bankName: string;
  accountNumber: string;
  accountType: string;
  phoneNumber: string;
  branchName: string;
  companyName: string;
  paymentAccess : boolean;
  transactionAccess: boolean;
}

const getInitialValue = (companyName: string): addUserInitialValue => {
  return {
    firstName: "",
    lastName: "",
    email: "",
    role: "User",
    password: "",
    registrationDate: "",
    joiningDate: "",
    ifscCode: "",
    bankName: "",
    accountNumber: "",
    accountType: "",
    phoneNumber: "",
    branchName: "",
    companyName: hasPermission(actionsRole.MASTER_ADMIN) ? "" : companyName,
    paymentAccess: false,
    transactionAccess: false,
  };
};

const validateFun = object().shape({
  firstName: string().trim().required("First name is required"),
  lastName: string().trim().required("Last name is required"),
  email: string().trim().email("Wrong email format").required("Email is required"),
  phoneNumber: number()
    .test("length", "Phone number is not valid", (value) => {
      if (value && value.toString().length > 10) return false;
      return true;
    })
    .required("Phone number is required"),
  registrationDate: date().required("Registration date is required"),
  joiningDate: date().required("Joining date is required"),
  ifscCode: string().required("IFSC code is required"),
  bankName: string().required("Bank name is required"),
  branchName: string().required("Branch name is required"),
  accountType: string().required("Account type is required"),
  accountNumber: number().required("Account number is required"),
  password: string()
    .required("Password is required")
    .matches(
      /(?=.*[A-Z])(?=.*\W+)(?=.*\d+).+/,
      "Password must contain one uppercase, one number and one special case character"
    ),
  companyName: string()
    .nullable()
    .test("required", "Company name is required", (value) => {
      if (!value && hasPermission(actionsRole.MASTER_ADMIN)) {
        return false;
      }
      return true;
    }),
});

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  userListPage,
  emptyObject,
];

const AddUser: FC<props> = ({ addUserDispatch, profileDetails,masterDataList}) => {
  const navigate = useNavigate();
  const sendDataChangeWarning = useSendDataChangeWarning();

  return (
    <ErrorBoundary>
      <div className="container-fluid px-0">
        <PageTitle breadcrumbs={accountBreadCrumbs}>Add User</PageTitle>
        <div className="card">
          <Formik
            enableReinitialize
            initialValues={getInitialValue(
              profileDetails?.profileData?.CompanyName
            )}
            validationSchema={validateFun}
            onSubmit={async (values, { resetForm }) => {
              await addUserDispatch(values, () => {
                resetForm();
                sendDataChangeWarning();
              });
            }}
          >
            {({ values, touched, errors, isSubmitting, setFieldValue }) => {
              return (
                <Form noValidate className="form">
                  <div className="p-7">
                    {hasPermission(actionsRole.MASTER_ADMIN) && (
                      <div className="row mb-6">
                        <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                          Company Name
                        </label>

                        <div className="col-lg-8 fv-row">
                          <Field
                            type="text"
                            className={`form-control form-control-lg form-control-solid 
                            ${
                              touched.companyName &&
                              errors.companyName &&
                              "is-invalid inValidBorder"
                            }`}
                            placeholder="Company Name"
                            id="companyName"
                            name="companyName"
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name="companyName"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                    )}
                    <UserDetailsForm
                      values={values}
                      touched={touched}
                      isSubmitting={isSubmitting}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      masterDataList={masterDataList}
                    />
                    <BankDetailsForm
                      values={values}
                      touched={touched}
                      isSubmitting={isSubmitting}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className="d-flex justify-content-end py-6 px-6 gap-3">
                    <ResetButton
                      name="Cancel"
                      className="btn btn-light btn-active-light-primary"
                      onClickCallback={() => navigate(absolutePath(USER_LIST))}
                    />
                    <SubmitButton
                      className="btn btn-primary"
                      isSubmitting={isSubmitting}
                      name="Submit"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </ErrorBoundary>
  );
};
const mapStateToProps = (state: any) => {
  return {
    profileDetails: state.profile,
    masterDataList: state.masterData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addUserDispatch: (data: addUserData, successCallback: Function) =>
      dispatch(addUserAction(data, successCallback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
