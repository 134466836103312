import { Form, Formik,  FormikValues } from "formik";
import { FC, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import DropDown from "../../../commonComponent/dropDown/DropDown";
import FilterButton from "../../../commonComponent/tableFilter/FilterButton";
import { getSearchParameter } from "../../../../helpers/helperFunction";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { useOutsideAlerter } from "app/helpers/customHooks";

interface props {}

interface userFilterInitialValue {
  role: string;
}

const OPTIONS = [
  { value: "Admin", name: "Admin" },
  { value: "User", name: "Article" },
  { value: "Team Leader", name: "Team Leader" },
];

const UserFilter: FC<props> = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  const searchObj = useMemo(
    () => getSearchParameter(searchParam),
    [searchParam]
  );
  const [showDropDown, setShowDropDown] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, setShowDropDown);

  const resetHandler = (resetForm: any) => {
    delete searchObj.role;
    resetForm();
    setSearchParam({ ...searchObj, PageNumber: "1" });
  };

  const initialValue: userFilterInitialValue = {
    role: searchObj?.role || "",
  };

  return (
    <div className="position-relative" ref={wrapperRef}>
      <FilterButton
        setShowDropDown={setShowDropDown}
        showDropDown={showDropDown}
      />
      <div
        className={`card shadow-sm w-300px w-md-325px task-filter ${
          showDropDown ? "" : "hidden"
        }`}
      >
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">Filter</div>
        </div>
        <div className="separator border-gray-200"></div>
        <div className="px-7 py-5" data-kt-user-table-filter="form">
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={async (values) => {
              setSearchParam({
                ...searchObj,
                PageNumber: "1",
                role: values.role,
              });
            }}
          >
            {({ resetForm, values, isSubmitting, setFieldValue }) => {
              return (
                <Form noValidate className="form">
                  <div className="mb-10">
                    <label className="form-label fs-6 fw-bold">Role</label>
                    <DropDown
                      className="text-start form-control form-control-lg form-control-solid form-select"
                      placeholder="Select Role"
                      options={OPTIONS}
                      setFieldValue={setFieldValue}
                      name="role"
                      currentValue={{ value: (values as FormikValues).role }}
                      defaultValue={{
                        value: searchObj.role,
                        name: OPTIONS.find(
                          (option: { value: string; name: string }) =>
                            option.value === searchObj.role
                        )?.name,
                      }}
                      showSearch={true}
                    />
                  </div>
                  <div className="d-flex justify-content-end gap-3">
                    <ResetButton
                      name="Reset"
                      className="btn btn-light btn-active-light-primary btn-sm"
                      onClickCallback={() => {
                        resetHandler(resetForm);
                        setShowDropDown(false);
                      }}
                    />
                    <SubmitButton
                      className="btn btn-primary btn-sm"
                      isSubmitting={isSubmitting}
                      name="Apply"
                      onClickCallback={() => setShowDropDown(false)}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default UserFilter;
