import { FC, useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../components/pages/errors/ErrorsPage";
import {
  Logout,
  AuthPage,
  useAuth,
  setupAxios,
} from "../components/pages/auth";
import App from "../../App";
import Error404 from "../components/pages/errors/components/Error404";
import { HOME_PAGE, RESET_PASSWORD_PAGE } from "../helpers/routes";
import ResetPassword from "app/components/pages/auth/components/ResetPassword";
import SessionExpired from "app/components/commonComponent/SessionExpired";

const AppRoutes: FC = () => {
  const location = useLocation();
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [sessionExpiredDailog, setSessionExpiredDailog] = useState({
    show: false,
  });

  const sessionExpired = () => {
    //setSessionExpiredDailog({ show: true });
    logout();
  };

  const closeModal = () => {
    setSessionExpiredDailog({ show: false });
    navigate(`/auth/login?redirectTo=${location.pathname}`);
    // , {state : {redirectTo : location.pathname}}
  };

  useEffect(() => {
    setupAxios(sessionExpired);
  }, [navigate, logout]);

  return (
    <>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          <Route path={RESET_PASSWORD_PAGE} element={<ResetPassword />} />
          {currentUser ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to={HOME_PAGE} />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
      <SessionExpired closeModal={closeModal} session={sessionExpiredDailog} />
    </>
  );
};

export { AppRoutes };
