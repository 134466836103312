import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC } from "react";
import { object, number, string } from "yup";
import { useNavigate } from "react-router-dom";

import { adminHomePage } from "../../main/admin/AdminMain";
import { absolutePath } from "../../../../helpers/relativePath";
import CustomDatePicker from "app/components/commonComponent/datePicker/CustomDatePicker";
import DropDown from "../../../commonComponent/dropDown/DropDown";
import { emptyObject } from "../../../../helpers/helperFunction";
import {
  PageLink,
  PageSubTitle,
  PageTitle,
} from "../../../../../_metronic/layout/core";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { taskListPage } from "../TaskList";
import { dueDateChangeValue, taskEditValue } from "../TaskForm";
import { TASK_LIST } from "../../../../helpers/routes";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import { taskData } from "app/reducers/task/taskDataInterface";
import hasPermission, { actionsRole } from "../../auth/core/permission";
import { getAuth } from "../../auth";
import { REQUEST_STATUS } from "app/helpers/constants";
import { initial } from "lodash";
import hasPaymentAccess from "../../auth/core/paymentAccess";

interface props {
  taskDispatch: Function;
  dueDateChangeRequestDispatch: Function;
  initialValue: taskEditValue;
  title: string;
  taskDetails?: taskData;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

const validateFun = object().shape({
  clientId: number()
    .positive("Client name is required")
    .required("Client name is required"),
  //assigneeId: string().required("Article name is required"),
});

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  taskListPage,
  emptyObject,
];

const EditTask: FC<props> = ({
  masterDataList,
  taskDetails,
  initialValue,
  taskDispatch,
  dueDateChangeRequestDispatch,
  title,
}) => {
  const navigate = useNavigate();
  const user = getAuth();

  return (
    <div className="container-fluid px-0">
      <PageTitle breadcrumbs={accountBreadCrumbs}>{title}</PageTitle>
      <PageSubTitle>{taskDetails?.TaskCode || ""}</PageSubTitle>

      <div className="card">
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={validateFun}
          onSubmit={async (values) => {
            const updatedValues = {
              ...values,
              [values.fixedDate ? "fixedDate" : "idealDueDate"]:
                values.fixedDate ? values.fixedDate : values.idealDueDate,
            };

            const requestValue: dueDateChangeValue = {
              OldDueDate: initialValue.fixedDate ?? initialValue.idealDueDate,
              RequesterId: user?.Id,
              //ReviewerId: values.managerId ? values.managerId : values.expertId,
              ReviewerIds: masterDataList?.masterData?.data?.records?.Admin?.map(
                (a) => a.Id
              ),
              TaskId: values.id,
              RequestedDueDate: values.fixedDate ?? values.idealDueDate,
              TaskCode: values.taskCode,
            };
            // eslint-disable-next-line no-lone-blocks

            // hasPermission(actionsRole.ARTICLE) &&
            (user?.Id == values.managerId || user?.Id == values.expertId) &&
              !user?.Roles.includes("Admin") &&
              (initialValue.fixedDate
                ? initialValue.fixedDate != values.fixedDate
                : initialValue.idealDueDate != values.idealDueDate) &&
              (await dueDateChangeRequestDispatch(requestValue));

            await taskDispatch(updatedValues, () => {
              navigate(absolutePath(TASK_LIST));
            });
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => {
            return (
              <Form noValidate className="form">
                <div className="p-7">
                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Task
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid text-gray-500`}
                          placeholder="Type Name"
                          id="typeName"
                          name="typeName"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Client Name
                      </label>

                      <div className="">
                        <DropDown
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          placeholder="Select Client Name"
                          options={masterDataList.masterData?.data?.records?.Clients?.map(
                            (client) => ({
                              value: client.Id,
                              name: client.Name,
                            })
                          )}
                          setFieldValue={setFieldValue}
                          name="clientId"
                          defaultValue={{
                            value: taskDetails?.ClientId,
                            name: taskDetails?.ClientName,
                          }}
                          disabled={isSubmitting}
                          showSearch={true}
                        />
                        <ErrorMessage
                          name="clientId"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Created By
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid text-gray-500`}
                          placeholder="Creater Name"
                          id="createrName"
                          name="createrName"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6">
                        Article
                      </label>

                      <div className="">
                        <DropDown
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          placeholder="Select Article"
                          options={
                            masterDataList.masterData?.data?.records?.Admin &&
                            masterDataList.masterData?.data?.records?.Article &&
                            masterDataList.masterData?.data?.records?.TeamLeader
                              ? [
                                  { value: "", name: "Select Assignee" },
                                  ...(user?.Roles.includes("User")
                                    ? masterDataList.masterData?.data?.records?.Article?.filter(
                                        (article: any) => article.Id === user.Id
                                      ).map((assignee: any) => ({
                                        value: assignee.Id,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      }))
                                    : [
                                        ...masterDataList.masterData?.data?.records?.Admin?.map(
                                          (assignee: any) => ({
                                            value: assignee.Id,
                                            name: `${assignee.FirstName} ${assignee.LastName}`,
                                          })
                                        ),
                                        ...masterDataList.masterData?.data?.records?.Article?.map(
                                          (assignee: any) => ({
                                            value: assignee.Id,
                                            name: `${assignee.FirstName} ${assignee.LastName}`,
                                          })
                                        ),
                                        ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                                          (assignee: any) => ({
                                            value: assignee.Id,
                                            name: `${assignee.FirstName} ${assignee.LastName}`,
                                          })
                                        ),
                                      ]),
                                ]
                              : []
                          }
                          setFieldValue={setFieldValue}
                          name="assigneeId"
                          defaultValue={{
                            value: taskDetails ? taskDetails.AssigneeId : "",
                            name: taskDetails ? taskDetails.PersonAssigned : "",
                          }}
                          showSearch={true}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="assigneeId"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6">
                        Manager
                      </label>

                      <div className="">
                        <DropDown
                          placeholder="Select Manager"
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          options={
                            masterDataList.masterData?.data?.records?.Admin &&
                            masterDataList.masterData?.data?.records?.Article &&
                            masterDataList.masterData?.data?.records?.TeamLeader
                              ? [
                                  {
                                    value: "",
                                    name: "Select Manager",
                                  },
                                  ...masterDataList.masterData?.data?.records?.Admin?.map(
                                    (assignee: any) => {
                                      return {
                                        value: assignee.Id,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                  ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                                    (assignee: any) => {
                                      return {
                                        value: assignee.Id,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                ]
                              : []
                          }
                          setFieldValue={setFieldValue}
                          name={`managerId`}
                          defaultValue={{
                            value: taskDetails ? taskDetails.ManagerId : "",
                            name: taskDetails ? taskDetails.ManagerName : "",
                          }}
                          showSearch={true}
                          disabled={
                            isSubmitting || !user?.Roles.includes("Admin")
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6">
                        Expert
                      </label>
                      <div>
                        <DropDown
                          placeholder="Select Expert  "
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          options={
                            masterDataList.masterData?.data?.records?.Admin &&
                            masterDataList.masterData?.data?.records?.Article &&
                            masterDataList.masterData?.data?.records?.TeamLeader
                              ? [
                                  {
                                    value: "",
                                    name: "Select Expert",
                                  },
                                  ...masterDataList.masterData?.data?.records?.Admin?.map(
                                    (assignee: any) => {
                                      return {
                                        value: assignee.Id,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                  ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                                    (assignee: any) => {
                                      return {
                                        value: assignee.Id,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                ]
                              : []
                          }
                          setFieldValue={setFieldValue}
                          name={`expertId`}
                          defaultValue={{
                            value: taskDetails ? taskDetails.ExpertId : "",
                            name: taskDetails ? taskDetails.ExpertName : "",
                          }}
                          showSearch={true}
                          disabled={
                            isSubmitting || !user?.Roles.includes("Admin")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    {hasPaymentAccess() && (
                      <div className="col-sm-6 col-lg-4">
                        <label className="col-form-label fw-semibold fs-6 required">
                          Task Fees
                        </label>

                        <div className="">
                          <Field
                            type="number"
                            className={`form-control form-control-lg form-control-solid`}
                            placeholder="Task Fees"
                            id={`fees`}
                            name={`fees`}
                            disabled={
                              isSubmitting || user?.Roles.includes("User")
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Due Date
                      </label>
                      {(user?.Id != taskDetails?.AssigneeId) &&
                        !user?.Roles.includes("Admin") && (
                          <label className="col-form-label fw-semibold fs-8">
                            {" " + " (Request) "}
                          </label>
                        )}

                      {values.isRepeatative && (
                        <div className="d-flex flex-row flex-lg-nowrap flex-wrap gap-3 align-items-center">
                          <div className="w-100">
                            <CustomDatePicker
                              name={`fixedDate`}
                              placeholder="Fixed Date"
                              setFieldValue={setFieldValue}
                              currentValue={
                                values.fixedDate
                                  ? new Date(values.fixedDate)
                                  : null
                              }
                              disabled={
                                isSubmitting ||
                                user?.Roles.includes("User") ||
                                (user?.Id != taskDetails?.ManagerId && user?.Id != taskDetails?.ExpertId &&
                                  !user?.Roles.includes("Admin"))
                              }
                            />
                          </div>
                        </div>
                      )}
                      {!values.isRepeatative && (
                        <div className="d-flex flex-row flex-lg-nowrap flex-wrap gap-3 align-items-center">
                          <div className="w-100">
                            <CustomDatePicker
                              name={`idealDueDate`}
                              placeholder="Ideal Due Date"
                              setFieldValue={setFieldValue}
                              currentValue={
                                values.idealDueDate
                                  ? new Date(values.idealDueDate)
                                  : null
                              }
                              disabled={
                                isSubmitting ||
                                user?.Roles.includes("User") ||
                                (user?.Id != taskDetails?.ManagerId && user?.Id != taskDetails?.ExpertId &&
                                  !user?.Roles.includes("Admin"))
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    {hasPermission(actionsRole.ADMIN) && (
                      <div className="d-flex gap-3 mt-8">
                        <div className="form-check form-check-custom form-check-solid">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="isStopped"
                            name="isStopped"
                            checked={values.isStopped}
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                setFieldValue("isStopped", true);
                              } else {
                                setFieldValue("isStopped", false);
                              }
                            }}
                            disabled={isSubmitting}
                          />
                        </div>
                        <label className="col-form-label fw-semibold fs-6">
                          Stop Task
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-end py-6 px-6 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() => navigate(absolutePath(TASK_LIST))}
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default EditTask;
