import { convert } from "app/helpers/helperFunction";
import { FC } from "react";
import { connect } from "react-redux";
import { AdvancePaymentMapping } from "app/reducers/transaction/transactionDataInterface";

interface props {
  row: AdvancePaymentMapping;
}

const AdvancePaymentMappingsTableRow: FC<props> = ({ row }) => {
  return (
    <tr>
      <td className="text-dark fw-bold ps-2">{row.ClientName}</td>
      <td className="">{row.FirmName}</td>
      <td className="">
        {row.IsReimbursement ? row.ReimbursementBillNumber : row.BillNumber}
      </td>
      <td className="">{row.ReceiptAmount || "-"}</td>
      <td className="">{row.UsedAmount}</td>
      <td className="">{row?.Date ? convert(row.Date) : "-"}</td>
    </tr>
  );
};

export default connect(null, null)(AdvancePaymentMappingsTableRow);
