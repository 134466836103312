import {
  ErrorMessage,
  Field,
  FormikErrors,
  FormikTouched,
  FormikValues,
} from "formik";
import { FC, useEffect, useState } from "react";

import { addUserInitialValue } from "../AddUser";
import CustomDatePicker from "../../../commonComponent/datePicker/CustomDatePicker";
import DropDown from "../../../commonComponent/dropDown/DropDown";

import { masterDataModal } from "app/reducers/masterData/masterDataReducer";

interface props {
  touched: FormikTouched<addUserInitialValue>;
  errors: FormikErrors<addUserInitialValue>;
  setFieldValue: any;
  values: FormikValues;
  isSubmitting: boolean;
  passwordClass?: string;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

const UserDetailsForm: FC<props> = ({
  isSubmitting,
  values,
  touched,
  errors,
  setFieldValue,
  passwordClass,
  masterDataList,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="d-flex flex-column gap-5 gap-md-7">
      <div className="fs-3 fw-semibold mb-n2">Personal Details</div>
      <div className="row">
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            First Name
          </label>
          <div className="mb-4 mb-lg-0 pe-0">
            <Field
              type="text"
              className={`form-control form-control-lg form-control-solid ${
                touched.firstName &&
                errors.firstName &&
                "is-invalid inValidBorder"
              }`}
              placeholder="First Name"
              id="firstName"
              name="firstName"
              disabled={isSubmitting}
            />
            <ErrorMessage
              name="firstName"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Last Name
          </label>
          <div className="pe-0">
            <Field
              type="text"
              className={`form-control form-control-lg form-control-solid ${
                touched.lastName &&
                errors.lastName &&
                "is-invalid inValidBorder"
              }`}
              placeholder="Last Name"
              id="lastName"
              name="lastName"
              disabled={isSubmitting}
            />
            <ErrorMessage
              name="lastName"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Phone Number
          </label>

          <div className="fv-row">
            <Field
              type="number"
              className={`form-control form-control-lg form-control-solid ${
                touched.phoneNumber &&
                errors.phoneNumber &&
                "is-invalid inValidBorder"
              }`}
              placeholder="Phone Number"
              id="phoneNumber"
              name="phoneNumber"
              disabled={isSubmitting}
            />
            <ErrorMessage
              name="phoneNumber"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Registration Date
          </label>

          <div className="pe-0">
            <CustomDatePicker
              name="registrationDate"
              placeholder="Registration Date"
              setFieldValue={setFieldValue}
              currentValue={
                values.registrationDate
                  ? new Date(values.registrationDate)
                  : null
              }
              disabled={isSubmitting}
            />
            <ErrorMessage
              name="registrationDate"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Joining Date
          </label>
          <div className="pe-0">
            <CustomDatePicker
              name="joiningDate"
              placeholder="Joining Date"
              setFieldValue={setFieldValue}
              currentValue={
                values.joiningDate ? new Date(values.joiningDate) : null
              }
              disabled={isSubmitting}
            />
            <ErrorMessage
              name="joiningDate"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
      </div>
      <div className={`fs-3 fw-semibold mb-n2 ${passwordClass}`}>Login Details</div>
      <div className={`row ${passwordClass}`}>
        <div className="col-sm-6 col-lg-4">
          <label className="col-form-label fw-semibold fs-6 required">
            Email
          </label>
          <div className="pe-0">
            <Field
              type="text"
              className={`form-control form-control-lg form-control-solid ${
                touched.email && errors.email && "is-invalid inValidBorder"
              }`}
              placeholder="Email"
              id="email"
              name="email"
              disabled={isSubmitting}
            />
            <ErrorMessage name="email" component="div" className="errorMsg" />
          </div>
        </div>
        <div className={`col-sm-6 col-lg-4`}>
          <label className="col-form-label fw-semibold fs-6 required">
            Password
          </label>
          <div className="pe-0 position-relative">
            <Field
              type={showPassword ? "text" : "password"}
              className={`form-control form-control-lg form-control-solid 
              ${
                touched.password &&
                errors.password &&
                "is-invalid inValidBorder"
              }`}
              placeholder="Password"
              id="password"
              name="password"
              disabled={isSubmitting}
            />
            {showPassword && (
              <button
                type="button"
                className={`btn p-0 ${
                  touched.password && errors.password
                    ? "password-eye-user-with-error"
                    : "password-eye-user"
                }`}
                onClick={() => setShowPassword(false)}
              >
                <i className="fa-solid fa-eye-slash"></i>
              </button>
            )}
            {!showPassword && (
              <button
                type="button"
                className={`btn p-0  ${
                  touched.password && errors.password
                    ? "password-eye-user-with-error"
                    : "password-eye-user"
                }`}
                onClick={() => setShowPassword(true)}
              >
                <i className="fa-solid fa-eye"></i>
              </button>
            )}
            <ErrorMessage
              name="password"
              component="div"
              className="errorMsg"
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-column">
        <label className="col-form-label fw-semibold fs-6 required">Role</label>
        <div className="col-lg-5 row">
          <div className="d-flex flex-row align-items-center gap-3 col-lg-4">
            <div className="form-check form-check-custom form-check-solid">
              <Field
                className="form-check-input"
                type="radio"
                name="role"
                value="Admin"
                disabled={isSubmitting}
              />
            </div>
            <div className="col-form-label fw-semibold fs-6">Admin</div>
          </div>
          <div className="d-flex flex-row align-items-center gap-3 col-lg-4">
            <div className="form-check form-check-custom form-check-solid">
              <Field
                className="form-check-input"
                type="radio"
                name="role"
                value="User"
                disabled={isSubmitting}
              />
            </div>
            <div className="col-form-label fw-semibold fs-6">Article</div>
          </div>
          <div className="d-flex flex-row align-items-center gap-3 col-lg-4">
            <div className="form-check form-check-custom form-check-solid">
              <Field
                className="form-check-input"
                type="radio"
                name="role"
                value="Team Leader"
                disabled={isSubmitting}
              />
            </div>
            <div className="col-form-label fw-semibold fs-6">Team Leader</div>
          </div>
        </div>
      </div>
      
        <div className="d-flex flex-row align-items-center gap-8  col-sm-8  mb-6">
          <div>
          <label className="col-form-label fw-semibold fs-6">Reporting Authority</label>
          <div className="d-flex flex-lg-wrap flex-md-nowrap flex-sm-wrap flex-xxl-nowrap gap-3 ">
            <div className="w-100">
              <DropDown
                placeholder="Select Manager"
                className={`text-start mb-4 form-control form-control-lg form-control-solid form-select ${
                  masterDataList.loading ? "display-dropdown-loader" : ""
                }`}
                displayLoader={masterDataList.loading}
                options = {
                  masterDataList.masterData?.data?.records?.Admin &&
                  masterDataList.masterData?.data?.records?.TeamLeader
                    ? [
                        ...masterDataList.masterData?.data?.records?.Admin?.filter(
                          (assignee: any) => assignee.IsActive
                        ).map((assignee: any) => ({
                          value: assignee.Id,
                          name: `${assignee.FirstName} ${assignee.LastName}`,
                        })),
                
                        ...masterDataList.masterData?.data?.records?.TeamLeader?.filter(
                          (assignee: any) => assignee.IsActive
                        ).map((assignee: any) => ({
                          value: assignee.Id,
                          name: `${assignee.FirstName} ${assignee.LastName}`,
                        })),
                      ].sort((a, b) => a.name.localeCompare(b.name))
                    : []
                }                
                setFieldValue={setFieldValue}
                name="reportingAuthorityId"
                currentValue={{ value: values.reportingAuthorityId}}
                defaultValue={{value: values.reportingAuthorityId,name:values.reportedId}}
                disabled={isSubmitting}
                showSearch={true}
              />
              <ErrorMessage
                name="reportingAuthorityId"
                component="div"
                className="errorMsg"
              />
            </div>
          </div>
          </div>
          <div className="d-flex flex-row align-items-center gap-3 mt-8 col-lg-4">
            <div className="form-check form-check-custom form-check-solid">
              <Field
                className="form-check-input"
                type="checkbox"
                name="paymentAccess"
                  id="paymentAccess"
                //value="paymentAccess"
                disabled={isSubmitting}
              />
            </div>
            <div className="col-form-label fw-semibold fs-6">Payment Access</div>
            <div className="form-check form-check-custom form-check-solid">
              <Field
                className="form-check-input"
                type="checkbox"
                name="transactionAccess"
                  id="transactionAccess"
                //value="transactionAccess"
                disabled={isSubmitting}
              />
            </div>
            <div className="col-form-label fw-semibold fs-6">Transaction Access</div>
          </div>
        </div>
    </div>
  );
};

export default UserDetailsForm;
