import {
  ErrorMessage,
  Field,
  FieldArray,
  FormikErrors,
  FormikTouched,
  FormikValues,
} from "formik";
import { FC } from "react";

import { activityObject, addTaskTypeValue } from "../TaskTypeForm";
import DropDown from "../../../commonComponent/dropDown/DropDown";

interface props {
  values: FormikValues;
  touched: FormikTouched<addTaskTypeValue>;
  errors: FormikErrors<addTaskTypeValue>;
  setFieldValue: any;
  isSubmitting: boolean;
}

const newActivityObject: activityObject = {
  name: "",
  isMandatory: true,
  requireCompletionField: false,
  // requireChecking: true,
  completionFieldName: "",
  completionFieldType: "",
  minValue: 0,
};

const OPTIONS = [
  { value: "text", name: "Text" },
  { value: "number", name: "Number" },
];

const TaskTypeActivityForm: FC<props> = ({
  isSubmitting,
  values,
  touched,
  errors,
  setFieldValue,
}) => {
  return (
    <div className="my-6 d-flex flex-column gap-5 gap-md-7">
      {values.activities.length ? (
        <div className="fs-3 fw-semibold mb-n2">Checklist Details</div>
      ) : (
        ""
      )}
      <div className="row mb-6">
        <div className="">
          <FieldArray
            name="activities"
            render={(arrayHelpers) => (
              <div className="">
                {values.activities.length === 0 && (
                  <div>
                    <button
                      className="btn btn-light-primary btn-sm"
                      onClick={() => arrayHelpers.push(newActivityObject)}
                      disabled={isSubmitting}
                    >
                      <i className="fa-solid fa-plus"></i>
                      Add Step
                    </button>
                  </div>
                )}
                {values.activities.map((person: any, index: any) => (
                  <div
                    key={index}
                    className="border border-2 rounded-2 row px-2 mb-4"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="align-items-center d-flex gap-4 fs-4 fw-semibold min-h-50px w-100"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#activity-${index}`}
                      >
                        <div>
                          <span>Step {index + 1}</span>&nbsp;&nbsp;
                          <span className="fs-5 text-gray-500">
                            {values.activities[index].name}
                          </span>
                        </div>
                      </div>
                      <div className="align-items-center gap-4 d-flex justify-content-start w-auto">
                        <button
                          type="button"
                          style={{ color: "#ff5959" }}
                          className="btn btn-active-light-danger btn-light px-4 py-2 rounded-circle text-center"
                          onClick={() => arrayHelpers.remove(index)}
                          disabled={isSubmitting}
                        >
                          <i
                            style={{ color: "#ff5959" }}
                            className="fa-solid fa-close p-0"
                          ></i>
                        </button>
                        {values.activities.length - 1 === index && (
                          <button
                            type="button"
                            className="btn btn-active-light-primary btn-light px-4 py-2 rounded-circle text-center"
                            onClick={() =>
                              arrayHelpers.insert(index + 1, newActivityObject)
                            }
                            disabled={isSubmitting}
                          >
                            <i
                              style={{ color: "#1da9f8" }}
                              className="fa-solid fa-plus p-0"
                            ></i>
                          </button>
                        )}
                      </div>
                    </div>
                    <div
                      id={`activity-${index}`}
                      className={`border-top collapse pt-5 ${
                        values.activities.length - 1 === index ? "show" : ""
                      }`}
                    ><div className="row mb-6 align-items-center">
                        <div className="col-sm-6 col-lg-4">
                          <label className="col-form-label fw-semibold fs-6 required">
                            Step Name
                          </label>
                          <div className="">
                            <Field
                              type="text"
                              className={`form-control form-control-lg form-control-solid 
                            ${
                              touched.activities &&
                              (
                                touched.activities[
                                  index
                                ] as FormikErrors<activityObject>
                              )?.name &&
                              errors.activities &&
                              (
                                errors.activities[
                                  index
                                ] as FormikErrors<activityObject>
                              )?.name &&
                              "is-invalid inValidBorder"
                            }`}
                              placeholder="Step Name"
                              id={`activities.${index}.name`}
                              name={`activities.${index}.name`}
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name={`activities.${index}.name`}
                              component="div"
                              className="errorMsg"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                          <label className="col-form-label fw-semibold fs-6"></label>
                          <div className="d-flex flex-row flex-wrap gap-3">
                            <div className="d-flex align-items-center">
                              <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name={`activities.${index}.isMandatory`}
                                  id={`activities.${index}.isMandatory`}
                                  disabled={isSubmitting}
                                />
                              </div>
                              <label className="col-form-label fw-semibold fs-6">
                                Compulsory
                              </label>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name={`activities.${index}.requireCompletionField`}
                                  id={`activities.${index}.requireCompletionField`}
                                  disabled={isSubmitting}
                                />
                              </div>
                              <label className="col-form-label fw-semibold fs-6">
                                Require Completion Field
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-6">
                        {values.activities[index].requireCompletionField && (
                          <div className="col-sm-6 col-lg-4">
                            <label className="col-form-label fw-semibold fs-6 required">
                              Field Type
                            </label>

                            <div className="">
                              <DropDown
                                className={`text-start form-control form-control-lg form-control-solid form-select 
                                ${
                                  touched.activities &&
                                  (
                                    touched.activities[
                                      index
                                    ] as FormikErrors<activityObject>
                                  )?.completionFieldType &&
                                  errors.activities &&
                                  (
                                    errors.activities[
                                      index
                                    ] as FormikErrors<activityObject>
                                  )?.completionFieldType &&
                                  "is-invalid inValidBorder"
                                }`}
                                placeholder="Select Field Type"
                                options={OPTIONS}
                                setFieldValue={setFieldValue}
                                name={`activities.${index}.completionFieldType`}
                                currentValue={{
                                  value:
                                    values.activities[index]
                                      .completionFieldType,
                                  name:
                                    values.activities[index].completionFieldType
                                      .charAt(0)
                                      .toUpperCase() +
                                    values.activities[
                                      index
                                    ].completionFieldType.slice(1),
                                }}
                                defaultValue={{
                                  value:
                                    values.activities[index]
                                      .completionFieldType,
                                  name:
                                    values.activities[index].completionFieldType
                                      .charAt(0)
                                      .toUpperCase() +
                                    values.activities[
                                      index
                                    ].completionFieldType.slice(1),
                                }}
                                disabled={isSubmitting}
                                showSearch={true}
                              />
                              <ErrorMessage
                                name={`activities.${index}.completionFieldType`}
                                component="div"
                                className="errorMsg"
                              />
                            </div>
                          </div>
                        )}
                        {values.activities[index].requireCompletionField &&
                          values.activities[index].completionFieldType && (
                            <div className="col-sm-6 col-lg-4">
                              <label className="col-form-label fw-semibold fs-6 required">
                                Field Name
                              </label>

                              <div className="">
                                <Field
                                  type="text"
                                  className={`form-control form-control-lg form-control-solid 
                                    ${
                                      touched.activities &&
                                      (
                                        touched.activities[
                                          index
                                        ] as FormikErrors<activityObject>
                                      )?.completionFieldName &&
                                      errors.activities &&
                                      (
                                        errors.activities[
                                          index
                                        ] as FormikErrors<activityObject>
                                      )?.completionFieldName &&
                                      "is-invalid inValidBorder"
                                    }`}
                                  placeholder="Field Name"
                                  id={`activities.${index}.completionFieldName`}
                                  name={`activities.${index}.completionFieldName`}
                                  disabled={isSubmitting}
                                />
                                <ErrorMessage
                                  name={`activities.${index}.completionFieldName`}
                                  component="div"
                                  className="errorMsg"
                                />
                              </div>
                            </div>
                          )}
                        {values.activities[index].requireCompletionField &&
                          values.activities[index].completionFieldType ===
                            "number" && (
                            <div className="col-sm-6 col-lg-4">
                              <label className="col-form-label fw-semibold fs-6 required">
                                Minimum Value
                              </label>

                              <div className="">
                                <Field
                                  type="number"
                                  className={`form-control form-control-lg form-control-solid 
                                    ${
                                      touched.activities &&
                                      (
                                        touched.activities[
                                          index
                                        ] as FormikErrors<activityObject>
                                      )?.minValue &&
                                      errors.activities &&
                                      (
                                        errors.activities[
                                          index
                                        ] as FormikErrors<activityObject>
                                      )?.minValue &&
                                      "is-invalid inValidBorder"
                                    }`}
                                  placeholder="Minimum Value"
                                  id={`activities.${index}.minValue`}
                                  name={`activities.${index}.minValue`}
                                  disabled={isSubmitting}
                                />
                                <ErrorMessage
                                  name={`activities.${index}.minValue`}
                                  component="div"
                                  className="errorMsg"
                                />
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default TaskTypeActivityForm;
