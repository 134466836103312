import { connect } from "react-redux";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikState,
  FormikValues,
  useFormikContext,
} from "formik";
import { FC, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { object, string, number } from "yup";

import { addTransactionValue } from "../TransactionForm";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import { addTransaction } from "app/reducers/transaction/transactionAction";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { TRANSACTION_TYPE } from "app/helpers/constants";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import CustomDatePicker from "app/components/commonComponent/datePicker/CustomDatePicker";
import hasPermission, { actionsRole } from "../../auth/core/permission";
import { billListState } from "app/reducers/billing/getBillListReducer";
import { getSearchParameter } from "app/helpers/helperFunction";
import { useSearchParams } from "react-router-dom";
import { searchParams } from "app/helpers/commonInterface";
import { getBillList, updateBill } from "app/reducers/billing/billingAction";
import { useSendDataChangeWarning } from "app/helpers/webSocketHelper";
import { addBillValue } from "../../billing/BillingForm";
import { BILL_STATUS_OPTIONS } from "../../billing/component/AddBill";
import {
  getReimbursementInvoiceList,
  updateReimbursementInvoice,
} from "app/reducers/reimbursement/reimbursementAction";
import { getMasterData } from "app/reducers/masterData/masterDataAction";

interface props {
  addTransactionDispatch: Function;
  closeModal: Function;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
  showTransaction: boolean;
  initialValuess?: any;
  billDetails?: any;
  reimbursementInvoiceDetails?: any;
  getAllBillingDetails?: Function;
  updateBillDispatch?: Function;
  updateReimbursementInvoiceDispatch?: Function;
  reimbursementInvoiceListDispatch?: Function;
  getMasterDataList: Function;
}
const initialValue = {
  bookId: undefined,
  amount: "",
  narration: "",
  type: undefined,
  taskId: undefined,
  billId: undefined,
  expenseId: undefined,
  isPayment: "false",
  clientId: undefined,
  firmId: undefined,
  isCashBook: "true",
};
interface firmModel {
  Books: {
    Name: string;
    IsCashBook: boolean;
    FirmId: number;
    Id: number;
    IsActive: boolean;
  }[];
  Id: number;
  IsActive: boolean;
  Name: string;
}

export const RECEIPT_TYPE_OPTIONS = [
  { value: TRANSACTION_TYPE["Advance"], name: "Advance" },
  // { value: TRANSACTION_TYPE["GovernmentFees/Tax"], name: "Govt.Fees/Tax" },
  {
    value: TRANSACTION_TYPE["Against Reimbursement"],
    name: "Against Reimbursement",
  },
  { value: TRANSACTION_TYPE["Against Bill"], name: "Against Bill" },
  { value: TRANSACTION_TYPE.Other, name: "Other" },
];

const PAYMENT_TYPE_OPTIONS = [
  { value: TRANSACTION_TYPE.Reimbursement, name: "Reimbusement" },
  { value: TRANSACTION_TYPE.Expense, name: "Expense" },
  { value: TRANSACTION_TYPE["GovernmentFees/Tax"], name: "Govt.Fees/Tax" },
  { value: TRANSACTION_TYPE.Other, name: "Other" },
];

const getBookDataBySelectedFirm = (
  firms: firmModel[],
  firmId: number,
  isCashBook: string
) => {
  return firms?.reduce((acc: { value: number; name: string }[], firm) => {
    if (String(firm.Id) == String(firmId)) {
      const firmWiseBookData = firm.Books.reduce(
        (acc1: { value: number; name: string }[], book) => {
          if (String(book.IsCashBook) === String(isCashBook) && book.IsActive)
            acc1.push({ value: book.Id, name: book.Name });
          return acc1;
        },
        []
      );
      acc.push(...firmWiseBookData);
    }
    return acc;
  }, []);
};



const AddTransactionShrt: FC<props> = ({
  masterDataList,
  closeModal,
  addTransactionDispatch,
  showTransaction,
  initialValuess,
  billDetails,
  updateBillDispatch,
  updateReimbursementInvoiceDispatch,
  getAllBillingDetails,
  reimbursementInvoiceListDispatch,
  reimbursementInvoiceDetails,
  getMasterDataList
}) => {
  useEffect(() => {
    if (initialValuess?.type?.value) {
      setTransactionTypeValue(initialValuess.type.value);
    }
  }, [initialValuess?.type?.value]);

  const [transactionTypeValue, setTransactionTypeValue] = useState<
    TRANSACTION_TYPE | undefined
  >();

  // const [show, setShow] = useState(true);
  // useEffect(() => setShow(true), []);

  const [searchParam] = useSearchParams();
  const searchObj = useMemo(
    () => getSearchParameter(searchParam),
    [searchParam]
  );

  const sendDataChangeWarning = useSendDataChangeWarning();

  const transactionValidationSchema = object().shape({
    isPayment: string().required("Transaction type is required"),
    type: number()
      .required("Transaction type is required")
      .typeError("Transaction type is required"),
    firmId: number().required("Firm is required"),
    bookId: number().required("Book is required").min(1, "Book is required"),

    clientId: number().when("type", {
      is: (type: TRANSACTION_TYPE) =>
        type !== TRANSACTION_TYPE.Expense && type !== TRANSACTION_TYPE.Other,
      then: number().required("Client is required"),
    }),
    billId: number().when("type", {
      is: (type: number) => type === TRANSACTION_TYPE["Against Bill"],
      then: number().required("BillId is required"),
    }),
    expenseId: number().when("type", {
      is: (type: number) => type === TRANSACTION_TYPE.Expense,
      then: number().required("ExpenseId is required"),
    }),
    amount: number()
      .required("Transaction amount is required")
      .typeError("Transaction amount is required "),
    // .test(
    //   "max-due-amount",
    //   "Amount cannot be greater than due amount",
    //   function (value: any) {
    //     const dueAmount = initialValuess?.dueAmount;
    //     if (dueAmount === undefined || dueAmount === null) return true;
    //     return value <= dueAmount;
    //   }
    // ),
    narration: string().when("type", {
      is: (type: number) => type === TRANSACTION_TYPE.Other,
      then: string().required("Narration is requiured"),
    }),
  });

  const getMasterData = () => {
    getMasterDataList()
  }

  const GetDataAsPerType = () => {
    const {
      values,
      setFieldValue,
      isSubmitting,
    }: FormikState<FormikValues> & FormikHelpers<FormikValues> =
      useFormikContext();

    switch (Number(transactionTypeValue)) {
      //case TRANSACTION_TYPE["Advance"]:
      case TRANSACTION_TYPE["GovernmentFees/Tax"]:
      case TRANSACTION_TYPE.Reimbursement:
        return (
          <>
            {values.clientId !== 0 && (
              <div className="mb-6">
                <label className="col-form-label fw-semibold fs-6 required">
                  Task
                </label>

                <div className="">
                  <DropDown
                    className={`text-start form-control form-control-lg form-control-solid form-select ${
                      masterDataList.loading ? "display-dropdown-loader" : ""
                    }`}
                    placeholder="Select Task"
                    displayLoader={masterDataList.loading}
                    options={masterDataList.masterData?.data?.records?.Tasks?.filter(
                      (task: any) => {
                        return task.ClientId === Number(values.clientId);
                      }
                    ).map(
                      (row: {
                        Id: number;
                        TaskCode: string;
                        TaskTypeId: Number;
                      }) => {
                        return {
                          value: row.Id,
                          name:
                            row.TaskCode +
                            " " +
                            masterDataList.masterData?.data?.records.TaskTypes.find(
                              (x) => x.Id === row.TaskTypeId
                            )?.Name,
                        };
                      }
                    )}
                    setFieldValue={setFieldValue}
                    name="taskId"
                    currentValue={{ value: values.taskId }}
                    disabled={isSubmitting}
                    showSearch={true}
                  />
                  <ErrorMessage
                    name="taskId"
                    component="div"
                    className="errorMsg"
                  />
                </div>
              </div>
            )}
          </>
        );
      case TRANSACTION_TYPE["Against Bill"]:
        return (
          <>
            {values.clientId !== 0 && (
              <div className="mb-6">
                <label className="col-form-label fw-semibold fs-6 required">
                  Invoice
                </label>

                <div className="">
                  <DropDown
                    className={`text-start form-control form-control-lg form-control-solid form-select ${
                      masterDataList.loading ? "display-dropdown-loader" : ""
                    }`}
                    placeholder="Select Invoice"
                    displayLoader={masterDataList.loading}
                    options={masterDataList.masterData?.data?.records?.Bills?.filter(
                      (bill: any) => bill.ClientId === Number(values.clientId)
                    ).map((row: { Id: number; BillNumber: string }) => {
                      return { value: row.Id, name: row.BillNumber };
                    })}
                    setFieldValue={setFieldValue}
                    name="billId"
                    currentValue={{
                      value: values.billId,
                      name:
                        (initialValuess?.billId &&
                          masterDataList.masterData?.data?.records?.Bills?.find(
                            (bill: { Id: number }) =>
                              bill.Id === initialValuess.billId
                          )?.BillNumber) ??
                        "",
                    }}
                    disabled={isSubmitting}
                    showSearch={true}
                    setFieldValueDefault={true}
                  />
                  <ErrorMessage
                    name="billId"
                    component="div"
                    className="errorMsg"
                  />
                </div>
              </div>
            )}
          </>
        );
      case TRANSACTION_TYPE["Against Reimbursement"]:
        return (
          <>
            {values.clientId !== 0 && (
              <div className="mb-6">
                <label className="col-form-label fw-semibold fs-6 required">
                  Reimbursement
                </label>

                <div className="">
                  <DropDown
                    className={`text-start form-control form-control-lg form-control-solid form-select ${
                      masterDataList.loading ? "display-dropdown-loader" : ""
                    }`}
                    placeholder="Select Reimbursement"
                    displayLoader={masterDataList.loading}
                    options={masterDataList.masterData?.data?.records?.Reimbursements?.filter(
                      (bill: any) => bill.ClientId === Number(values.clientId)
                    ).map((row: { Id: number; BillNumber: string }) => {
                      return { value: row.Id, name: row.BillNumber };
                    })}
                    setFieldValue={setFieldValue}
                    name="billId"
                    currentValue={{
                      value: values.billId,
                      name:
                        (initialValuess?.billId &&
                          masterDataList.masterData?.data?.records?.Reimbursements?.find(
                            (bill: { Id: number }) =>
                              bill.Id === initialValuess.billId
                          )?.BillNumber) ??
                        "",
                    }}
                    disabled={isSubmitting}
                    showSearch={true}
                    setFieldValueDefault={true}
                  />
                  <ErrorMessage
                    name="billId"
                    component="div"
                    className="errorMsg"
                  />
                </div>
              </div>
            )}
          </>
        );
      case TRANSACTION_TYPE.Expense:
        return (
          <div className="mb-6">
            <label className="col-form-label fw-semibold fs-6 required">
              Expense
            </label>

            <div className="">
              <DropDown
                className={`text-start form-control form-control-lg form-control-solid form-select ${
                  masterDataList.loading ? "display-dropdown-loader" : ""
                }`}
                placeholder="Select Expense"
                displayLoader={masterDataList.loading}
                options={masterDataList.masterData?.data?.records?.Expenses?.map(
                  (row) => {
                    return { value: row.Id, name: row.Name };
                  }
                )}
                setFieldValue={setFieldValue}
                name="expenseId"
                currentValue={{ value: values.expenseId }}
                disabled={isSubmitting}
                showSearch={true}
              />
              <ErrorMessage
                name="expenseId"
                component="div"
                className="errorMsg"
              />
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <ErrorBoundary>
      <Modal
        show={showTransaction}
        aria-labelledby="contained-modal-transaction"
        centered
        data-toggle="modal"
        backdrop="static"
        keyboard={false}
        className="transaction-shortcut"
      >
        <Modal.Header>
          <Modal.Title className="">Add Transaction {initialValuess != null ? " - " + initialValuess.billNumber : ""}</Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={initialValuess ?? initialValue}
          validationSchema={transactionValidationSchema}
          onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
            const { amount } = values;
            const matchingBill =
              masterDataList?.masterData?.data?.records?.Bills?.find(
                (bill) => bill?.Id == values.billId
              );
            // const matchingReimbursement =
            //   masterDataList.masterData.data.records.Reimbursements.find(
            //     (rm) => rm.Id == values.billId
            //   );

            const matchingReimbursement =
              reimbursementInvoiceDetails?.reimbursementInvoiceList?.data?.records?.find(
                (rm: any) => rm?.Id == values.billId
            );
            let dueAmount =
              values.type == TRANSACTION_TYPE["Against Bill"]
                ? (matchingBill?.TotalAmount || 0) -
                  (matchingBill?.PaidAmount || 0)
                : (matchingReimbursement?.TotalAmount || 0) -
                  (matchingReimbursement?.PaidAmount || 0);
            // if (values.type == TRANSACTION_TYPE["Against Reimbursement"]) {
            //   dueAmount = initialValuess.dueAmount;
            // }
            if (
              (values.type == TRANSACTION_TYPE["Against Bill"] ||
                values.type == TRANSACTION_TYPE["Against Reimbursement"]) &&
              parseFloat(amount) > dueAmount
            ) {
              setErrors({
                amount: `Amount cannot exceed due amount of ${dueAmount}`,
              });
            } else {
              const updatedValues = {
                ...values,
                BillNumber: initialValuess
                  ? initialValuess.billNumber
                  : masterDataList.masterData.data.records.Bills.find(
                      (bill) => bill.Id == values.billId
                    )?.BillNumber,
                Date: initialValuess
                  ? initialValuess.date
                  : masterDataList.masterData.data.records.Bills.find(
                      (bill) => bill.Id == values.billId
                    )?.Date,
                Id: values.billId,
                PaidAmount: values.amount,
                PaymentStatus: initialValuess
                  ? values.amount < initialValuess.dueAmount
                    ? BILL_STATUS_OPTIONS[0].value
                    : BILL_STATUS_OPTIONS[1].value
                  : (() => {
                      const matchingBill =
                        masterDataList.masterData.data.records.Bills.find(
                          (bill) => bill.Id == values.billId
                        );

                      return values.amount <
                        (matchingBill?.TotalAmount || 0) -
                          (matchingBill?.PaidAmount || 0)
                        ? BILL_STATUS_OPTIONS[0].value
                        : BILL_STATUS_OPTIONS[1].value;
                    })(),
                TasksIds: [0],
              };
              if (
                values.type == TRANSACTION_TYPE["Against Bill"] &&
                updateBillDispatch &&
                getAllBillingDetails
              ) {
                await updateBillDispatch(updatedValues, () => {
                  (String(billDetails.ClientId) !== String(values.clientId) ||
                    String(billDetails.Firm.Id) !== String(values.firmId) ||
                    String(billDetails.Discount) !== String(values.discount)) &&
                    resetForm();
                  getAllBillingDetails(searchObj);
                  closeModal();
                  sendDataChangeWarning();
                });
              } else {
                console.warn(
                  "billDispatch is not defined, skipping edit logic"
                );
              }
              if (
                values.type == TRANSACTION_TYPE["Against Reimbursement"] &&
                updateReimbursementInvoiceDispatch &&
                reimbursementInvoiceListDispatch
              ) {
                await updateReimbursementInvoiceDispatch(updatedValues, () => {
                  (String(reimbursementInvoiceDetails.ClientId) !==
                    String(values.clientId) ||
                    String(reimbursementInvoiceDetails.Firm.Id) !==
                      String(values.firmId) ||
                    String(reimbursementInvoiceDetails.Discount) !==
                      String(values.discount)) &&
                    resetForm();
                  reimbursementInvoiceListDispatch(searchObj);
                  closeModal();
                  sendDataChangeWarning();
                });
              }
              await addTransactionDispatch(
                {
                  ...values,
                  isPayment: values.isPayment === "false" ? false : true,
                  oldIsPayment: null,
                },
                () => {
                  resetForm();
                }
              );
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            setFieldValue,
            setValues,
          }) => {
            return (
              <Form noValidate className="form">
                <Modal.Body className="d-flex flex-column justify-content-between modal-body px-9">
                  <div className="d-flex flex-column flex-sm-row gap-5 h-100 justify-content-between">
                    <div className="w-75">
                      <div className="row">
                        <div className="d-flex flex-row gap-3 col-lg-6">
                          <div className="form-check form-check-custom form-check-solid">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="isPayment"
                              value={"false"}
                              onChange={() => {
                                setValues({
                                  ...values,
                                  isPayment: "false",
                                  type: undefined,
                                  taskId: undefined,
                                  expenseId: undefined,
                                  billId: undefined,
                                  clientId: undefined,
                                });
                                setTransactionTypeValue(undefined);
                              }}
                              disabled={isSubmitting}
                            />
                          </div>
                          <div className="col-form-label fw-semibold fs-6">
                            Receipt
                          </div>
                        </div>
                        <div className="d-flex flex-row gap-3 col-lg-6">
                          <div className="form-check form-check-custom form-check-solid">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="isPayment"
                              value={"true"}
                              onChange={() => {
                                setValues({
                                  ...values,
                                  isPayment: "true",
                                  type: undefined,
                                  taskId: undefined,
                                  expenseId: undefined,
                                  billId: undefined,
                                  clientId: undefined,
                                });
                                setTransactionTypeValue(undefined);
                              }}
                              disabled={isSubmitting}
                            />
                          </div>
                          <div className="col-form-label fw-semibold fs-6">
                            Payment
                          </div>
                        </div>
                        <ErrorMessage
                          name="isPayment"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                      <div className="mb-6">
                        <label className="col-form-label fw-semibold fs-6 required">
                          Firm
                        </label>

                        <div className="">
                          <DropDown
                            className={`text-start form-control form-control-lg form-control-solid form-select ${
                              masterDataList.loading
                                ? "display-dropdown-loader"
                                : ""
                            }`}
                            placeholder="Select Firm"
                            displayLoader={masterDataList.loading}
                            options={masterDataList.masterData?.data?.records?.Firms?.map(
                              (firm) => ({ value: firm.Id, name: firm.Name })
                            )}
                            setFieldValue={setFieldValue}
                            apiCallDispatch={(e: any) => {
                              setFieldValue("bookId", -1);
                            }}
                            name="firmId"
                            currentValue={{ value: values.firmId, name:
                              (initialValuess?.firmId &&
                                masterDataList.masterData?.data?.records?.Firms?.find(
                                  (firm: { Id: number }) =>
                                    firm.Id === initialValuess.firmId
                                )?.Name) ??
                              "", }}
                            disabled={isSubmitting}
                            showSearch={true}
                          />
                          <ErrorMessage
                            name="firmId"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="d-flex flex-row gap-3 col-lg-6">
                          <div className="form-check form-check-custom form-check-solid">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="isCashBook"
                              value={"true"}
                              onChange={() => {
                                setFieldValue("isCashBook", "true");
                                setFieldValue("bookId", 0);
                              }}
                              disabled={isSubmitting || !values.firmId}
                            />
                          </div>
                          <div className="col-form-label fw-semibold fs-6">
                            Cash Book
                          </div>
                        </div>
                        <div className="d-flex flex-row gap-3 col-lg-6">
                          <div className="form-check form-check-custom form-check-solid">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="isCashBook"
                              value={"false"}
                              onChange={() => {
                                setFieldValue("isCashBook", "false");
                                setFieldValue("bookId", 0);
                              }}
                              disabled={isSubmitting || !values.firmId}
                            />
                          </div>
                          <div className="col-form-label fw-semibold fs-6">
                            Bank Book
                          </div>
                        </div>
                        <ErrorMessage
                          name="isCashBook"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                      <div className="mb-6">
                        <label className="col-form-label fw-semibold fs-6 required">
                          Book
                        </label>

                        <div className="">
                          <DropDown
                            className="text-start form-control form-control-lg form-control-solid form-select"
                            placeholder="Select Book"
                            options={getBookDataBySelectedFirm(
                              masterDataList.masterData?.data?.records?.Firms,
                              values.firmId,
                              values.isCashBook
                            )}
                            setFieldValue={setFieldValue}
                            name="bookId"
                            currentValue={{ value: values.bookId }}
                            disabled={isSubmitting || !values.firmId}
                            showSearch={true}
                          />
                          <ErrorMessage
                            name="bookId"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      <div className="mb-6">
                        <label className="col-form-label fw-semibold fs-6">
                          Narration
                        </label>

                        <div className="">
                          <Field
                            as="textarea"
                            placeholder="Narration"
                            className={`form-control form-control-lg form-control-solid vertical-scroll
                         ${
                           touched.narration &&
                           errors.narration &&
                           "is-invalid inValidBorder"
                         }`}
                            name="narration"
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name="narration"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      {/* date selector for admin*/}
                      {hasPermission(actionsRole.ADMIN) && (
                        <div className="mb-6">
                          <label className="col-form-label fw-semibold fs-6">
                            Transaction Date
                          </label>

                          <div className="">
                            <CustomDatePicker
                              name="transactionDate"
                              placeholder="Date"
                              // currentValue={
                              //   values.startDate ? new Date(values.startDate) : null
                              // }
                              setFieldValue={setFieldValue}
                            />
                            <ErrorMessage
                              name="date"
                              component="div"
                              className="errorMsg"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="border border-1 border-dashed border-gray-300 d-none d-sm-block mx-7 w-1px"></div>
                    <div className="w-100">
                      <div className="mb-6">
                        <label className="col-form-label fw-semibold fs-6 required">
                          Amount
                        </label>

                        <div className="">
                          <Field
                            type="number"
                            placeholder="Amount"
                            className={`form-control form-control-lg form-control-solid vertical-scroll
                        ${
                          touched.amount &&
                          errors.amount &&
                          "is-invalid inValidBorder"
                        }`}
                            name="amount"
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name="amount"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      <div className="mb-6">
                        <label className="col-form-label fw-semibold fs-6 required">
                          Transaction type
                        </label>

                        <div className="">
                          <DropDown
                            className="text-start form-control form-control-lg form-control-solid form-select"
                            placeholder="Select Transaction Type"
                            options={
                              values.isPayment === "false"
                                ? RECEIPT_TYPE_OPTIONS
                                : PAYMENT_TYPE_OPTIONS
                            }
                            setFieldValue={setFieldValue}
                            name="type"
                            apiCallDispatch={(e: any) => {
                              setTransactionTypeValue(
                                initialValuess?.type.value ??
                                  e?.target?.dataset?.id
                              );
                              setValues({
                                ...values,
                                type: e?.target?.dataset?.id,
                                taskId: undefined,
                                expenseId: undefined,
                                billId: undefined,
                                clientId: undefined,
                              });
                            }}
                            currentValue={{
                              value: initialValuess?.type.value ?? values.type,
                              name: initialValuess?.type.name,
                            }}
                            disabled={isSubmitting}
                            setFieldValueDefault={true}
                          />
                          <ErrorMessage
                            name="type"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                      {values.type &&
                        TRANSACTION_TYPE.Expense !== Number(values.type) &&
                        TRANSACTION_TYPE.Other !== Number(values.type) && (
                          <div className="mb-6">
                            <label className="col-form-label fw-semibold fs-6 required">
                              Client
                            </label>

                            <div className="">
                              <DropDown
                                className={`text-start form-control form-control-lg form-control-solid form-select ${
                                  masterDataList.loading
                                    ? "display-dropdown-loader"
                                    : ""
                                }`}
                                placeholder="Select Client"
                                displayLoader={masterDataList.loading}
                                options={masterDataList.masterData?.data?.records?.Clients?.map(
                                  (client: { Id: number; Name: string }) => {
                                    return {
                                      value: client.Id,
                                      name: client.Name,
                                    };
                                  }
                                )}
                                setFieldValue={setFieldValue}
                                name="clientId"
                                currentValue={{
                                  value:
                                    initialValuess?.clientId ?? values.clientId,
                                  name:
                                    (initialValuess?.clientId &&
                                      masterDataList.masterData?.data?.records?.Clients?.find(
                                        (client: { Id: number }) =>
                                          client.Id === initialValuess.clientId
                                      )?.Name) ??
                                    "",
                                }}
                                disabled={isSubmitting}
                                showSearch={true}
                                setFieldValueDefault={true}
                                //onChange={() => getMasterData()}
                              />
                              <ErrorMessage
                                name="clientId"
                                component="div"
                                className="errorMsg"
                              />
                            </div>
                          </div>
                        )}
                      <GetDataAsPerType />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end gap-3 pt-6">
                    <ResetButton
                      name="Cancel"
                      className="btn btn-light btn-active-light-primary"
                      onClickCallback={() => {
                        closeModal();
                        //setShow(false);
                      }}
                    />
                    <SubmitButton
                      className="btn btn-primary"
                      isSubmitting={isSubmitting}
                      name="Submit"
                    />
                  </div>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterDataList: state.masterData,
    billDetails: state.bill,
    reimbursementInvoiceDetails: state.reimbursementInvoiceList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateBillDispatch: (data: addBillValue, successCallback: Function) =>
      dispatch(updateBill(data, successCallback)),
    updateReimbursementInvoiceDispatch: (
      data: any,
      successCallback: Function
    ) => dispatch(updateReimbursementInvoice(data, successCallback)),
    getAllBillingDetails: (searchObj: searchParams) =>
      dispatch(getBillList(searchObj)),
    reimbursementInvoiceListDispatch: (searchObj: searchParams) =>
      dispatch(getReimbursementInvoiceList(searchObj)),
    addTransactionDispatch: (
      data: addTransactionValue,
      successCallback: Function
    ) => dispatch(addTransaction(data, successCallback)),
    getMasterDataList: () => dispatch(getMasterData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTransactionShrt);
