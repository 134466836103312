import { FC } from "react";
import { object, string } from "yup";

import { adminHomePage } from "../../main/admin/AdminMain";
import { absolutePath } from "../../../../helpers/relativePath";
import {
  changeTextToFirstUppercase,
  emptyObject,
} from "../../../../helpers/helperFunction";
import { documentTypeData } from "../DocumentTypeForm";
import { documentTypeListPage } from "../DocumentTypeList";
import { DOCUMENT_TYPE_LIST } from "../../../../helpers/routes";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import { DOCUMENT_TYPE_REPEATATION } from "app/helpers/constants";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";
import { useNavigate, useParams } from "react-router-dom";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { useSendDataChangeWarning } from "app/helpers/webSocketHelper";

interface props {
  initialValue: documentTypeData;
  documentTypeDispatch: Function;
  title: string;
}

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  documentTypeListPage,
  emptyObject,
];

const REPETATION_PERIOD_OPTION = [
  { value: DOCUMENT_TYPE_REPEATATION.Monthly, name: "Monthly" },
  { value: DOCUMENT_TYPE_REPEATATION.Quarterly, name: "Quarterly" },
  { value: DOCUMENT_TYPE_REPEATATION.HalfYearly, name: "Half Yearly" },
  { value: DOCUMENT_TYPE_REPEATATION.Yearly, name: "Yearly" },
];

const validateFun = object().shape({
  name: string().required("Document type name is required"),
  repeatation: string().required("Time period is required"),
});

const AddDocumentType: FC<props> = ({
  title,
  documentTypeDispatch,
  initialValue,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const sendDataChangeWarning = useSendDataChangeWarning();

  return (
    <div className="container-fluid px-0 client-form-container">
      <PageTitle breadcrumbs={accountBreadCrumbs}>{title}</PageTitle>
      <div className="card">
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={validateFun}
          onSubmit={async (values, { resetForm }) => {
            await documentTypeDispatch(
              values,
              params.id
                ? () => {
                    navigate(absolutePath(DOCUMENT_TYPE_LIST));
                    sendDataChangeWarning();
                  }
                : () => {
                    resetForm();
                    sendDataChangeWarning();
                  }
            );
          }}
        >
          {({ values, touched, errors, isSubmitting, setFieldValue }) => {
            return (
              <Form noValidate className="form">
                <div className="p-7">
                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Document Type Name
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid ${
                            touched.name &&
                            errors.name &&
                            "is-invalid inValidBorder"
                          }`}
                          placeholder="Document Type Name"
                          id="name"
                          name="name"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Time Period
                      </label>

                      <div className="">
                        <DropDown
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          placeholder="Select Time Period"
                          options={REPETATION_PERIOD_OPTION}
                          setFieldValue={setFieldValue}
                          name="repeatation"
                          currentValue={{
                            value: values.repeatation,
                            name: changeTextToFirstUppercase(
                              DOCUMENT_TYPE_REPEATATION[
                                Number(values.repeatation)
                              ] || ""
                            ),
                          }}
                          disabled={isSubmitting}
                          showSearch={true}
                        />
                        <ErrorMessage
                          name="repeatation"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end py-6 px-7 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() =>
                      navigate(absolutePath(DOCUMENT_TYPE_LIST))
                    }
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddDocumentType;
