import { searchParams } from "app/helpers/commonInterface";
import { TRANSACTION_TYPE } from "app/helpers/constants";
import { useGetSearchObject } from "app/helpers/customHooks";
import {
  convert,
  getDetailsAgainstTransaction,
} from "app/helpers/helperFunction";
import {
  cancelTransaction,
  getTransactionReportList,
} from "app/reducers/transaction/transactionAction";
import { FC, useState } from "react";
import { connect } from "react-redux";
import { CancelTransactionPopup } from "./CancelTransactionPopup";

interface props {
  row: any;
  cancelTransactionDispatch: Function;
  getTransactionReportDispatch: Function;
}

const TransactionReportTableRow: FC<props> = ({
  row,
  getTransactionReportDispatch,
  cancelTransactionDispatch,
}) => {
  const { searchObj } = useGetSearchObject();
  const [showModal, setShowModal] = useState(false);

  const handleCancelTransaction = async () => {
    await cancelTransactionDispatch(row?.Id, row?.IsPayment);
    setTimeout(() => {
      getTransactionReportDispatch(searchObj);
    }, 500);
    
    setShowModal(false);
  };

  return (
    <tr style={!row.IsActive ? (localStorage.getItem("kt_theme_mode_value") === "light") ? { backgroundColor: "#ffcccc" } : {backgroundColor: "#3a2434"} : {} }
    >
      <td className="text-dark fw-bold ps-2">
        {row?.Type ? TRANSACTION_TYPE[Number(row.Type)] : "-"}
      </td>
      <td>
        <div className="overflow-auto mh-75px vertical-scroll mw-375px d-flex flex-column">
          {row.Type !== TRANSACTION_TYPE.Other && (
            <span className="text-dark fw-semibold">
              {getDetailsAgainstTransaction(row).value}
            </span>
          )}
          <span className="text-gray-500 fw-semibold">
            {row?.Narration || "-"}
          </span>
        </div>
      </td>
      <td>{row?.FirmName || "-"}</td>
      <td>{row?.BookName || "-"}</td>
      <td>{row?.ClientName || "-"}</td>
      <td>{row?.TransactionDate ? convert(row.TransactionDate) : "-"}</td>
      <td>
        {!row?.IsPayment ? (
          <span className="fw-semibold text-success">
            {row?.Amount !== null && row?.Amount !== undefined
              ? `+${row.Amount}`
              : "-"}
          </span>
        ) : (
          <span className="fw-semibold text-danger">
            {row?.Amount !== null && row?.Amount !== undefined
              ? `-${row.Amount}`
              : "-"}
          </span>
        )}
      </td>
      <td>
        <button
          className={`p-2 btn btn-active-light-danger btn-bg-light btn-sm fs-6 text-gray-600 text-hover-danger`}
          title="Cancel Transaction"
          onClick={() => setShowModal(true)}
          disabled={!row.IsActive}
        >
          Cancel
        </button>
      </td>
      <CancelTransactionPopup
        show={showModal}
        onHide={() => setShowModal(false)}
        onConfirm={handleCancelTransaction}
      />
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    cancelTransactionDispatch: (id: number, isPayment: boolean) => {
      dispatch(cancelTransaction(id, isPayment));
    },
    getTransactionReportDispatch: (searchObj: searchParams) =>
      dispatch(getTransactionReportList(searchObj)),
  };
};

export default connect(null, mapDispatchToProps)(TransactionReportTableRow);
