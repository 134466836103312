import { useSelector } from "react-redux";
import { shouldHideCompletionApproveButton, shouldHideGenerateInvoiceButton, shouldShowGenerateReimbursementButton } from "../taskHelper";

export const useTaskButtonVisibility = () => {
  const selectedTaskDetails = useSelector((state: any) => state.selectedTask);

  const doNotShowGenerateInvoiceBtn = shouldHideGenerateInvoiceButton(
    selectedTaskDetails.selected,
    selectedTaskDetails.taskListData
  );

  const showGenerateReimbursementBtn = shouldShowGenerateReimbursementButton(
    selectedTaskDetails.selected,
    selectedTaskDetails.taskListData
  );

  const doNotShowCompletionApproveBtn = shouldHideCompletionApproveButton(
    selectedTaskDetails.selected,
    selectedTaskDetails.taskListData
  );

  return { doNotShowGenerateInvoiceBtn, showGenerateReimbursementBtn, doNotShowCompletionApproveBtn };
};
