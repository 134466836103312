import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { number, object, array } from "yup";
import { mapBillValue } from "../BillingForm";
import { getBillList } from "app/reducers/billing/billingAction";
import { getSearchParameter } from "app/helpers/helperFunction";
import { useSearchParams } from "react-router-dom";
import { searchParams } from "app/helpers/commonInterface";
import { getReimbursementInvoiceList } from "app/reducers/reimbursement/reimbursementAction";

interface Props {
  showAdvancePaymentPopup: {
    show: boolean;
    clientId: number | null;
    invoiceId: number | null;
    dueAmount: number;
  };
  setShowAdvancePaymentPopup: Function;
  clientAdvancePayments?: any[];
  updateFun: Function;
  getAllBillingDetails?: Function;
  isReimbursement: boolean;
  reimbursementInvoiceListDispatch: Function;
}

const AdvancePaymentPopup: FC<Props> = ({
  showAdvancePaymentPopup,
  setShowAdvancePaymentPopup,
  clientAdvancePayments,
  updateFun,
  getAllBillingDetails,
  isReimbursement,
  reimbursementInvoiceListDispatch,
}) => {
  const validateTotal = (values: any) => {
    const errors: any = {};

    // Calculate total advance payment
    const sumAdvancePayments = values?.AppliedAdvances?.reduce(
      (sum: number, item: any) => sum + (item.value ? Number(item.value) : 0),
      0
    );

    // If total exceeds allowed amount, return a validation error
    if (sumAdvancePayments > showAdvancePaymentPopup.dueAmount) {
      errors.totalAdvanceError =
        "Total advance payments exceed the due amount!";
    }

    return errors;
  };

  const [searchParam] = useSearchParams();
  const searchObj = useMemo(
    () => getSearchParameter(searchParam),
    [searchParam]
  );
  const closeModal = () => {
    setShowAdvancePaymentPopup({
      show: false,
      clientId: null,
      invoiceId: null,
    });
  };

  return (
    <Modal
      show={showAdvancePaymentPopup.show}
      aria-labelledby="contained-modal-title"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Map Advance Payments</Modal.Title>
      </Modal.Header>
      <Modal.Body className="fs-6 fw-semibold">
        {clientAdvancePayments && clientAdvancePayments.length > 0 ? (
          <Formik
            enableReinitialize
            initialValues={{
              ClientId: showAdvancePaymentPopup.clientId,
              BillId: !isReimbursement
                ? showAdvancePaymentPopup.invoiceId
                : null,
              ReimbursemenId: isReimbursement
                ? showAdvancePaymentPopup.invoiceId
                : null,
              IsReimbursement: isReimbursement,
              AppliedAdvances: clientAdvancePayments.map((payment) => ({
                key: payment.Id,
                value: null,
              })),
            }}
            validate={validateTotal}
            //   validationSchema={object().shape({
            //     appliedAdvances: array().of(
            //       object().shape({
            //         key: number().required(),
            //         value: number()
            //           .min(1, "Amount must be greater than 0")
            //           .max(1000000, "Amount too large"),
            //       })
            //     ),
            //   })}
            onSubmit={async (values, { resetForm }) => {
              const mappedData: mapBillValue = {
                ClientId: showAdvancePaymentPopup.clientId,
                BillId: !isReimbursement
                  ? showAdvancePaymentPopup.invoiceId
                  : null,
                ReimbursementId: isReimbursement
                  ? showAdvancePaymentPopup.invoiceId
                  : null,
                IsReimbursement: isReimbursement,
                AppliedAdvances: values.AppliedAdvances.filter(
                  (item) => item.value
                ).map((item) => ({
                  key: item.key,
                  value: Number(item.value),
                })),
              };

              await updateFun(mappedData, () => {
                getAllBillingDetails &&
                  !isReimbursement &&
                  getAllBillingDetails(searchObj);
                reimbursementInvoiceListDispatch &&
                  isReimbursement &&
                  reimbursementInvoiceListDispatch(searchObj);
                resetForm();
                closeModal();
              });
            }}
          >
            {({ values, setFieldValue, isSubmitting, errors, setErrors }) => (
              <Form noValidate className="form">
                {(
                  <div className="col-form-label fw-semibold fs-4 text-success">
                    Due Amount: {showAdvancePaymentPopup.dueAmount}
                  </div>
                )}
                <table className="table table-bordered align-middle">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientAdvancePayments.map((payment, index) => (
                      <tr key={payment.Id}>
                        <td>
                          {new Date(payment.TransactionDate).toLocaleDateString(
                            "en-GB"
                          )}
                        </td>
                        <td>{payment.RemainingAmount}</td>
                        <td>
                          <Field
                            type="number"
                            className="form-control"
                            name={`AppliedAdvances[${index}].value`}
                            placeholder="Enter amount"
                            onChange={(e: any) =>
                              setFieldValue(
                                `AppliedAdvances[${index}].value`,
                                e.target.value
                              )
                            }
                          />
                          <ErrorMessage
                            name={`AppliedAdvances[${index}].value`}
                            component="div"
                            className="errorMsg"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {(errors as any).totalAdvanceError && (
                  <div className="errorMsg">
                    {(errors as any).totalAdvanceError}
                  </div>
                )}
                <div className="d-flex gap-3 justify-content-end mt-4">
                  <ResetButton
                    className="btn btn-light btn-sm"
                    name="Cancel"
                    onClickCallback={closeModal}
                  />
                  <SubmitButton
                    className="btn btn-primary btn-sm"
                    isSubmitting={isSubmitting}
                    name="Submit"
                  />
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <div>No Advance Payments</div>
            <div className="d-flex gap-3 justify-content-end mt-4">
              <ResetButton
                className="btn btn-light btn-sm"
                name="Close"
                onClickCallback={closeModal}
              />
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

// const mapStateToProps = (state: any) => {
//   return {
//     clientAdvancePayments: state.clientAdvancePayments.advancePayments,
//   };
// };

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllBillingDetails: (searchObj: searchParams) =>
      dispatch(getBillList(searchObj)),
    reimbursementInvoiceListDispatch: (searchObj: searchParams) =>
      dispatch(getReimbursementInvoiceList(searchObj)),
  };
};

export default connect(null, mapDispatchToProps)(AdvancePaymentPopup);
