import { ChangeEventHandler, FC, Fragment } from "react";

import ErrorBoundary from "app/helpers/ErrorBoundary";
import ErrorDisplay from "../ErrorDisplay";
import Loader from "../loader/Loader";
import ItemsPerPage from "./ItemsPerPage";
import { Paginate } from "./Paginate";
import { UserCustomHeader } from "./UserCustomHeader";

interface headers {
  className: string;
  id: string;
  columnName: string;
}

interface props<T> {
  checkBoxColumn: {
    show: boolean;
    checked?: boolean;
    onChangeFun?: ChangeEventHandler<HTMLInputElement>;
  };
  loading: boolean;
  error: string;
  tableData: Array<any> | undefined;
  success: boolean | undefined;
  headers: Array<headers>;
  paginate: {
    show: boolean;
    pagination?: { total_record_count: number };
    PageNumber?: number;
    PageSize?: number;
  };
  tableRow: (row: T, index: number) => JSX.Element;
}

const Table: FC<props<any>> = ({
  success,
  checkBoxColumn,
  paginate,
  headers,
  loading,
  error,
  tableData,
  tableRow,
}) => {
  return (
    <>
      <div className="px-7 py-4">
        <div className="table-responsive horizontal-scroll">
          <table
            id="kt_table_users"
            className="align-middle gs-0 gy-4 table table-row-dashed table-row-gray-300 table-striped"
          >
            <thead>
              <tr className="text-start text-muted fw-bold">
                {checkBoxColumn.show && (
                  <th className="w-10px pe-2 ps-2">
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={checkBoxColumn.checked}
                        onChange={checkBoxColumn.onChangeFun}
                      />
                    </div>
                  </th>
                )}
                <UserCustomHeader headers={headers} />
              </tr>
            </thead>
            <ErrorBoundary>
              <tbody className="text-gray-600">
                {loading ? (
                  <tr>
                    <td colSpan={headers.length + 1}>
                      <Loader />
                    </td>
                  </tr>
                ) : error ? (
                  <tr>
                    <td colSpan={headers.length + 1}>
                      <ErrorDisplay error={error} />
                    </td>
                  </tr>
                ) : success && tableData?.length !== 0 ? (
                  tableData?.map((row: any, index: number) => {
                    return (
                      <Fragment key={index}>{tableRow(row, index)}</Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={headers.length + 1}>
                      <div className="d-flex text-center w-100 align-content-center justify-content-center">
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </ErrorBoundary>
          </table>
        </div>
      </div>
      {paginate.show &&
      !loading &&
      success &&
      tableData?.length !== 0 &&
      paginate.PageNumber != null &&
      paginate.PageSize != null &&
      paginate.pagination != null ? (
        <div className="d-flex flex-row justify-content-between align-items-center card-footer border-0 pt-0">
          <div className="d-flex align-items-center">
          <span className="me-2 text-muted">
              {(() => {
                const start = (paginate.PageNumber - 1) * paginate.PageSize + 1;
                const end = Math.min(
                  paginate.PageNumber * paginate.PageSize,
                  paginate.pagination.total_record_count
                );
                return `Showing ${start} - ${end} of ${paginate.pagination.total_record_count}`;
              })()}
            </span>
            <ItemsPerPage
              pageSize={paginate?.PageSize ? String(paginate.PageSize) : "10"}
            />
          </div>

          {paginate?.pagination?.total_record_count !== 0 && (
            <div className="d-flex align-items-center">
              <Paginate
                itemsPerPage={Number(paginate.PageSize) || 10}
                itemLength={Number(paginate?.pagination?.total_record_count)}
                currentPage={Number(paginate.PageNumber || 1)}
              />
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Table;
