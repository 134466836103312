import { FormikErrors, FormikTouched, FormikValues } from "formik";
import { toast } from "react-toastify";

import {
  APPROVED,
  ASSIGNED,
  COMPLETED,
  IN_PROGRESS,
  NOTURGENT,
  ON_HOLD,
  REJECTED,
  SENT_FOR_CHECK,
  PENDING_FROM_CLIENT,
  TRANSACTION_TYPE,
  UNASSIGNED,
  URGENT,
  TAX_PENDING,
  COMPLETION_APPROVED,
} from "./constants";
import { filterObject, searchParams } from "./commonInterface";
import {
  taskPayments,
  taskReceipts,
} from "app/components/pages/proformaInvoice/proformaInvoiceDetailComponent/ProformaInvoiceTransactionDetail";
import { useDispatch } from "react-redux";
import { updateTaskStatus } from "app/reducers/task/taskAction";
import { useParams } from "react-router-dom";
import { values } from "lodash";
import hasPermission, { actionsRole } from "app/components/pages/auth/core/permission";
import { getAuth } from "app/components/pages/auth";

//searchobj interface
interface searchObj {
  [key: string]: any;
}

//toast message
export const showToastMessageSuccess = (message: string) => {
  toast.success(message, {});
};
export const showToastMessageFailure = (message: string) => {
  toast.error(message, {});
};

//date converter
export function convert(str: string): string {
  let date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("-");
}

//get search parameter from url data
export function getSearchParameter(search: any) {
  const params: searchObj = {};
  search.forEach((value: any, key: any) => {
    params[key] = value;
  });
  return { ...params };
}

//convert to querystring for api
export function getSearchQueryString(searchObj: searchParams) {
  let queryString = [];
  for (const data of Object.entries(searchObj)) {
    queryString.push(data.join("="));
  }
  return queryString.join("&");
}
// convert with different format
export function getFilterQueryString(filterData: filterObject) {
  let queryString = [];
  for (const data of Object.entries(filterData)) {
    queryString.push(data.join("="));
  }
  return queryString.join(",");
}

export const getDataFromFilterQueryString = (filterStr: string | undefined) => {
  if (filterStr) {
    const splitedArr = filterStr.split(",");
    const splitedObj = splitedArr.reduce(
      (acc: { [key: string]: string }, search: string) => {
        const a1 = search.split("=");
        acc[a1[0]] = a1[1];
        return acc;
      },
      {}
    );
    return splitedObj;
  }
  return {};
};

export const changeFormat = (dueDate: string) => {
  return dueDate.split("T");
};

// convert json data to formdata
export function getFormData(values: object): FormData {
  const bodyFormData = new FormData();

  for (const [key, value] of Object.entries(values)) {
    if (value !== null && value !== undefined && value !== "") {
      bodyFormData.append(key, value);
    }
  }

  return bodyFormData;
}


//breadcrumbs last title object
export const emptyObject = {
  title: "",
  path: "",
  isSeparator: true,
  isActive: false,
};

export const nullObject = {
  title: "",
  path: "",
  isSeparator: false,
  isActive: false,
};

// get image for file upload
export const getImage = (name: string) => {
  switch (name) {
    case "docx":
      return "/media/task/doc-1.png";
    case "xlsx":
      return "/media/task/xls.png";
    case "pptx":
      return "/media/task/ppt.png";
    case "zip":
      return "/media/task/zip-file.png";
    case "pdf":
      return "/media/task/pdf-file.png";
    case "png":
    case "jpg":
    case "jpeg":
      return "/media/task/image-1.png";
    default:
      return "/media/task/doc-1.png";
  }
};

// get color for name letter (background and color)
export const getColor = (name: string) => {
  const firstLetter = name ? name[0]?.toUpperCase() : "";
  switch (true) {
    case firstLetter >= "A" && firstLetter <= "B":
      return { color: "f1416c", background: "f1416c1a" };
    case firstLetter >= "C" && firstLetter <= "D":
      return { color: "3b5998", background: "3b59981a" };
    case firstLetter >= "E" && firstLetter <= "F":
      return { color: "dd4b39", background: "dd4b391a" };
    case firstLetter >= "G" && firstLetter <= "H":
      return { color: "7239ea", background: "7239ea1a" };
    case firstLetter >= "I" && firstLetter <= "L":
      return { color: "e1306c", background: "e1306c1a" };
    case firstLetter >= "J" && firstLetter <= "K": 
      return { color: "0077b5", background: "0077b51a" }
    case firstLetter >= "M" && firstLetter <= "N":
      return { color: "009ef7", background: "009ef71a" };
    case firstLetter >= "O" && firstLetter <= "P":
      return { color: "334FC9", background: "334FC91a" };
    case firstLetter >= "Q" && firstLetter <= "R":
      return { color: "50cd89", background: "50cd891a" };
    case firstLetter >= "S" && firstLetter <= "T":
      return { color: "1da1f2", background: "1da1f21a" };
    case firstLetter >= "U" && firstLetter <= "V":
      return { color: "ffc700", background: "ffc7001a" };
    case firstLetter >= "W" && firstLetter <= "Z":
      return { color: "ff0000", background: "ff00001a" };
    case firstLetter >= "R" && firstLetter <= "Y" :
      return { color: "ff0000", background: "ff00001a" }
    default:
      return { color: "7B0F96", background: "7B0F961a" };
  }
};

// get formated time
export const getFormatedTime = (str: string) => {
  let date = new Date(str),
    hours = ("0" + date.getHours()).slice(-2),
    minutes = ("0" + date.getMinutes()).slice(-2);
  return [hours, minutes].join(":");
};

//convert date into indian date format
export const convertToLocalDate = (str: string): string => {
  let date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("/");
};

//convert with year ahead
export const convertDate = (str: string): string => {
  let date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
};

//convert date into number of days/month/year
export const dateConverter = (postedDate: any): any => {
  const date: any = new Date();
  const currentDate: string = convertDate(date);
  const createdDate: string = convertDate(postedDate);
  const d1: any = new Date(currentDate);
  const d2: any = new Date(createdDate);
  const day = Math.ceil(Math.abs(d1 - d2) / (1000 * 60 * 60 * 24));
  if (day === 0) {
    return "Today";
  } else {
    if (Math.floor(day / 365) > 0) {
      return `${Math.floor(day / 365)} year ago`;
    } else if (Math.floor(day / 30) > 0) {
      return `${Math.floor(day / 30)} month ago`;
    } else if (Math.floor(day / 7) > 0) {
      return `${Math.floor(day / 7)} week ago`;
    } else {
      return `${day} day ago`;
    }
  }
};

//create cookie
export function createCookie(name: any, value: any, minutes: any) {
  var expires;
  if (minutes) {
    var date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

//get cookie
export function getCookie(cname: any) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

//get status array from current status

export const getStatusArray = (status: number) => {
  switch (status) {
    case 1:
      return [
        // { value: UNASSIGNED.id, name: UNASSIGNED.name },
        { value: ASSIGNED.id, name: ASSIGNED.name },
      ];

    case 2:
      return [
        { value: ASSIGNED.id, name: ASSIGNED.name },
        { value: SENT_FOR_CHECK.id, name: SENT_FOR_CHECK.name },
        { value: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name },
        { value: COMPLETED.id, name: COMPLETED.name },
        { value: APPROVED.id, name: APPROVED.name },
        { value: ON_HOLD.id, name: ON_HOLD.name },
      ];

    case 3:
      return [
        { value: SENT_FOR_CHECK.id, name: SENT_FOR_CHECK.name },
        { value: ASSIGNED.id, name: ASSIGNED.name },
        { value: COMPLETED.id, name: COMPLETED.name },
        { value: APPROVED.id, name: APPROVED.name },
        { value: REJECTED.id, name: REJECTED.name },
      ];
    case 4:
      return [
        { value: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name },
        { value: ASSIGNED.id, name: ASSIGNED.name },
        { value: COMPLETED.id, name: COMPLETED.name },
        { value: APPROVED.id, name: APPROVED.name },
        { value: REJECTED.id, name: REJECTED.name },
      ];

    case 5:
      return [
        { value: COMPLETED.id, name: COMPLETED.name },
        { value: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name },
        { value: APPROVED.id, name: APPROVED.name },
        { value: ON_HOLD.id, name: ON_HOLD.name },
      ];
    case 6:
    case 9:
      return [
        { value: REJECTED.id, name: REJECTED.name },
        { value: SENT_FOR_CHECK.id, name: SENT_FOR_CHECK.name },
        { value: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name },
      ];

    case 10:
      return [
        // { value: UNASSIGNED.id, name: UNASSIGNED.name },
        { value: ASSIGNED.id, name: ASSIGNED.name },
        { value: SENT_FOR_CHECK.id, name: SENT_FOR_CHECK.name },
        { value: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name },
        { value: COMPLETED.id, name: COMPLETED.name },
        { value: APPROVED.id, name: APPROVED.name },
        { value: ON_HOLD.id, name: ON_HOLD.name },
        { value: REJECTED.id, name: REJECTED.name },
      ];

    default:
      return [
        // { value: UNASSIGNED.id, name: UNASSIGNED.name },
        { value: ASSIGNED.id, name: ASSIGNED.name },
        { value: SENT_FOR_CHECK.id, name: SENT_FOR_CHECK.name },
        { value: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name },
        { value: COMPLETED.id, name: COMPLETED.name },
        { value: APPROVED.id, name: APPROVED.name },
        { value: ON_HOLD.id, name: ON_HOLD.name },
        { value: REJECTED.id, name: REJECTED.name },
      ];
  }
};

export const getStatusArr = (status: number, managerId?: string) => {
  const user = getAuth();
  const access = managerId ? user?.Id == managerId : false;
  switch (status) {
    case 1:
      return [
        // { value: UNASSIGNED.id, name: UNASSIGNED.name },
        { value: ASSIGNED.id, name: ASSIGNED.name },
      ];

    case 2:
      return [
        { value: ASSIGNED.id, name: ASSIGNED.name },
        { value: IN_PROGRESS.id, name: IN_PROGRESS.name },
        { value: SENT_FOR_CHECK.id, name: SENT_FOR_CHECK.name },
        { value: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name },
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: ON_HOLD.id, name: ON_HOLD.name }] : []),
        { value: TAX_PENDING.id, name: TAX_PENDING.name },
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: COMPLETED.id, name: COMPLETED.name }] : []),
        ...(hasPermission(actionsRole.TEAM_LEAD) ? [{ value: APPROVED.id, name: APPROVED.name }] : []),
      ];
      
    case 3:
      return [
        { value: IN_PROGRESS.id, name: IN_PROGRESS.name },
        { value: SENT_FOR_CHECK.id, name: SENT_FOR_CHECK.name },
        { value: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name },
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: ON_HOLD.id, name: ON_HOLD.name }] : []),
        { value: TAX_PENDING.id, name: TAX_PENDING.name },
        //{ value: ASSIGNED.id, name: ASSIGNED.name },
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: COMPLETED.id, name: COMPLETED.name }] : []),
        ...(hasPermission(actionsRole.TEAM_LEAD) ? [{ value: APPROVED.id, name: APPROVED.name }] : []),
        { value: REJECTED.id, name: REJECTED.name },
      ];
    case 4:
      return [
        { value: SENT_FOR_CHECK.id, name: SENT_FOR_CHECK.name },
        //{ value: ASSIGNED.id, name: ASSIGNED.name },
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: COMPLETED.id, name: COMPLETED.name }] : []),
        ...(hasPermission(actionsRole.TEAM_LEAD) ? [{ value: APPROVED.id, name: APPROVED.name }] : []),
        { value: REJECTED.id, name: REJECTED.name },
      ];
    case 5:
      return [
        { value: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name },
        { value: IN_PROGRESS.id, name: IN_PROGRESS.name },
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: ON_HOLD.id, name: ON_HOLD.name }] : []),
        { value: TAX_PENDING.id, name: TAX_PENDING.name },
        //{ value: ASSIGNED.id, name: ASSIGNED.name },
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: COMPLETED.id, name: COMPLETED.name }] : []),
        ...(hasPermission(actionsRole.TEAM_LEAD) ? [{ value: APPROVED.id, name: APPROVED.name }] : []),
        { value: REJECTED.id, name: REJECTED.name },
      ];

    case 6:
      return [
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: COMPLETED.id, name: COMPLETED.name }] : []),
        { value: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name },
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: ON_HOLD.id, name: ON_HOLD.name }] : []),
        { value: TAX_PENDING.id, name: TAX_PENDING.name },
        ...(hasPermission(actionsRole.TEAM_LEAD) ? [{ value: APPROVED.id, name: APPROVED.name }] : []),
      ];
    case 7:
    case 8:
      return [
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: ON_HOLD.id, name: ON_HOLD.name }] : []),
        { value: IN_PROGRESS.id, name: IN_PROGRESS.name },
        { value: SENT_FOR_CHECK.id, name: SENT_FOR_CHECK.name },
        { value: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name },
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: COMPLETED.id, name: COMPLETED.name }] : []),
        ...(hasPermission(actionsRole.TEAM_LEAD) ? [{ value: APPROVED.id, name: APPROVED.name }] : []),
        { value: TAX_PENDING.id, name: TAX_PENDING.name },
        { value: REJECTED.id, name: REJECTED.name },
      ];
    case 9:
      return [
        { value: TAX_PENDING.id, name: TAX_PENDING.name },
        { value: IN_PROGRESS.id, name: IN_PROGRESS.name },
        { value: SENT_FOR_CHECK.id, name: SENT_FOR_CHECK.name },
        { value: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name },
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: ON_HOLD.id, name: ON_HOLD.name }] : []),
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: COMPLETED.id, name: COMPLETED.name }] : []),
        ...(hasPermission(actionsRole.TEAM_LEAD) ? [{ value: APPROVED.id, name: APPROVED.name }] : []),
        { value: REJECTED.id, name: REJECTED.name },
      ];

    case 10:
      return [
        // { value: UNASSIGNED.id, name: UNASSIGNED.name },
        { value: REJECTED.id, name: REJECTED.name },
        { value: SENT_FOR_CHECK.id, name: SENT_FOR_CHECK.name },
        { value: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name },
        //{ value: COMPLETED.id, name: COMPLETED.name },
        { value: TAX_PENDING.id, name: TAX_PENDING.name },
        ...(hasPermission(actionsRole.TEAM_LEAD) ? [{ value: APPROVED.id, name: APPROVED.name }] : []),
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: ON_HOLD.id, name: ON_HOLD.name }] : []),
      ];

    default:
      return [
        // { value: UNASSIGNED.id, name: UNASSIGNED.name },
        { value: ASSIGNED.id, name: ASSIGNED.name },
        { value: IN_PROGRESS.id, name: IN_PROGRESS.name },
        { value: SENT_FOR_CHECK.id, name: SENT_FOR_CHECK.name },
        { value: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name },
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: COMPLETED.id, name: COMPLETED.name }] : []),
        ...(hasPermission(actionsRole.TEAM_LEAD) ? [{ value: APPROVED.id, name: APPROVED.name }] : []),
        ...((hasPermission(actionsRole.ADMIN) || access) ? [{ value: ON_HOLD.id, name: ON_HOLD.name }] : []),
        { value: TAX_PENDING.id, name: TAX_PENDING.name },
        { value: REJECTED.id, name: REJECTED.name },
      ];
  }
};

export const getUrgent = () => {
  return [
    { value: URGENT.id, name: URGENT.name },
    { value: NOTURGENT.id, name: NOTURGENT.name },
  ];
};

//get status from its id
export const getStatuses = (statusId: number) => {
  switch (statusId) {
    // case 1:
    //   return { id: UNASSIGNED.id, name: UNASSIGNED.name };

    case 2:
      return { id: ASSIGNED.id, name: ASSIGNED.name };

    case 3:
      return { id: SENT_FOR_CHECK.id, name: SENT_FOR_CHECK.name };

    case 4:
      return { id: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name };

    case 5:
      return { id: COMPLETED.id, name: COMPLETED.name };

    case 6:
      return { id: APPROVED.id, name: APPROVED.name };

    case 7:
      return { id: ON_HOLD.id, name: ON_HOLD.name };

    // case 8:
    //   return { id: PAUSED.id, name: PAUSED.name };

    case 9:
      return { id: REJECTED.id, name: REJECTED.name };

    default:
      return { id: ASSIGNED.id, name: ASSIGNED.name };
  }
};

export const getStatus = (statusId: number) => {
  switch (statusId) {
    // case 1:
    //   return { id: UNASSIGNED.id, name: UNASSIGNED.name };

    case 2:
      return { id: ASSIGNED.id, name: ASSIGNED.name };

    case 3:
      return { id: IN_PROGRESS.id, name: IN_PROGRESS.name };

    case 4:
      return { id: SENT_FOR_CHECK.id, name: SENT_FOR_CHECK.name };

    case 5:
      return { id: PENDING_FROM_CLIENT.id, name: PENDING_FROM_CLIENT.name };

    case 6:
      return { id: COMPLETED.id, name: COMPLETED.name };

    case 7:
      return { id: APPROVED.id, name: APPROVED.name };

    case 8:
      return { id: ON_HOLD.id, name: ON_HOLD.name };

    // case 8:
    //   return { id: PAUSED.id, name: PAUSED.name };

    case 9:
      return { id: TAX_PENDING.id, name: TAX_PENDING.name };

    case 10:
      return { id: REJECTED.id, name: REJECTED.name };

    case 11:
      return { id: COMPLETION_APPROVED.id, name: COMPLETION_APPROVED.name };

    default:
      return { id: ASSIGNED.id, name: ASSIGNED.name };
  }
};

//get role
export const getRole = (role: Array<string>) => {
  switch (true) {
    case role.includes("Admin"):
      return "Admin";
    case role.includes("Team Leader"):
      return "Team Leader";
    default:
      return "Article";
  }
};

//regex
const ALL_PARAMETER = /(\*|_|~)(.*?)\1/g;
const VARAIBLE_REPLACE_REGEX = /\{\{\d+\}\}/g;

//change simple text to html format
export const changeWpTemplateTextFormat = (str: string): string => {
  return str.replace(ALL_PARAMETER, (matched, p1, p2) => {
    switch (p1) {
      case "*": {
        return `<b>${p2}</b>`;
      }
      case "_": {
        return `<em>${p2}</em>`;
      }
      case "~": {
        return `<s>${p2}</s>`;
      }
      case "\n": {
        return `</p><p>`;
      }
      default:
        return matched;
    }
  });
};

//check for error
export const getError = (
  touched: FormikTouched<FormikValues>,
  errors: FormikErrors<FormikValues>,
  matched: string
) => {
  if (touched[matched] && errors[matched]) {
    return errors[matched];
  }
  return "";
};

//change varaible with input box
export const changeWpTemplateVariable = (
  str: string,
  className: string,
  errors?: FormikErrors<FormikValues>,
  touched?: FormikTouched<FormikValues>
): string => {
  return str.replace(VARAIBLE_REPLACE_REGEX, (matched) => {
    const errorMsg = errors && touched && getError(touched, errors, matched);
    return `<input type="text" 
            name=${matched} 
            id=${matched}
            placeholder=${matched}
            class="d-inline-block form-control form-control-sm form-control-solid ${className} ${
      errorMsg ? "is-invalid inValidBorder" : ""
    }"/>`;
  });
};

//convert first letter uppercase
export const changeTextToFirstUppercase = (word: string) => {
  if (!word) return word;
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getDetailsAgainstTransaction = (
  transaction: taskPayments | taskReceipts
) => {
  switch (transaction.Type) {
    case TRANSACTION_TYPE["Advance"]:
    case TRANSACTION_TYPE["GovernmentFees/Tax"]:
    case TRANSACTION_TYPE["Reimbursement"]:
      return { property: "TaskCode", value: transaction?.TaskCode || "-" };
    case TRANSACTION_TYPE["Against Bill"]:
      return {
        property: "Invoice No",
        value: (transaction as taskReceipts)?.BillNumber || "-",
      };
    case TRANSACTION_TYPE["Expense"]:
      return {
        property: "Expense",
        value: (transaction as taskPayments)?.ExpenseName || "-",
      };
    case TRANSACTION_TYPE["Against Reimbursement"]:
      return {
        property: "Reimbursement No",
        value: (transaction as taskReceipts)?.BillNumber || "-",
      }
    default:
      return { property: "-", value: "-" };
  }
};

export const convertTo12HourFormat = (time: string) => {
  // Split the input time string into components
  let [hours, minutes, seconds] = time.split(":").map(Number);

  // Determine AM or PM
  let period = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour time to 12-hour time
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'

  // Format the hours and minutes with leading zeros if necessary
  let formattedHours = String(hours).padStart(2, "0");
  let formattedMinutes = String(minutes).padStart(2, "0");

  // Construct the final formatted time string without seconds
  return `${formattedHours}:${formattedMinutes} ${period}`;
};

export const capitalizeFirstLetter = (str: string) => {
  if (str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const calculateDuration = (startTimeStr: string, endTimeStr: string) => {
  // Parse start and end times
  let startTime: any = new Date("1970-01-01T" + startTimeStr + "Z");
  let endTime: any = new Date("1970-01-01T" + endTimeStr + "Z");

  // Calculate the difference in milliseconds
  let durationMs = endTime - startTime;

  // Convert milliseconds to seconds
  let durationSeconds = durationMs / 1000;

  // Convert seconds to hours and minutes
  let hours = Math.floor(durationSeconds / 3600);
  let minutes = Math.floor((durationSeconds % 3600) / 60);

  // Determine the format of the result
  if (hours > 0) {
    return hours + " hour " + minutes + " minute";
  } else {
    return minutes + " minute";
  }
};

// use for drop down options
export const sortedArrayByName = (array: any[]) => {
  if (array != undefined) {
    array.sort((a, b) => a.name.localeCompare(b.name));
  }
  return array;
};
