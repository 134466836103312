import { PageLink, PageTitle } from "_metronic/layout/core";
import { absolutePath } from "app/helpers/relativePath";
import { adminHomePage } from "../main/admin/AdminMain";
import { emptyObject } from "app/helpers/helperFunction";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent";
import { KTCard } from "_metronic/helpers";
import ReimbursementInvoiceFilter from "./component/AdvancePaymentMappingFilter";
import { ADVANCE_PAYMENT_MAPPINGS_LIST } from "app/helpers/routes";
import AdvancePaymentMappingTable from "./component/AdvancePaymentMappingTable";
import AdvancePaymentMappingFilter from "./component/AdvancePaymentMappingFilter";

export const advancePaymentMappingsListPage = {
  title: "Advance Payment Mapping",
  path: absolutePath(ADVANCE_PAYMENT_MAPPINGS_LIST),
  isSeparator: true,
  isActive: true,
};

//export const addToReimbursement: Array<PageLink> = [emptyObject];

const accountBreadCrumbs: Array<PageLink> = [adminHomePage, emptyObject];

const AdvancePaymentMappingsList = () => {
  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Advance Payment Mapping</PageTitle>
      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4 position-relative">
        <UsersListSearchComponent />
        <AdvancePaymentMappingFilter />
      </div>
      <KTCard>
        <AdvancePaymentMappingTable />
      </KTCard>
    </>
  );
};

export default AdvancePaymentMappingsList;
