import { addClientValue } from "../../components/pages/client/ClientForm";
import { failure, request, success } from "../dispatchFunctions";
import { searchParams } from "../../helpers/commonInterface";
import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "../../helpers/helperFunction";
import {
  addClientService,
  getClientAdvancePaymentsService,
  getCLientByIdService,
  getCLientListService,
  getCLientReportDetailByIdService,
  getLegalStatusListService,
  importClientDataService,
  updateClientService,
  updateClientStatusService,
  updateContactStatusService,
} from "./clientService";
import {
  ADD_CLIENT_FAILURE,
  ADD_CLIENT_REQUEST,
  ADD_CLIENT_SUCCESS,
  GET_CLIENT_ADVANCE_PAYMENT_FAILURE,
  GET_CLIENT_ADVANCE_PAYMENT_REQUEST,
  GET_CLIENT_ADVANCE_PAYMENT_SUCCESS,
  GET_CLIENT_BY_ID_FAILURE,
  GET_CLIENT_BY_ID_REQUEST,
  GET_CLIENT_BY_ID_SUCCESS,
  GET_CLIENT_LIST_FAILURE,
  GET_CLIENT_LIST_REQUEST,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_REPORT_DETAILS_FAILURE,
  GET_CLIENT_REPORT_DETAILS_REQUEST,
  GET_CLIENT_REPORT_DETAILS_SUCCESS,
  GET_LEGAL_STATUS_LIST_FAILURE,
  GET_LEGAL_STATUS_LIST_REQUEST,
  GET_LEGAL_STATUS_LIST_SUCCESS,
  IMPORT_CLIENT_FAILURE,
  IMPORT_CLIENT_REQUEST,
  IMPORT_CLIENT_SUCCESS,
  UPDATE_CLIENT_BY_ID_FAILURE,
  UPDATE_CLIENT_BY_ID_REQUEST,
  UPDATE_CLIENT_BY_ID_SUCCESS,
  UPDATE_CLIENT_STATUS_FAILURE,
  UPDATE_CLIENT_STATUS_REQUEST,
  UPDATE_CLIENT_STATUS_SUCCESS,
} from "../actionTypes";
import { updateMasterData } from "../masterData/masterDataAction";
import {
  ADD_DATA_IN_MASTER,
  MASTER_TYPE,
  UPDATE_DATA_IN_MASTER,
  UPDATE_DATA_STATUS_IN_MASTER,
} from "../masterData/masterDataReducer";
import { GetErrorMessage } from "../stateHelperFunction";
import { importClientDataValue } from "app/components/pages/client/component/ImportModel";

export const addClient = (data: addClientValue, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_CLIENT_REQUEST));

    await addClientService(data).then(
      (result: any) => {
        dispatch(success(ADD_CLIENT_SUCCESS, result));
        showToastMessageSuccess("Client added");
        successCallback();
        dispatch(
          updateMasterData(ADD_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.CLIENTS,
            data: result.data.data.records,
          })
        );
        result.data.data.records.Tasks.length !== 0 &&
          dispatch(
            updateMasterData(ADD_DATA_IN_MASTER, {
              masterType: MASTER_TYPE.TASKS,
              data: result.data.data.records.Tasks,
            })
          );
      },
      (error: any) => {
        dispatch(
          failure(ADD_CLIENT_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const importClient = (data: any, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(IMPORT_CLIENT_REQUEST));

    try {
      const result = await importClientDataService(data);
      dispatch(success(IMPORT_CLIENT_SUCCESS, result));
      // showToastMessageSuccess("Client data imported");
      successCallback();
      dispatch(
        updateMasterData(ADD_DATA_IN_MASTER, {
          masterType: MASTER_TYPE.CLIENTS,
          data: result.data.data.records,
        })
      );
      if (result.data.data.records.Tasks.length !== 0) {
        dispatch(
          updateMasterData(ADD_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.TASKS,
            data: result.data.data.records.Tasks,
          })
        );
      }
      return result; // Return the response
    } catch (error: any) {
      dispatch(
        failure(IMPORT_CLIENT_FAILURE, GetErrorMessage(error?.response?.data))
      );
      showToastMessageFailure(GetErrorMessage(error?.response?.data));
      throw error; // Rethrow the error
    }
  };
};

export const getClientList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_CLIENT_LIST_REQUEST));

    getCLientListService(searchObj).then(
      (result: any) => dispatch(success(GET_CLIENT_LIST_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_CLIENT_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const getLegalStatusList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_LEGAL_STATUS_LIST_REQUEST));

    getLegalStatusListService(searchObj).then(
      (result: any) => dispatch(success(GET_LEGAL_STATUS_LIST_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_LEGAL_STATUS_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const updateClient = (
  data: addClientValue,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_CLIENT_BY_ID_REQUEST));

    await updateClientService(data).then(
      (result: any) => {
        dispatch(success(UPDATE_CLIENT_BY_ID_SUCCESS, result.data));
        showToastMessageSuccess("Client updated");
        successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.CLIENTS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_CLIENT_BY_ID_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getClientById = (clientId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_CLIENT_BY_ID_REQUEST));

    getCLientByIdService(clientId).then(
      (result: any) => dispatch(success(GET_CLIENT_BY_ID_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_CLIENT_BY_ID_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );

    // dispatch(success(GET_CLIENT_BY_ID_SUCCESS, {clientDataId, clientData}))
  };
};

export const updateClientStatus = (
  clientId: number,
  successCallback: Function
) => {
  return (dispatch: any) => {
    dispatch(request(UPDATE_CLIENT_STATUS_REQUEST));

    updateClientStatusService(clientId).then(
      (result: any) => {
        dispatch(success(UPDATE_CLIENT_STATUS_SUCCESS, result.data));
        showToastMessageSuccess("Client status updated");
        successCallback && successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_STATUS_IN_MASTER, {
            masterType: MASTER_TYPE.CLIENTS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_CLIENT_STATUS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
        successCallback && successCallback();
      }
    );
  };
};

export const updateContactStatus = (
  contactId: number,
  successCallback: Function
) => {
  return (dispatch: any) => {
    dispatch(request(UPDATE_CLIENT_BY_ID_REQUEST));

    updateContactStatusService(contactId).then(
      (result: any) => {
        dispatch(success(UPDATE_CLIENT_BY_ID_SUCCESS, result.data));
        showToastMessageSuccess("Contact person status updated");
        successCallback && successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_CLIENT_BY_ID_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getClientReportDetails = (clientId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_CLIENT_REPORT_DETAILS_REQUEST));

    getCLientReportDetailByIdService(clientId).then(
      (result: any) =>
        dispatch(success(GET_CLIENT_REPORT_DETAILS_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_CLIENT_REPORT_DETAILS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const getClientAdvancePayments = (clientId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_CLIENT_ADVANCE_PAYMENT_REQUEST));

    getClientAdvancePaymentsService(clientId).then(
      (result: any) =>
        dispatch(success(GET_CLIENT_ADVANCE_PAYMENT_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_CLIENT_ADVANCE_PAYMENT_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
