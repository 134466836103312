import { connect } from "react-redux";
import { FC, useEffect } from "react";

import {
  getBillingPendingTaskList,
  getTaskList,
  selectAllTask,
  unSelectAllTask,
} from "../../../../reducers/task/taskAction";
import { searchParams } from "../../../../helpers/commonInterface";
import { selectedState } from "../../../../reducers/task/taskSelectionReducer";
import TaskTableRow from "./TaskTableRow";
import Table from "app/components/commonComponent/table/Table";
import { taskListState } from "app/reducers/task/getTaskListReducer";
import { useGetSearchObject } from "app/helpers/customHooks";
import hasPaymentAccess from "../../auth/core/paymentAccess";

interface props {
  getAllTaskList: Function;
  taskListData: taskListState;
  selectedTaskDetails: selectedState;
  selectAllTaskDispatch: Function;
  unSelectAllTaskDispatch: Function;
}

const BillingPendingTaskTable: FC<props> = ({
  getAllTaskList,
  taskListData,
  unSelectAllTaskDispatch,
  selectedTaskDetails,
  selectAllTaskDispatch,
}) => {

  const HEADERS = [
    {
      columnName: "Task Code",
      id: "TaskCode",
      className: "min-w-110px ps-2",
    },
    {
      columnName: "Client Name",
      id: "ClientName",
      className: "min-w-110px",
    },
    {
      columnName: "Task",
      id: "TypeName",
      className: "min-w-110px",
    },
    {
      columnName: "Duration",
      id: "Duration",
      className: "min-w-110px",
    },
    {
      columnName: "Start Date",
      id: "StartDate",
      className: "min-w-125px",
    },
    {
      columnName: "Due Date",
      id: "DueDate",
      className: "min-w-125px",
    },
    {
      columnName: "Article",
      id: "PersonAssigned",
      className: "min-w-125px",
    },
    {
      columnName: "Managed By",
      id: "ManagerName",
      className: "min-w-125px",
    },
    {
      columnName: "Task Type",
      id: "IsRepeatative",
      className: "min-w-110px",
    },
    ...(hasPaymentAccess()
      ? [
          {
            columnName: "Billing Status",
            id: "BillingStatus",
            className: "min-w-125px",
          },
        ]
      : []),
    {
      columnName: "Work Status",
      id: "StatusValue",
      className: "min-w-110px",
    },
    {
      columnName: "Actions",
      id: "NULL",
      className: "min-w-125px",
    },
  ];
  
  const {searchObj} = useGetSearchObject();
  useEffect(() => {
    getAllTaskList(searchObj);
  }, [searchObj, getAllTaskList]);
  useEffect(() => {
    return () => {
      unSelectAllTaskDispatch();
    };
  }, []);
  const onChangeSelectAllHandler = (e: any) => {
    if (e.target.checked) {
      selectAllTaskDispatch();
    } else {
      unSelectAllTaskDispatch();
    }
  };

  return (
    <>
      
      <Table
        checkBoxColumn={{
          show: true,
          checked: selectedTaskDetails.isCheckedAll,
          onChangeFun: onChangeSelectAllHandler,
        }}
        error={taskListData.error}
        headers={HEADERS}
        success={taskListData.taskList?.success}
        loading={taskListData.loading}
        paginate={{
          show: true,
          pagination: taskListData.taskList?.pagination,
          PageNumber: Number(searchObj.PageNumber || 1),
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={taskListData.taskList?.data?.records}
        tableRow={(row: any, index: number) => (
          <TaskTableRow row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    taskListData: state.billingPendingTaskList,
    selectedTaskDetails: state.selectedTask,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllTaskList: (searchObj: searchParams) =>
      dispatch(getBillingPendingTaskList(searchObj)),
    unSelectAllTaskDispatch: () => dispatch(unSelectAllTask()),
    selectAllTaskDispatch: () => dispatch(selectAllTask()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingPendingTaskTable);
