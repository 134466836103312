import { connect } from "react-redux";
import { Form, Formik } from "formik";
import { FC } from "react";
import { Modal } from "react-bootstrap";

import { convert } from "app/helpers/helperFunction";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import GetUrlForPdf from "./component/GetUrlForPdf";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import Loader from "app/components/commonComponent/loader/Loader";
import { selectedContactPerson } from "../task/component/TaskGrouping";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { useAuth } from "../auth";

interface props {
  formatedBill: { show: boolean };
  addedBillDetails: any;
  closeModal: Function;
  contactPersons: Array<selectedContactPerson>;
  savedBillPdfDetails: any;
}

const BillFormat: FC<props> = ({
  savedBillPdfDetails,
  contactPersons,
  closeModal,
  addedBillDetails,
  formatedBill,
}) => {
  const { currentUser } = useAuth();
  const companyName = currentUser?.CompanyName;
  return (
    <Modal
      show={formatedBill.show}
      aria-labelledby="contained-modal-formated-bill"
      centered
      data-toggle="modal"
      backdrop="static"
      keyboard={false}
      className="formated-bill"
    >
      {addedBillDetails.loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {addedBillDetails.error && (
        <ErrorDisplay error={addedBillDetails.error} />
      )}
      {!addedBillDetails.loading &&
        addedBillDetails.addedBillData?.success &&
        (JSON.stringify(addedBillDetails.addedBillData?.data?.records) !==
        `{}` ? (
          <>
            <div className="border border-2 m-4">
              <Modal.Header className="d-flex flex-column modal-header p-3">
                <Modal.Title className="fs-2 fw-bold">
                  {addedBillDetails.addedBillData?.data?.records?.Firm?.Name}
                </Modal.Title>
                {addedBillDetails.addedBillData?.data?.records?.Firm?.IsCAFirm && <Modal.Title className="fs-6 text-muted">
                  Chartered Accountants
                </Modal.Title>}
              </Modal.Header>
              <Modal.Body className="p-0">
                {/* <div className="d-flex align-items-center border-bottom border-primary">
                  <div className="border-end border-2 pe-4 ps-4">
                    <ImageDisplay
                      altText="CALogo"
                      className="w-75px"
                      errorPath="/media/auth/logo_512.png"
                      path="/media/auth/logo_512.png"
                      title="CALogo"
                    />
                  </div>
                  <div className="d-flex justify-content-between w-100 ps-4">
                    <div className="fw-bold fs-3 text-dark">
                      {
                        addedBillDetails.addedBillData?.data?.records?.Firm
                          ?.Name
                      }
                    </div>
                    <div className="fs-6 fw-semibold text-muted">
                      FY: {new Date().getFullYear()}-
                      {+String(new Date().getFullYear()).slice(-2) + 1}
                    </div>
                  </div>
                </div> */}

                <div className="d-flex py-2">
                  <div className="w-75 pe-4">
                    {/* <div className="fs-6 text-muted mb-1">
                      PAN No.:{" "}
                      <span className="text-dark">
                        {addedBillDetails.addedBillData?.data?.records?.Firm
                          ?.PANNumber || "-"}
                      </span>
                    </div> */}
                    <div className="fs-6 text-muted mb-1 mt-4 fw-bold px-2">
                      Address:{" "}
                      <span className="text-dark">
                        {addedBillDetails.addedBillData?.data?.records?.Firm
                          ?.Address || "-"}
                      </span>
                    </div>
                    {addedBillDetails.addedBillData?.data?.records?.Firm?.HasGST && <div className="fs-6 text-muted mb-1 fw-bold px-2">
                      GSTIN:{" "}
                      <span className="text-dark">
                        {addedBillDetails.addedBillData?.data?.records?.Firm
                          ?.GSTIN || "-"}
                      </span>
                    </div>}
                    <div className="fs-6 text-muted mb-1 my-6 fw-bold px-2">
                      Invoice No.:{" "}
                      <span className="text-dark">
                        {new Date().getFullYear()}-
                        {+String(new Date().getFullYear()).slice(-2) + 1}
                        {"/" +
                          addedBillDetails.addedBillData?.data?.records
                            ?.BillNumber || "-"}
                      </span>
                    </div>
                    <div className="fs-6 text-muted fw-bold mt-6 px-2">
                      Bill To:{" "}
                      <span className="text-dark">
                        {
                          addedBillDetails.addedBillData?.data?.records
                            ?.ClientName
                        }
                      </span>
                    </div>
                    {addedBillDetails.addedBillData?.data?.records?.Firm?.HasGST && <div className="fs-6 text-muted fw-bold px-2">
                      Client GSTIN:{" "}
                      <span className="text-dark">
                        {
                          addedBillDetails.addedBillData?.data?.records
                            ?.Client_GSTIN || "-"
                        }
                      </span>
                    </div>}
                    <div className="fs-6 text-muted fw-bold px-2 mb-4">
                      Address:{" "}
                      <span className="text-dark">
                        {addedBillDetails.addedBillData?.data?.records?.Address
                          ? `${addedBillDetails.addedBillData?.data?.records?.Address}, ${addedBillDetails.addedBillData?.data?.records?.State}`
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="w-25 text-end pe-4 mt-20 fw-bold">
                    <div className="fs-6 text-muted">
                      Date:{" "}
                      <span className="text-dark">
                        {convert(
                          addedBillDetails.addedBillData?.data?.records?.Date
                        ) || "dd/mm/yyyy"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="border-top border-3">
                  <div className="d-flex border-bottom border-2">
                    <div className="w-75 p-3 border-end border-2 fw-bold fs-5">
                      Particulars
                    </div>
                    <div className="w-25 p-3 text-end fw-bold fs-5">Amount</div>
                  </div>
                  {addedBillDetails.addedBillData?.data?.records?.Tasks?.map(
                    (task: any, index: any) => (
                      <div className="d-flex" key={index}>
                        <div className="w-75 p-2">{task.TypeName}</div>
                        <div className="w-25 p-2 text-end">{task.Fees}</div>
                      </div>
                    )
                  )}

                  {addedBillDetails.addedBillData?.data?.records?.Firm
                    ?.HasGST && (
                    <>
                      {" "}
                      <div className="d-flex border-bottom border-top border-2 fw-bold">
                        <div className="w-75 text-end p-3 pe-4 border-end border-2">
                          Subtotal:
                        </div>
                        <div className="w-25 text-end p-3">
                          {addedBillDetails.addedBillData?.data?.records
                            ?.TotalAmountWithoutTax || 0}
                        </div>
                      </div>
                      {(addedBillDetails.addedBillData?.data?.records?.State ===
                        "Gujarat" ||
                        addedBillDetails.addedBillData?.data?.records?.State ==
                          "") && (
                        <>
                          <div className="d-flex fw-bold">
                            <div className="w-75 text-end p-3 pe-4">
                              Add: SGST @{" "}
                              {addedBillDetails.addedBillData?.data?.records
                                ?.TaxRate / 2}
                              %
                            </div>
                            <div className="w-25 text-end p-3">
                              {addedBillDetails.addedBillData?.data?.records
                                ?.TotalTaxAmount / 2 || 0}
                            </div>
                          </div>
                          <div className="d-flex fw-bold">
                            <div className="w-75 text-end pb-3 pe-4">
                              CGST @{" "}
                              {addedBillDetails.addedBillData?.data?.records
                                ?.TaxRate / 2}
                              %
                            </div>
                            <div className="w-25 text-end px-3">
                              {addedBillDetails.addedBillData?.data?.records
                                ?.TotalTaxAmount / 2 || 0}
                            </div>
                          </div>
                        </>
                      )}
                      {addedBillDetails.addedBillData?.data?.records?.State !==
                        "Gujarat" &&
                        addedBillDetails.addedBillData?.data?.records?.State !==
                          "" && (
                          <div className="d-flex fw-bold">
                            <div className="w-75 text-end pb-3 pe-4">
                              IGST @{" "}
                              {
                                addedBillDetails.addedBillData?.data?.records
                                  ?.TaxRate
                              }
                              %
                            </div>
                            <div className="w-25 text-end px-3">
                              {addedBillDetails.addedBillData?.data?.records
                                ?.TotalTaxAmount || 0}
                            </div>
                          </div>
                        )}
                    </>
                  )}
                  <div className="d-flex border-top border-3 fw-bold">
                    <div className="w-75 text-end p-3 pe-4 border-end border-2">
                      Total Amount:
                    </div>
                    <div className="w-25 text-end p-3">
                      {addedBillDetails.addedBillData?.data?.records
                        ?.TotalAmount || 0}
                    </div>
                  </div>
                </div>

                <div className="border-top border-3">
                  <div className="ps-4 py-6 fs-5 fw-bold">Bank Details:</div>
                  <div className="d-flex justify-content-between ps-4">
                    <div>
                      <div className="fs-6 fw-semibold mb-1">
                        {
                          addedBillDetails.addedBillData?.data?.records?.Firm
                            ?.Name
                        }
                      </div>
                      <div className="fs-6 mb-1">
                        Bank:{" "}
                        {addedBillDetails.addedBillData?.data?.records?.Firm
                          ?.BankName || "-"}
                      </div>
                      <div className="fs-6 mb-1">
                        A/c No.:{" "}
                        {addedBillDetails.addedBillData?.data?.records?.Firm
                          ?.AccountNumber || "-"}
                      </div>
                      <div className="fs-6">
                        IFSC:{" "}
                        {addedBillDetails.addedBillData?.data?.records?.Firm
                          ?.IFSCCode || "-"}
                      </div>
                    </div>
                    <ImageDisplay
                      altText="qrCode"
                      errorPath="/media/task/qrCode.png"
                      className="w-100px"
                      path={`data:image/jpeg;base64,${addedBillDetails.addedBillData?.data?.records?.base64Qr}`}
                      title="qrCode"
                    />
                  </div>
                  <div className="ps-4 fs-7 py-2">
                    Please transfer the amount or send a cheque.
                  </div>
                </div>

                <div className="text-end pt-4">
                  <div className="fw-bold fs-6">
                    For{" "}
                    {addedBillDetails.addedBillData?.data?.records?.Firm?.Name}
                  </div>
                  {addedBillDetails.addedBillData?.data?.records?.Firm?.IsCAFirm && <div className="fs-6">Chartered Accountants</div>}
                  <ImageDisplay
                    className="w-125px"
                    altText="signature"
                    errorPath="/media/task/adminSign.png"
                    path={`data:image/jpeg;base64,${addedBillDetails.addedBillData?.data?.records?.base64Sign}`}
                    title="signature"
                  />
                  <div className="fw-bold fs-6">
                    (
                    {
                      addedBillDetails.addedBillData?.data?.records?.Firm
                        ?.PersonName
                    }
                    )
                  </div>
                  <div className="fw-bold fs-6">
                    {
                      addedBillDetails.addedBillData?.data?.records?.Firm
                        ?.Designation
                    }
                  </div>
                </div>
              </Modal.Body>
            </div>
            <GetUrlForPdf
              billData={addedBillDetails.addedBillData?.data?.records}
            />
            <Formik
              enableReinitialize
              initialValues={{ contact: [] }}
              // validationSchema={whatsappSendValidationSchema}
              onSubmit={async (values, { resetForm }) => {
                //whatsapp send api call
                // const updatedValues = {name : "sent_bill", components : contactPersons.map((con : any) => ({
                //   bodyParameters : [],
                //   contactNumber : con.MobileNumber,
                //   headerParameter:{type: "DOCUMENT", value: "generated bill url"}
                // }))}
                // add upadted values into api call
              }}
            >
              {({ isSubmitting }) => {
                return (
                  <Form
                    noValidate
                    className="form d-flex align-items-end justify-content-between p-6 pt-4"
                  >
                    <div className="d-flex justify-content-end gap-3 w-100">
                      <ResetButton
                        name="Cancel"
                        className="btn btn-light btn-active-light-primary"
                        onClickCallback={() => closeModal()}
                      />
                      <SubmitButton
                        isSubmitting={isSubmitting}
                        disabled={
                          !savedBillPdfDetails?.billPdf?.data?.records?.FileUrl
                        }
                        className="btn btn-primary"
                        name="Send"
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        ) : (
          <Modal.Body className="pb-4 pe-4">
            <div className="fs-6 fw-semibold">
              Bill not generated because all task are marked FOC
            </div>
            <div className="text-end">
              <ResetButton
                name="Cancel"
                className="btn btn-sm btn-light btn-active-light-primary"
                onClickCallback={() => closeModal()}
              />
            </div>
          </Modal.Body>
        ))}
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    addedBillDetails: state.addUpdateBill,
    savedBillPdfDetails: state.saveBillPdf,
  };
};

export default connect(mapStateToProps)(BillFormat);
