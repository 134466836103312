export const ChatShimmer = () => {
  return (
    <div className="shimmer-chat">
      {/* <div className="circle" /> */}
      <div className="shimmer-message one shimmer" />
      {/* <div className="shimmer-message two shimmer" /> */}
    </div>
      
  );
};
