import { connect } from "react-redux";
import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Avatar from "../../../commonComponent/Avatar";
import { absolutePath } from "../../../../helpers/relativePath";
import { TASK_DETAIL_PAGE, EDIT_TASK } from "../../../../helpers/routes";
import {
  convert,
  getColor,
  getStatus,
} from "../../../../helpers/helperFunction";
import { KTSVG } from "../../../../../_metronic/helpers";
import { PAYMENT_STATUS } from "app/helpers/constants";
import { selectedState } from "../../../../reducers/task/taskSelectionReducer";
import { unSelectTask, selectTask } from "../../../../reducers/task/taskAction";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { taskData } from "app/reducers/task/taskDataInterface";
import PinSVG from "./PinSVG";
import hasPaymentAccess from "../../auth/core/paymentAccess";
import hasPermission, { actionsRole } from "../../auth/core/permission";

interface props {
  row: taskData;
  selectTaskDispatch: Function;
  unSelectTaskDispatch: Function;
  selectedTaskDetails: selectedState;
}

const formatDuration = (duration: string) => {
  const match = duration.match(/(\d{4}(-\d{2})?)$/); 
  if (match) {
    const yearPart = match[0]; 
    const textPart = duration.replace(yearPart, ""); 
    
    return (
      <>
        {textPart}
        <span style={{ fontSize: "0.75em", marginLeft: "4px" }}>{yearPart}</span>
      </>
    );
  }
  return duration;
};

const TaskTableRow: FC<props> = ({
  row,
  selectTaskDispatch,
  unSelectTaskDispatch,
  selectedTaskDetails,
}) => {
  const selected = useMemo(
    () => selectedTaskDetails.selected.includes(row.Id),
    [selectedTaskDetails.selected, row.Id]
  );
  const navigate = useNavigate();

  const editTaskHadler = () => {
    navigate(absolutePath(`${EDIT_TASK}/${row.Id}`));
  };

  const taskDetailHandler = () => {
    navigate(absolutePath(`${TASK_DETAIL_PAGE}/${row.Id}`));
  };

  const checkBoxHandler = (e: any) => {
    if (e.target.checked) {
      selectTaskDispatch(row.Id);
    } else {
      unSelectTaskDispatch(row.Id);
    }
  };

  return (
    <tr>
      <td className="pe-2 ps-2">
        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input"
            type="checkbox"
            checked={selected}
            onChange={(e: any) => checkBoxHandler(e)}
          />
        </div>
      </td>

      <td className="text-dark fw-bold ps-2">
        <div className="text-gray-800 fw-bold ps-1">{row.TaskCode}</div>
        {/* {row.IsOverdue && (
          <div className="d-flex align-items-center text-gray-500 fs-7">
            <ImageDisplay
              path="/media/task/overdue_flag.png"
              errorPath="/media/task/overdue_flag.png"
              altText="flag"
              className="w-15px"
            />
            <div className="text-danger w-100px">Overdue</div>
          </div>
        )} */}
      </td>

      <td className="text-dark fw-bold">{row.ClientName}</td>
      <td>
        {row.TypeName}{" "}
        {row.IsUrgent && (
          <div className="d-flex align-items-center text-primary-500 fs-7">
            <PinSVG fill="#009EF7" />
            <div className="text-primary w-100px">Urgent</div>
          </div>
        )}
      </td>
      {/* <td>{getDuration(row.StartDate, row.DueDate, row.RepeatationPeriod)}</td> */}
      <td>{row.Duration ? formatDuration(row.Duration) : "-"}</td>
      <td>{row.StartDate ? convert(row.StartDate) : "-"}</td>
      <td>
        {!row.IsRequestedDueDate ? (
          <>
            {row.DueDate ? convert(row.DueDate) : "-"}{" "}
            {row.IsOverdue && (
              <div className="d-flex align-items-center text-gray-500 fs-7">
                {/* <ImageDisplay
              path="/media/task/overdue_flag.png"
              errorPath="/media/task/overdue_flag.png"
              altText="flag"
              className="w-15px"
            /> */}
                <PinSVG fill="#F1416C" />
                <div className="text-danger w-100px">OverDue</div>
              </div>
            )}
          </>
        ) : (
          <>
            {row.RequestedDueDate ? convert(row.RequestedDueDate) : ""}
            {
              <div className="d-flex align-items-center text-gray-500 fs-7">
                <PinSVG fill="#009EF7" />
                <div className="text-primary w-100px">Requested</div>
              </div>
            }
          </>
        )}
      </td>
      <td>
        {row.PersonAssigned ? (
          <div className="d-flex">
            <div className="min-w-150px align-items-center bg-light border d-flex fs-7 fw-semibold py-1 px-2 rounded-pill text-gray-600 min-w-125px">
              <div className="w-20px me-3">
                <Avatar
                  firstName={row.PersonAssigned}
                  lastName={""}
                  size={20}
                  color={getColor(row.PersonAssigned).color}
                  background={getColor(row.PersonAssigned).background}
                  length={1}
                  rounded={true}
                />
              </div>
              {row.PersonAssigned}
            </div>
          </div>
        ) : (
          "-"
        )}
      </td>
      <td>
        {row.ManagerName ? (
          <div className="d-flex">
            <div className="min-w-150px d-flex align-items-center bg-light border fs-7 fw-semibold py-1 ps-2 pe-4 rounded-pill text-gray-600">
              <div className="w-20px me-3">
                <Avatar
                  firstName={row.ManagerName}
                  lastName={""}
                  size={20}
                  color={getColor(row.ManagerName).color}
                  background={getColor(row.ManagerName).background}
                  length={1}
                  rounded={true}
                />
              </div>
              <span className="w-auto">{row.ManagerName}</span>
            </div>
          </div>
        ) : (
          "-"
        )}
      </td>
      <td>{row.IsRepeatative ? "Repetitive" : "One Time Task"}</td>
      {hasPaymentAccess() && (
        <td>
          <span className={`badge bill-status-${row.BillingStatus}`}>
            {PAYMENT_STATUS[row.BillingStatus]}
          </span>
        </td>
      )}
      <td>
        <span className={`badge status-${row.StatusValue}`}>
          {getStatus(row.StatusValue).name}
        </span>
      </td>
      <td>
        <button
          className={`btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
          style={{ marginRight: "20px" }}
          title="Edit"
          onClick={editTaskHadler}
        >
          <KTSVG
            path="/media/icons/duotune/art/art005.svg"
            className="svg-icon-3"
          />
        </button>
        {/* </td>
      <td className="pe-5"> */}
        <button
          className={`btn btn-icon btn-bg-light btn-light btn-sm btn-active-light-primary`}
          title="Task Detail"
          onClick={taskDetailHandler}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr064.svg"
            className="svg-icon-2 svg-icon-primary"
          />
        </button>
      </td>
    </tr>
  );
};

const mapStateToProps = (state: any) => {
  return {
    selectedTaskDetails: state.selectedTask,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    unSelectTaskDispatch: (taskId: number) => dispatch(unSelectTask(taskId)),
    selectTaskDispatch: (taskId: number) => dispatch(selectTask(taskId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskTableRow);
