import { KTSVG } from "_metronic/helpers";
import {
  PAYMENT_STATUS,
  REIMBURSEMENT_PAYMENT_STATUS,
} from "app/helpers/constants";
import { convert } from "app/helpers/helperFunction";
import { REIMBURSEMENT_LIST } from "app/helpers/routes";
import { FC, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GeneratePdfByClick } from "../../billing/component/GetUrlForPdf";
import AddTransactionShrt, {
  RECEIPT_TYPE_OPTIONS,
} from "../../transaction/component/AddTransactionShrt";
import { connect } from "react-redux";
import { getInitialValue } from "../ReimbursementInvoiceForm";
import AdvancePaymentPopup from "../../billing/component/AdvancePaymentPopup";
import { getClientAdvancePayments } from "app/reducers/client/clientAction";
import { mapBillValue } from "../../billing/BillingForm";
import { mapAdvancePayments } from "app/reducers/billing/billingAction";

interface props {
  row: any;
  setShowBillEditForm: React.Dispatch<
    SetStateAction<{ show: boolean; billId: number | undefined }>
  >;
  reimbursementInvoiceData: any;
  reimbursementInvoiceLoading: boolean;
  getClientAdvancePaymentsDispatch: Function
  clientAdvancePayments: any,
  mapAdvancePaymentsDispatch: Function
}

const ReimbursementInvoiceTableRow: FC<props> = ({
  row,
  setShowBillEditForm,
  reimbursementInvoiceData,
  reimbursementInvoiceLoading,
  getClientAdvancePaymentsDispatch,
  clientAdvancePayments,
  mapAdvancePaymentsDispatch
}) => {
  const initialValues = {
    bookId: undefined,
    amount: "",
    narration: "",
    type: RECEIPT_TYPE_OPTIONS[1],
    taskId: undefined,
    billId: row.Id,
    expenseId: undefined,
    isPayment: "false",
    clientId: row.ClientId,
    firmId: row.Firm?.Id,
    isCashBook: "true",
    dueAmount: row.TotalAmount - row.PaidAmount,
    billNumber: row.BillNumber,
    date: row.Date,
  };

  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [showAdvancePaymentPopup, setShowAdvancePaymentPopup] = useState({
    show: false,
    clientId: 0,
    invoiceId: 0,
    dueAmount: row.TotalAmount - row.PaidAmount
  });
  const closeModal = () => {
    setShowTransactionModal(false);
  };
  const handleOpenPopup = async (clientId: number, invoiceId: number, dueAmount: number) => {
    setShowAdvancePaymentPopup({ show: true, clientId, invoiceId, dueAmount});
    await getClientAdvancePaymentsDispatch(clientId);
  };

  const navigate = useNavigate();

  const navigateToDetailPage = () => {
    navigate(`${REIMBURSEMENT_LIST}/${row.Id}`);
  };

  return (
    <tr>
      <td className="text-dark fw-bold ps-2">{row.BillNumber}</td>
      <td className="">{row.ClientName}</td>
      <td className="">
        {Array.from(
          new Set(row.Payments.map((p: { TaskCode: string }) => p.TaskCode))
        ).join(",") || "-"}
      </td>
      <td className="">{row?.Firm?.Name || "-"}</td>
      <td className="">{row.TotalAmount}</td>
      <td className="">{row.TotalAmount - row.PaidAmount}</td>
      <td>{row?.Date ? convert(row.Date) : "-"}</td>
      <td>
        <div className={`badge badge-sm bill-status-${row.PaymentStatus}`}>
          {PAYMENT_STATUS[row.PaymentStatus]}
        </div>
      </td>
      <td>
        <div className="d-flex gap-3 justify-content-end pe-5">
          <button
            className={`p-2 btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
            title="Map to Advance Payment"
            onClick={() => handleOpenPopup(row.ClientId, row.Id, (row.TotalAmount - row.PaidAmount))}
            disabled={clientAdvancePayments?.data?.records?.length < 0}
          >
            <KTSVG
              path="/media/icons/duotune/coding/cod007.svg"
              className="svg-icon-3"
            />
          </button>
          {/* Popup Component */}
          <AdvancePaymentPopup
            showAdvancePaymentPopup={showAdvancePaymentPopup}
            setShowAdvancePaymentPopup={setShowAdvancePaymentPopup}
            clientAdvancePayments={clientAdvancePayments?.data?.records}
            updateFun={mapAdvancePaymentsDispatch}
            isReimbursement={true}
          />
          <button
            className={`p-2 btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
            title="Edit"
            //onClick={() => setShowBillEditForm({ show: true, billId: row.Id })}
            onClick={() => setShowTransactionModal(true)}
          >
            <KTSVG
              path="/media/icons/duotune/finance/fin008.svg"
              className="svg-icon-3"
            />
          </button>
          <AddTransactionShrt
            showTransaction={showTransactionModal}
            closeModal={closeModal}
            initialValuess={initialValues}
          />
          <GeneratePdfByClick
            billData={row}
            title="Reimbursement of Expenses"
            className="btn btn-icon btn-bg-light btn-sm btn-active-light-primary"
            showIcon={true}
          />
        </div>
      </td>
      {/* <td className="pe-5">
        <button
          className={`btn btn-icon btn-bg-light btn-light btn-sm btn-active-light-primary`}
          title="Invoice Detail"
          onClick={navigateToDetailPage}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr064.svg"
            className="svg-icon-2 svg-icon-primary"
          />
        </button>
      </td> */}
    </tr>
  );
};

const mapStateToProps = (state: any) => {
  return {
    reimbursementInvoiceLoading: state.reimbursementInvoice.loading,
    reimbursementInvoiceData: state.reimbursementInvoice.reimbursementInvoice,
    clientAdvancePayments: state.clientAdvancePayments.advancePayments,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getClientAdvancePaymentsDispatch: (id: number) =>
      dispatch(getClientAdvancePayments(id)),
    mapAdvancePaymentsDispatch: (
          data: mapBillValue,
          successCallback: Function
        ) => dispatch(mapAdvancePayments(data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReimbursementInvoiceTableRow);
