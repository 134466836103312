import React from "react";
import DOMPurify from "dompurify";
import clsx from "clsx";

interface MessageProps {
  Message: string;
}

interface props {
  message: MessageProps;
  state: string;
  messageType: "in" | "out";
  isMessageToAll: boolean;
}

const Message: React.FC<props> = ({
  message,
  state,
  messageType,
  isMessageToAll,
}) => {
  // Regular expression to match mentions of the form "@Firstname Lastname"
  const mentionRegex = isMessageToAll
    ? /(@[A-Za-z]+\s)/
    : /(@[A-Za-z0-9]+\s[A-Za-z0-9]+)/;

  // check for a mention in the message
  let formattedMessage: string = message.Message;

  if (mentionRegex.test(formattedMessage)) {
    // Replace mentions with a styled span
    formattedMessage = formattedMessage.replace(
      mentionRegex,
      (mention) =>
        `<span className="text-primary text-primary-500 w-100px" style="color: #009EF7; font-weight: bold;">${mention}</span>`
    );
  }

  // Replace new lines with <br />
  formattedMessage = formattedMessage.replace(/\n/g, "<br />");

  return (
    <div
      className={clsx(
        "p-5 rounded",
        `bg-light-${state}`, // Dynamic background
        "text-dark fw-bold mw-lg-400px",
        `text-${messageType === "in" ? "start" : "end"}` // Conditional alignment
      )}
      data-kt-element="message-text"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(formattedMessage), // Sanitize the formatted HTML
      }}
    ></div>
  );
};

export default Message;
