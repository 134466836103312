import { FC } from "react";
import {
  Field,
  FieldArray,
  FormikHelpers,
  FormikState,
  FormikValues,
  useFormikContext,
} from "formik";

import { addTaskActivityObject, categoryDetails, taskTypes } from "./AddTask";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";

interface props {
  categoryDetails: Array<categoryDetails>;
  loader: boolean;
}
export interface key {
  id?: number;
  categoryId: number;
  taskId?: number;
  categoryName: string;
}

interface remarksObject {
  id: number;
  remark: string;
  FileName: string;
  FilePath: string;
  FileUrl: string;
  MimeType: string;
  filePath: string;
}

export interface subKeyActivityObject {
  activityId: number;
  activityName: string;
  isMandatory: boolean;
  requireCompletionField: boolean;
  requireChecking: boolean;
}
export interface subKey {
  typeId: number;
  typeName: string;
  requireConfirmation: boolean;
  assigneeId: string;
  assigneeName: string;
  managerId: string;
  managerName: string;
  expertId: string;
  expertName: string;
  startDate: Date | string;
  fees: number;
  idealDueDate: Date | string;
  activities: Array<subKeyActivityObject>;
  remarks: Array<remarksObject>;
  isManualDueDate: boolean;
  repeatation: string | undefined;
}

export interface workCategoryIdDataObject {
  subKey: Array<subKey>;
  key: key;
}

const CustomDropdown: FC<props> = ({ categoryDetails, loader }) => {
  const {
    values,
    setFieldValue,
    isSubmitting,
  }: FormikState<FormikValues> & FormikHelpers<FormikValues> =
    useFormikContext();

  const checkedForIcon = (categoryId: number) => {
    const categoryData: any = categoryDetails.find(
      (category: any) => category.Id === categoryId
    )?.TaskTypes?.length;
    const workCategoryData: any = values.workCategoryId.find(
      (workCategory: workCategoryIdDataObject) =>
        workCategory?.key?.categoryId === categoryId
    )?.subKey?.length;

    return categoryData === workCategoryData;
  };

  const isChecked = (categoryId: number, typeId: number) => {
    let checked;
    const data = values.workCategoryId.find(
      (workCategory: workCategoryIdDataObject) =>
        workCategory.key.categoryId === categoryId
    );
    checked = data?.subKey?.some(
      (subkeyData: subKey) => subkeyData.typeId === typeId
    );
    return checked;
  };

  const getIndex = (categoryId: number) => {
    if (!values.workCategoryId?.length) {
      return -1;
    }
    const index = values.workCategoryId.findIndex(
      (workCategory: workCategoryIdDataObject) =>
        workCategory?.key.categoryId === categoryId
    );
    return index;
  };

  const getNumberOfSelectedCategory = () => {
    return values.workCategoryId.reduce(
      (acc: number, data: workCategoryIdDataObject) =>
        (acc = acc + data.subKey?.length),
      0
    );
  };

  const getTaskTypeData = (type: taskTypes) => {
    return {
      typeId: type.Id,
      typeName: type.Name,
      requireConfirmation: type.RequireConfirmation,
      assigneeId: type.DefaultAssigneeId,
      assigneeName: type.DefaultAssigneeName,
      managerId: type.DefaultManagerId,
      managerName: type.DefaultManagerName,
      expertId: type.DefaultExpertId,
      expertName: type.DefaultExpertName,
      // assigneeId: "",
      // assigneeName: "",
      // managerId: "",
      // managerName: "",
      // expertId: "",
      // expertName: "",
      isRepeatative: type.IsRepeatative,
      repeatation: type.Repeatation,
      idealDays: type.IdealDaysToComplete,
      startDate: "",
      fees: type.StandardFees,
      fixedDate: type.GovernmentFixDate,
      onboardBefore: type.OnboardBefore,
      activities: type.Activities.map((activity: addTaskActivityObject) => {
        return {
          activityId: activity.Id,
          activityName: activity.Name,
          isMandatory: activity.IsMandatory,
          requireCompletionField: activity.RequireCompletionField,
          requireChecking: activity.RequireChecking,
        };
      }),
      remarks: [],
    };
  };

  return (
    <div className="btn-group w-100">
      <button
        className={`text-start form-control form-control-lg form-control-solid form-select ${
          loader ? "display-dropdown-loader" : ""
        }`}
        type="button"
        id="dropdownMenuClickableInside"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
      >
        {getNumberOfSelectedCategory()
          ? `${getNumberOfSelectedCategory()} Task Selected`
          : "Select Category"}
        {loader && (
          <div className="fs-7">
            <SmallLoader />
          </div>
        )}
      </button>
      <FieldArray
        name="workCategoryId"
        render={(arrayHelpers) => {
          return (
            <>
              <ul
                className="dropdown-menu w-100 horizontal-scroll overflow-auto mh-350px vertical-scroll"
                aria-labelledby="dropdownMenuClickableInside"
              >
                {categoryDetails?.map(
                  (category: categoryDetails, index: number) => {
                    if (category?.TaskTypes?.length) {
                      return (
                        <li
                          className="p-2 dropdown-item d-flex gap-2"
                          key={index}
                        >
                          <div className="form-check form-check-custom form-check-solid mx-2 checkox-parent">
                            <Field
                              type="checkbox"
                              className={`form-check-input w-20px h-20px checkbox-input`}
                              name={`workCategoryId[${index}].key.categoryId`}
                              id={`workCategoryId[${index}].key.categoryId`}
                              checked={
                                getIndex(category.Id) === -1 ? false : true
                              }
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  arrayHelpers.push({
                                    key: {
                                      categoryId: category.Id,
                                      categoryName: category.Name,
                                    },
                                    subKey: category.TaskTypes.map(
                                      (type: taskTypes) => {
                                        return {
                                          ...getTaskTypeData(type),
                                        };
                                      }
                                    ),
                                  });
                                }
                                if (!e.target.checked) {
                                  let categoryIndex = getIndex(category.Id);
                                  arrayHelpers.remove(categoryIndex);
                                }
                              }}
                              disabled={isSubmitting}
                            />
                            {/* <label htmlFor="myCheckbox">Checkbox</label> */}
                            <label
                              htmlFor={`workCategoryId[${index}].key.categoryId`}
                            >
                              <span
                                className={`${
                                  checkedForIcon(category.Id) ? "full-" : ""
                                }checkbox`}
                              ></span>
                            </label>
                          </div>
                          <span
                            className="dropdown-toggle cursor-pointer"
                            id="dropdownMenuClickableInside1"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                          >
                            {category.Name}
                          </span>
                          <FieldArray
                            name={`workCategoryId[${index}].subKey`}
                            render={(arrayHelpers) => {
                              return (
                                <ul
                                  className="dropdown-menu py-3 w-75 horizontal-scroll overflow-auto mh-250px vertical-scroll"
                                  aria-labelledby="dropdownMenuClickableInside1"
                                >
                                  {category.TaskTypes.map(
                                    (type: taskTypes, index2: number) => {
                                      return (
                                        <li
                                          className="p-2 dropdown-item d-flex gap-2"
                                          key={index2}
                                        >
                                          <div className="form-check form-check-custom form-check-solid mx-2">
                                            <Field
                                              type="checkbox"
                                              className="form-check-input w-20px h-20px checkbox-input"
                                              name={`workCategoryId[${index}].subKey[${index2}].typeId`}
                                              id={`workCategoryId[${index}].subKey[${index2}].typeId`}
                                              checked={isChecked(
                                                category.Id,
                                                type.Id
                                              )}
                                              onChange={(e: any) => {
                                                if (e.target.checked) {
                                                  let typeIndex = getIndex(
                                                    category.Id
                                                  );

                                                  if (typeIndex != -1) {
                                                    setFieldValue(
                                                      `workCategoryId[${typeIndex}]`,
                                                      {
                                                        key: values
                                                          .workCategoryId[
                                                          typeIndex
                                                        ].key,
                                                        subKey: [
                                                          ...values
                                                            .workCategoryId[
                                                            typeIndex
                                                          ].subKey,
                                                          {
                                                            ...getTaskTypeData(
                                                              type
                                                            ),
                                                          },
                                                        ],
                                                      }
                                                    );
                                                  } else {
                                                    const length =
                                                      values.workCategoryId
                                                        ?.length === 0
                                                        ? 0
                                                        : +values.workCategoryId
                                                            ?.length;
                                                    setFieldValue(
                                                      `workCategoryId[${length}]`,
                                                      {
                                                        key: {
                                                          categoryId:
                                                            category.Id,
                                                          categoryName:
                                                            category.Name,
                                                        },
                                                        subKey: [
                                                          {
                                                            ...getTaskTypeData(
                                                              type
                                                            ),
                                                          },
                                                        ],
                                                      }
                                                    );
                                                  }
                                                }
                                                if (!e.target.checked) {
                                                  let typeIndex = getIndex(
                                                    category.Id
                                                  );
                                                  if (
                                                    values.workCategoryId[
                                                      typeIndex
                                                    ].subKey?.length -
                                                      1 !=
                                                    0
                                                  ) {
                                                    setFieldValue(
                                                      `workCategoryId[${typeIndex}]`,
                                                      {
                                                        key: values
                                                          .workCategoryId[
                                                          typeIndex
                                                        ].key,
                                                        subKey:
                                                          values.workCategoryId[
                                                            typeIndex
                                                          ].subKey.filter(
                                                            (subType: subKey) =>
                                                              subType.typeId !=
                                                              type.Id
                                                          ),
                                                      }
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      "workCategoryId",
                                                      values.workCategoryId.filter(
                                                        (
                                                          workCategory: workCategoryIdDataObject
                                                        ) =>
                                                          workCategory.key
                                                            .categoryId !=
                                                          category.Id
                                                      )
                                                    );
                                                  }
                                                }
                                              }}
                                              disabled={isSubmitting}
                                            />
                                            <label
                                              htmlFor={`workCategoryId[${index}].subKey[${index2}].typeId`}
                                            >
                                              <span className="full-checkbox"></span>
                                            </label>
                                          </div>
                                          <span>{type.Name}</span>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              );
                            }}
                          />
                        </li>
                      );
                    }
                    return <></>;
                  }
                )}
              </ul>
            </>
          );
        }}
      />
    </div>
  );
};

export default CustomDropdown;
