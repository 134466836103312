import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import Avatar from "../../../../app/components/commonComponent/Avatar";
import { getProfileData } from "../../../../app/reducers/profile/profileAction";
import { HeaderUserMenu, ThemeModeSwitcher } from "../../../partials";
import { getColor } from "../../../../app/helpers/helperFunction";
import { Chat } from "../chat";
import { getChatList } from "app/reducers/chat/chatAction";
import TaskChat from "app/components/pages/main/admin/taskDetail/chat/TaskChat";
import { KTSVG } from "_metronic/helpers";

interface props {
  profileDetails: any;
  getProfile: Function;
}

const itemClass = "ms-1 ms-lg-3";
const btnClass =
  "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px";
const userAvatarClass = "symbol-35px symbol-md-40px";
const btnIconClass = "svg-icon-1";

const NavbarCom: FC<props> = ({ profileDetails, getProfile }) => {
  const [openChat, setOpenChat] = useState(false);

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className="app-navbar flex-shrink-0">
      <div className={clsx("app-navbar-item", itemClass)}>
        <div>
          <Chat />
          {/* <div
            id=""
            className="btn btn-icon btn-active-light-primary btn-custom chat"
            data-kt-scrolltop=""
            onClick={() => {
              setOpenChat(!openChat);
              //setChatId(Number(params.id));
            }}
          >
            <KTSVG
              path="/media/icons/duotune/communication/com007.svg"
              className="theme-light-hide svg-icon-2"
            />
          </div>
          {openChat && (
            <TaskChat
              setOpenChat={setOpenChat}
              isDrawer={true}
              openChat={openChat}
            />
          )}*/}
        </div> 
        <ThemeModeSwitcher
          toggleBtnClass={clsx("btn-active-light-primary btn-custom")}
        />
      </div>
      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <Avatar
            firstName={profileDetails.profileData.FirstName}
            lastName={profileDetails.profileData.LastName}
            size={100}
            color={getColor(profileDetails?.profileData?.LastName).color}
            background={
              getColor(profileDetails?.profileData?.LastName).background
            }
          />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profileDetails: state.profile,
    masterDataList: state.masterData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getProfile: () => dispatch(getProfileData()),
  };
};

const Navbar = connect(mapStateToProps, mapDispatchToProps)(NavbarCom);
export { Navbar };
