import { connect } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC, useEffect } from "react";
import { object, string, number, date } from "yup";
import { useNavigate } from "react-router-dom";

import { addUserData } from "../../../helpers/commonInterface";
import { addUserAction } from "../../../reducers/user/userAction";
import { adminHomePage } from "../main/admin/AdminMain";
import { absolutePath } from "../../../helpers/relativePath";
import BankDetailsForm from "./component/BankDetailsForm";
import { emptyObject } from "../../../helpers/helperFunction";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import hasPermission, { actionsRole } from "../auth/core/permission";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { USER_LIST } from "../../../helpers/routes";
import { userListPage } from "./UserList";
import UserDetailsForm from "./component/UserDetailsForm";
import { useSendDataChangeWarning } from "app/helpers/webSocketHelper";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import { editProfile, editUser, getProfileData, getUserByIdData } from "app/reducers/profile/profileAction";
import { useLocation } from 'react-router-dom';
import Loader from "app/components/commonComponent/loader/Loader";
import { values } from "lodash";


interface props {
  profileDetails: any;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
  editUserDispatch:Function;
  getProfile:Function
}


export interface initailValue {
    FirstName: string;
    LastName: string;
    Email: string;
    Roles: string[];
    RegistrationDate: string;
    ReportedId:string;
    ReportingAuthorityId:string;
    JoiningDate: string;
    IFSCCode: string;
    BankName: string;
    AccountNumber: string;
    AccountType: string;
    PhoneNumber: string;
    BranchName: string;
    CompanyName: string;
    PaymentAccess: boolean;
    TransactionAccess: boolean;
}

const getInitialValue = (data: initailValue) => {
  return {
    firstName: data.FirstName,
    lastName: data.LastName,
    email: data.Email,
    //role: data.Roles != null ?  data.Roles[0] : '',
    role: data.Roles != null ? (data.Roles.includes("Admin") ? "Admin" : data.Roles[0]) : '',
    id:'',
    password: "Latest@123",
    registrationDate: data.RegistrationDate,
    joiningDate: data.JoiningDate,
    ifscCode: data.IFSCCode,
    bankName: data.BankName,
    accountNumber: data.AccountNumber,
    accountType: data.AccountType,
    phoneNumber: data.PhoneNumber,
    branchName: data.BranchName,
    companyName: hasPermission(actionsRole.MASTER_ADMIN) ? "" : data.CompanyName,
    reportingAuthorityId:data.ReportedId,
    reportedId : data.ReportingAuthorityId,
    paymentAccess:data.PaymentAccess,
    transactionAccess:data.TransactionAccess,
  };
};

const validateFun = object().shape({
  firstName: string().required("First name is required"),
  lastName: string().required("Last name is required"),
  email: string().email("Wrong email format").required("Email is required"),
  phoneNumber: number()
    .test("length", "Phone number is not valid", (value) => {
      if (value && value.toString().length > 10) return false;
      return true;
    })
    .required("Phone number is required"),
  registrationDate: date().required("Registration date is required"),
  joiningDate: date().required("Joining date is required"),
  ifscCode: string().required("IFSC code is required"),
  bankName: string().required("Bank name is required"),
  branchName: string().required("Branch name is required"),
  accountType: string().required("Account type is required"),
  accountNumber: number().required("Account number is required"),
  password: string()
    .required("Password is required")
    .matches(
      /(?=.*[A-Z])(?=.*\W+)(?=.*\d+).+/,
      "Password must contain one uppercase, one number and one special case character"
    ),
  companyName: string()
    .nullable()
    .test("required", "Company name is required", (value) => {
      if (!value && hasPermission(actionsRole.MASTER_ADMIN)) {
        return false;
      }
      return true;
    }),
});

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  userListPage,
  emptyObject,
];

interface LocationState {
    id: string;
}

const EditUser: FC<props> = ({ profileDetails, masterDataList, editUserDispatch, getProfile}) => {
  const navigate = useNavigate();
  const sendDataChangeWarning = useSendDataChangeWarning();

//   useEffect(()=>{
//     getProfile();
//   },[])
 
  // Inside your component
  const location = useLocation();
  const {id} = location.state as LocationState || {};
  
  useEffect(()=>{
      if(id){
        getProfile(id);
      }
  },[id])
  
  return (
    <ErrorBoundary>
        {profileDetails.loading ? (
            <div className="loader-container">
            <Loader />
          </div>
        ):(
            <div className="container-fluid px-0">
            <PageTitle breadcrumbs={accountBreadCrumbs}>Edit User</PageTitle>
            <div className="card">
              <Formik
                enableReinitialize
                initialValues={getInitialValue(
                  profileDetails?.userById
                )}
                validationSchema={validateFun}
                // onSubmit={async (values, { resetForm }) => {
                // //   await addUserDispatch(values, () => {
                // //     resetForm();
                // //     sendDataChangeWarning();
                // //   });
                //  values.password =  '';
                //  console.log(values)

                //  editUserDispatch(values)
                // }}

                onSubmit={async (values) => {
                    values.id = id
                    values.password =  ''
                    values.reportingAuthorityId = values.reportingAuthorityId =='-' ? '':values.reportingAuthorityId
                    await editUserDispatch(values);
                    values.password = "Latest@123"
                  }}
              >
                {({ values, touched, errors, isSubmitting, setFieldValue }) => {
                  return (
                    <Form noValidate className="form">
                      <div className="p-7">
                        {hasPermission(actionsRole.MASTER_ADMIN) && (
                          <div className="row mb-6">    
                            <label className="col-lg-3 col-form-label fw-semibold fs-6 required">
                              Company Name
                            </label>
    
                            <div className="col-lg-8 fv-row">
                              <Field
                                type="text"
                                className={`form-control form-control-lg form-control-solid 
                                ${
                                  touched.companyName &&
                                  errors.companyName &&
                                  "is-invalid inValidBorder"
                                }`}
                                placeholder="Company Name"
                                id="companyName"
                                name="companyName"
                                disabled={isSubmitting}
                              />
                              <ErrorMessage
                                name="companyName"
                                component="div"
                                className="errorMsg"
                              />
                            </div>
                          </div>
                        )}
                        <UserDetailsForm
                          passwordClass="d-none"
                          values={values}
                          touched={touched}
                          isSubmitting={isSubmitting}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          masterDataList={masterDataList}
                        />
                        <BankDetailsForm
                          values={values}
                          touched={touched}
                          isSubmitting={isSubmitting}
                          errors={errors}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div className="d-flex justify-content-end py-6 px-6 gap-3">
                        <ResetButton
                          name="Cancel"
                          className="btn btn-light btn-active-light-primary"
                          onClickCallback={() => navigate(absolutePath(USER_LIST))}
                        />
                        <SubmitButton
                          className="btn btn-primary"
                          isSubmitting={isSubmitting}
                          name="Submit"
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
    
    </ErrorBoundary>
  );
};
const mapStateToProps = (state: any) => {
  return {
    profileDetails: state.userById,
    masterDataList: state.masterData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    editUserDispatch: (
        data: any,
      ) => dispatch(editUser(data)),
      getProfile: (id:any) => dispatch(getUserByIdData(id))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
