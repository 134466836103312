import { addFileObject, searchParams } from "app/helpers/commonInterface";
import hasPaymentAccess from "../auth/core/paymentAccess";
import { Dispatch } from "react";
import { updateTaskStatus } from "app/reducers/task/taskAction";
import { COMPLETION_APPROVED, ON_HOLD } from "app/helpers/constants";
import { LOADER_STATUS } from "./component/TaskGrouping";
import { taskData } from "app/reducers/task/taskDataInterface";
import { taskEditValue } from "./TaskForm";

export const handleDateChange = (
  field: any,
  value: any,
  index: number,
  index2: number,
  setSelectedDates: Function,
  setFieldValue: Function
) => {
  if (!value) {
    setSelectedDates((prev: any) => ({
      ...prev,
      [`${index}-${index2}`]: null,
    }));
    setFieldValue(field, null);
    return;
  }

  if (isNaN(new Date(value).getTime())) {
    return;
  }

  setSelectedDates((prev: any) => ({ ...prev, [`${index}-${index2}`]: value }));
  setFieldValue(field, value);
};

export const onChangeFileHandler = (
  file: any,
  setFieldValue: any,
  name: string,
  index: number,
  index2: number,
  setLoading: Function,
  uploadFileToRemarksDispatch: Function,
  getFormData: Function,
  values: any
) => {
  setLoading({ loading: false, index: index, index2: index2 });
  uploadFileToRemarksDispatch(
    getFormData({ file: file }),
    (fileObj: addFileObject) => {
      setLoading({ loading: false, index: -1, index2: -1 });
      setFieldValue(name, {
        ...values.workCategoryId[index].subKey[index2].remarks[0],
        filePath: fileObj.FilePath,
        fileName: fileObj.FileName,
        fileUrl: fileObj.FileUrl,
        mimeType: fileObj.MimeType,
      });
    }
  );
};

export const deleteSelectedFile = (
  name: string,
  index: number,
  index2: number,
  setFieldValue: Function,
  values: any
) => {
  const remarkData =
    values.workCategoryId[index].subKey[index2].remarks[0]?.remark;
  setFieldValue(name, remarkData ? { remark: remarkData } : { remark: "" });
};

export const getUpdatedDates = (
  fixedDateStr: any,
  repeatation: any,
  taskIndex: number,
  subTaskIndex: number,
  selectedDates: any
) => {
  if (!fixedDateStr) return null;

  let fixedDate = new Date(fixedDateStr);
  if (isNaN(fixedDate.getTime())) {
    return null;
  }

  let prevSelectedDate = selectedDates[`${taskIndex}-${subTaskIndex}`];

  if (prevSelectedDate) {
    let manualDate = new Date(prevSelectedDate);
    if (!isNaN(manualDate.getTime())) {
      return manualDate;
    }
  }

  let today = new Date();
  today.setHours(0, 0, 0, 0);

  let day = fixedDate.getDate();
  let month = fixedDate.getMonth();
  let year = today.getFullYear();

  let updatedDate = new Date(year, month, day);

  if (repeatation === "yearly") {
    if (updatedDate < today) {
      updatedDate.setFullYear(year + 1);
    }
  } else if (repeatation === "monthly") {
    updatedDate.setMonth(today.getMonth());
    if (updatedDate < today) {
      updatedDate.setMonth(updatedDate.getMonth() + 1);
    }
  } else if (repeatation === "half yearly") {
    const firstHalfEnd = 8;
      const secondHalfEnd = 2;
  
      let isPastDate = fixedDate < today;
      if (isPastDate) {
        updatedDate.setMonth(fixedDate.getMonth() + 6);
      } else {
        let isFirstHalf = fixedDate.getMonth() >= 3 && fixedDate.getMonth() <= 8;
        let nextHalfYear = isFirstHalf ? firstHalfEnd : secondHalfEnd;
        updatedDate.setMonth(nextHalfYear);
      }

  } else if (repeatation === "quarterly") {
    const quarterEndMonths = [2, 5, 8, 11];
    let nextQuarter =
      quarterEndMonths.find((m) => new Date(year, m + 1, day) > today) || 3;

    updatedDate.setMonth(nextQuarter);
    if (updatedDate < today) {
      updatedDate.setMonth(updatedDate.getMonth() + 3);
      // if (updatedDate.getMonth() > 11) {
      //     updatedDate.setFullYear(updatedDate.getFullYear() + 1);
      //     updatedDate.setMonth(updatedDate.getMonth() % 12);
      // }
    }
  }

  let lastDayOfMonth = new Date(
    updatedDate.getFullYear(),
    updatedDate.getMonth() + 1,
    0
  ).getDate();
  if (day > lastDayOfMonth) {
    updatedDate.setDate(lastDayOfMonth);
  }

  return updatedDate;
};

export const updateDueDates = (
  taskArr: any,
  values: any,
  setFieldValue: any,
  effectRan: any
) => {
  if (effectRan.current) return;
  effectRan.current = true;

  taskArr.forEach((task: { subKey: any[] }, index: string | number) => {
    task.subKey.forEach((subTask: any, index2: string | number) => {
      const dueDatePath = `workCategoryId[${index}].subKey[${index2}].idealDueDate`;
      const manualEditPath = `workCategoryId[${index}].subKey[${index2}].isManualDueDate`;

      const subTaskValues = values.workCategoryId?.[index]?.subKey?.[index2];
      if (!subTaskValues) return;

      const startDateValue = subTaskValues.startDate;
      const startDate = startDateValue ? new Date(startDateValue) : new Date();
      const idealDays = parseInt(subTaskValues.idealDays || "0", 10);
      let isManual = subTaskValues.isManualDueDate;

      if (isManual === undefined) {
        setFieldValue(manualEditPath, false, false);
        isManual = false;
      }

      if (!isManual && !isNaN(idealDays)) {
        const newDueDate = new Date(startDate);
        newDueDate.setDate(startDate.getDate() + idealDays);

        if (
          !subTaskValues.idealDueDate ||
          new Date(subTaskValues.idealDueDate).getTime() !==
            newDueDate.getTime()
        ) {
          const newDueDate = new Date(startDate);
          newDueDate.setDate(startDate.getDate() + idealDays);

          const utcDate = new Date(
            Date.UTC(
              newDueDate.getFullYear(),
              newDueDate.getMonth(),
              newDueDate.getDate()
            )
          );

          setFieldValue(
            dueDatePath,
            utcDate.toISOString().split("T")[0],
            false
          );
        }
      }
    });
  });
  setTimeout(() => {
    effectRan.current = false;
  }, 100);
};

export const shouldHideGenerateInvoiceButton = (
  selectedTaskIds: string[],
  taskListData: any[]
) => {
  return selectedTaskIds.some((Id) => {
    const task = taskListData.find((t) => t.Id === Id);
    return task && [6, 4, 5, 2, 3].includes(task.BillingStatus);
  });
};

export const shouldShowGenerateReimbursementButton = (
  selectedTaskIds: string[],
  taskListData: any[]
) => {
  return selectedTaskIds.some((Id) => {
    const task = taskListData.find((t) => t.Id === Id);
    return (
      task &&
      task.Payments &&
      task.Payments.some(
        (payment: any) =>
          [5, 3].includes(payment.Type) && !payment.IsAddedToReimbursement
      )
    );
  });
};

export const shouldHideCompletionApproveButton = (
  selectedTaskIds: string[],
  taskListData: any[]
) => {
  return selectedTaskIds.some((Id) => {
    const task = taskListData.find((t) => t.Id === Id);
    return task && task.Status !== 6;
  });
};

export const calculateTotalAmount = (
  selectedTaskIds: number[],
  taskListData: any[]
) => {
  return selectedTaskIds.reduce((sum, Id) => {
    const task = taskListData.find((t) => t.Id === Id);
    return task && task.Fees ? sum + task.Fees : sum;
  }, 0);
};

export const generateTotalReimbursementAmount = (
  selectedTaskIds: number[],
  taskListData: any[]
) => {
  return selectedTaskIds.reduce((sum, Id) => {
    const task = taskListData.find((t) => t.Id === Id);

    if (task && task.Payments) {
      const reimbursementAmount = task.Payments.filter(
        (payment: any) => !payment.IsAddedToReimbursement
      ).reduce((acc: number, payment: any) => acc + payment.Amount, 0);

      return sum + reimbursementAmount;
    }

    return sum;
  }, 0);
};

export const getValueForEdit = (
  taskData: taskData | undefined
): taskEditValue => {
  return {
    id: taskData?.Id || 0,
    clientId: taskData?.ClientId || 0,
    typeName: taskData?.TypeName || "",
    typeId: taskData?.TypeId || 0,
    assigneeId: taskData?.AssigneeId || "",
    creatorId: taskData?.CreatorId || "",
    createrName: taskData?.CreatedBy || "",
    fees: taskData?.Fees || 0,
    managerId: taskData?.ManagerId || "",
    expertId: taskData?.ExpertId || "",
    idealDueDate: taskData?.IdealDueDate || "" || undefined,
    fixedDate: taskData?.GovernmentDueDate || undefined,
    isRepeatative: taskData?.IsRepeatative || false,
    repeatation: taskData?.RepeatationPeriod || "",
    taskCode: taskData?.TaskCode || "",
    isStopped: taskData?.IsStopped || false,
  };
};
