import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { TotalAmountOfInvoice, TotalAmountOfTransaction } from "app/reducers/transaction/transactionDataInterface";
import { FC } from "react";

interface props {
  Total: TotalAmountOfInvoice;
}

const InvoiceAmountDetail: FC<props> = ({ Total }) => {
  return (
    <div className="d-flex flex-wrap fs-5 gap-3 mb-3 w-100">
      <div className="d-flex gap-3 w-100 w-md-50 w-xl-550px">
        <div className="w-100 p-4 rounded-2 totalInAmount">
          <div className="fw-semibold me-3">
            <i className="fa-solid fa-arrow-up totalOut text-success me-3"></i>
            Total Amount
          </div>
          <div className="fw-bolder d-inline-flex align-items-center">
            <ImageDisplay
              altText="rupee"
              className="w-15px"
              errorPath="/media/task/rupee.png"
              path="/media/task/rupee.png"
              title="rupeeSymbol"
            />
            {Total?.TotalAmount != 0 ? Total?.TotalAmount : 0}
          </div>
        </div>
        <div className="w-100 p-4 rounded-2 totalOutAmount">
          <div className="fw-semibold me-3">
            <i className="fa-solid fa-arrow-up totalIn text-danger me-3"></i>
            Total Due Amount
          </div>
          <div className="fw-bolder d-inline-flex align-items-center">
            <ImageDisplay
              altText="rupee"
              className="w-15px"
              errorPath="/media/task/rupee.png"
              path="/media/task/rupee.png"
              title="rupeeSymbol"
            />
            {Total?.TotalDueAmount}
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default InvoiceAmountDetail;
