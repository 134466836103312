import { getAuth } from "app/components/pages/auth";
import { addSocketInstance } from "app/reducers/websocket/websocketAction";
import {
  socketChatState,
  socketDataChangeState,
} from "app/reducers/websocket/websocketReducer";
import store from "app/store/store";
import { useDispatch } from "react-redux";

export const getWebSocketInstance = () => {
  const user = getAuth();
  return new WebSocket(
    `${process.env.REACT_APP_WEBSOCKET_BASE_URL}?companyId=${user?.CompanyId}&access_token=${user?.Token}`
  );
};

export const useSendDataChangeWarning = () => {
  const socket = store.getState().websocketData.socket;
  const dispatch: any = useDispatch();
  const addSocket = (socketInstance: WebSocket) =>
    dispatch(addSocketInstance(socketInstance));
  const user = getAuth();
  return () =>
    sendWebSocketMessage(socket, addSocket, {
      companyId: user?.CompanyId || 0,
      IsMasterDataChange: true,
    });
};

export const useSocketChatMessage = () => {
  const socket = store.getState().websocketData.socket;
  const dispatch: any = useDispatch();
  const addSocket = (socketInstance: WebSocket) =>
    dispatch(addSocketInstance(socketInstance));
  return (sendData: socketChatState) =>
    sendWebSocketMessage(socket, addSocket, sendData);
};

export const sendWebSocketMessage = (
  socket: WebSocket,
  addSocket: Function,
  sendData: socketChatState | socketDataChangeState
) => {
  //console.log(sendData, "Send Data");
  if (socket?.readyState == WebSocket.CLOSED) {
    //console.log("Websocket close need to open");
    const ws = getWebSocketInstance();
    ws.onopen = () => {
      ws.send(JSON.stringify(sendData));
    };
    addSocket(ws);
  } else {
    //console.log("websocket open ---");
    socket?.send(JSON.stringify(sendData));
  }
};
