import { response } from "app/helpers/commonInterface";
import {
  GET_BILL_REPORT_LIST_FAILURE,
  GET_BILL_REPORT_LIST_REQUEST,
  GET_BILL_REPORT_LIST_SUCCESS,
} from "../actionTypes";
import { billData, billdataWithTotal } from "./billingDataInterface";

export interface billReportListState {
  loading: boolean;
  //billDetails: response<billData[]>;
  billDetails: response<billdataWithTotal>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: billReportListState = {
  loading: false,
  billDetails: {},
  error: "",
};

export const getBillReportListReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_BILL_REPORT_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_BILL_REPORT_LIST_SUCCESS:
      return {
        loading: false,
        billDetails: action.payload,
        error: "",
      };
    case GET_BILL_REPORT_LIST_FAILURE:
      return {
        loading: false,
        billDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
