import { searchParams } from "app/helpers/commonInterface";
import { axiosInstance } from "../axiosInstance";
import { getSearchQueryString } from "app/helpers/helperFunction";
import {
  BILL_API_ENDPOINT,
  BILL_REPORT_API_ENDPOINT,
  GET_ALL_ADVANCE_PAYMENT_MAPPINGS_API_ENDPOINT,
  GETALL_BILL_API_ENDPOINT,
  MAP_ADVANCE_PAYMENT_TO_INVOICE,
  SAVE_PDF_OF_GENERATED_BILL_API_ENDPOINT,
} from "app/helpers/config";
import { addBillValue, mapBillValue } from "app/components/pages/billing/BillingForm";

export const addBillService = async (data: addBillValue) => {
  return await axiosInstance.post(BILL_API_ENDPOINT, data);
};

export const getBillListService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(`${GETALL_BILL_API_ENDPOINT}?${queryString}`);
};

export const getBillService = (billId: number) => {
  return axiosInstance.get(`${BILL_API_ENDPOINT}?id=${billId}`);
};

export const updateBillService = async (data: addBillValue) => {
  return await axiosInstance.put(BILL_API_ENDPOINT, data);
};

export const savePdfService = async (pdfData: FormData, billId: number) => {
  return await axiosInstance.post(
    `${SAVE_PDF_OF_GENERATED_BILL_API_ENDPOINT}/${billId}`,
    pdfData
  );
};

export const getBillReportListService = (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(`${BILL_REPORT_API_ENDPOINT}?${queryString}`);
};

export const mapAdvancePaymentService = async (data: mapBillValue) => {
  return await axiosInstance.put(MAP_ADVANCE_PAYMENT_TO_INVOICE, data);
};

export const getAdvancePaymentMappingsService = async (searchObj: searchParams) => {
  const queryString = getSearchQueryString(searchObj);
  return axiosInstance.get(`${GET_ALL_ADVANCE_PAYMENT_MAPPINGS_API_ENDPOINT}?${queryString}`);
};
