import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import CustomDatePicker from "app/components/commonComponent/datePicker/CustomDatePicker";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import FilterButton from "app/components/commonComponent/tableFilter/FilterButton";
import { TRANSACTION_TYPE } from "app/helpers/constants";
import { useGetFilterObject, useOutsideAlerter } from "app/helpers/customHooks";
import { getFilterQueryString } from "app/helpers/helperFunction";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import { Form, Formik } from "formik";
import { FC, useRef, useState } from "react";
import { connect } from "react-redux";

interface props {
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}
interface firmModel {
  Books: {
    Name: string;
    IsCashBook: boolean;
    FirmId: number;
    Id: number;
    IsActive: boolean;
  }[];
  Id: number;
  IsActive: boolean;
  Name: string;
}

interface tasks {
  Id: number;
  TaskCode: string;
  ClientId: number;
  ClientName: string;
}

const TRANSACTION_TYPE_OPTIONS = [
  { value: "false", name: "Receipt" },
  { value: "true", name: "Payment" },
];

const TRANSACTION_OPTIONS = [
  { value: TRANSACTION_TYPE["Advance"], name: "Advance" },
  { value: TRANSACTION_TYPE["GovernmentFees/Tax"], name: "GovernmentFees/Tax" },
  { value: TRANSACTION_TYPE["Reimbursement"], name: "Reimbursement" },
  { value: TRANSACTION_TYPE["Against Bill"], name: "Against Bill" },
  { value: TRANSACTION_TYPE["Against Reimbursement"], name: "Against Reimbursement"},
  { value: TRANSACTION_TYPE["Expense"], name: "Expense" },
  { value: TRANSACTION_TYPE["Other"], name: "Other" },
];

const getTaskDataBySelectedClient = (tasks: tasks[], clientId: string) => {
  return tasks?.reduce(
    (acc: { value: number; name: string }[], task: tasks) => {
      if (String(task.ClientId) === String(clientId))
        acc.push({ value: task.Id, name: task.TaskCode });
      return acc;
    },
    []
  );
};
const getBookDataBySelectedFirm = (firms: firmModel[], firmId: string) => {
  return firms?.reduce(
    (acc: { value: number; name: string }[], firm: firmModel) => {
      if (String(firm.Id) === String(firmId))
        return firm.Books.map((book) => ({ value: book.Id, name: book.Name }));
      return acc;
    },
    []
  );
};

export const TransactionReportFilter: FC<props> = ({ masterDataList }) => {
  const { filterObj, searchObj, setSearchParam } = useGetFilterObject();
  const [showDropDown, setShowDropDown] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, setShowDropDown);

  const resetHandler = (resetForm: any) => {
    delete searchObj.Filters;
    resetForm();
    setSearchParam({ ...searchObj, PageNumber: "1" });
  };

  return (
    <div className="position-relative" ref={wrapperRef}>
      <FilterButton
        setShowDropDown={setShowDropDown}
        showDropDown={showDropDown}
      />
      <div
        className={`shadow-sm card w-300px w-sm-400px w-md-500px task-filter ${
          showDropDown ? "" : "hidden"
        }`}
      >
        <div className="p-7">
          <div className="fs-5 text-dark fw-bolder">Filter</div>
        </div>
        <div className="separator border-gray-200"></div>
        <Formik
          enableReinitialize
          initialValues={filterObj}
          onSubmit={async (values: any) => {
            setSearchParam({
              ...searchObj,
              PageNumber: "1",
              Filters: getFilterQueryString(values),
            });
          }}
        >
          {({ values, isSubmitting, setFieldValue, resetForm }) => {
            return (
              <Form noValidate className="form">
                <div className="mb-10 px-9 pt-3">
                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Transaction Type
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          placeholder="Select Transaction Type"
                          options={TRANSACTION_TYPE_OPTIONS}
                          setFieldValue={setFieldValue}
                          name="IsPayment"
                          currentValue={{
                            value: values.IsPayment,
                          }}
                          defaultValue={{
                            value: values.IsPayment,
                            name: TRANSACTION_TYPE_OPTIONS.find(
                              (type: { value: string; name: string }) =>
                                String(type.value) === String(values.IsPayment)
                            )?.name,
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Type
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          placeholder="Select Type"
                          options={TRANSACTION_OPTIONS}
                          setFieldValue={setFieldValue}
                          name="Type"
                          currentValue={{
                            value: values.Type,
                          }}
                          defaultValue={{
                            value: values.Type,
                            name: values.Type
                              ? TRANSACTION_TYPE[Number(values.Type)]
                              : "",
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Client
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            masterDataList.loading
                              ? "display-dropdown-loader"
                              : ""
                          }`}
                          placeholder="Select Client"
                          displayLoader={masterDataList.loading}
                          options={masterDataList.masterData?.data?.records?.Clients?.map(
                            (client: { Id: number; Name: string }) => {
                              return { value: client.Id, name: client.Name };
                            }
                          )}
                          setFieldValue={setFieldValue}
                          name="ClientId"
                          currentValue={{
                            value: values.ClientId,
                            name: masterDataList.masterData?.data?.records?.Clients?.find(
                              (client: { Id: number; Name: string }) =>
                                String(client.Id) === String(values.ClientId)
                            )?.Name,
                          }}
                          defaultValue={{
                            value: values.ClientId,
                            name: masterDataList.masterData?.data?.records?.Clients?.find(
                              (client: { Id: number; Name: string }) =>
                                String(client.Id) === String(values.ClientId)
                            )?.Name,
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Task Code
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          placeholder="Select Task Code"
                          options={getTaskDataBySelectedClient(
                            masterDataList.masterData?.data?.records?.Tasks,
                            values.ClientId
                          )}
                          setFieldValue={setFieldValue}
                          name="TaskId"
                          currentValue={{
                            value: values.TaskId,
                            name: masterDataList.masterData?.data?.records?.Tasks?.find(
                              (task: { Id: number; TaskCode: string }) =>
                                String(task.Id) === String(values.TaskId)
                            )?.TaskCode,
                          }}
                          defaultValue={{
                            value: values.TaskId,
                            name: masterDataList.masterData?.data?.records?.Tasks?.find(
                              (task: { Id: number; TaskCode: string }) =>
                                String(task.Id) === String(values.TaskId)
                            )?.TaskCode,
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Firm
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            masterDataList.loading
                              ? "display-dropdown-loader"
                              : ""
                          }`}
                          placeholder="Select Firm"
                          displayLoader={masterDataList.loading}
                          options={masterDataList.masterData?.data?.records?.Firms?.map(
                            (firm: { Id: number; Name: string }) => {
                              return { value: firm.Id, name: firm.Name };
                            }
                          )}
                          setFieldValue={setFieldValue}
                          name="FirmId"
                          currentValue={{
                            value: values.FirmId,
                            name: masterDataList.masterData?.data?.records?.Firms?.find(
                              (firm: { Id: number; Name: string }) =>
                                String(firm.Id) === String(values.FirmId)
                            )?.Name,
                          }}
                          defaultValue={{
                            value: values.FirmId,
                            name: masterDataList.masterData?.data?.records?.Firms?.find(
                              (firm: { Id: number; Name: string }) =>
                                String(firm.Id) === String(values.FirmId)
                            )?.Name,
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Book
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          placeholder="Select Book"
                          options={getBookDataBySelectedFirm(
                            masterDataList.masterData?.data?.records?.Firms,
                            values.FirmId
                          )}
                          setFieldValue={setFieldValue}
                          name="BookId"
                          currentValue={{
                            value: values.BookId,
                            name: masterDataList.masterData?.data?.records?.Firms?.reduce(
                              (acc: string, firm: firmModel) => {
                                if (String(firm.Id) === String(values.FirmId)) {
                                  return (
                                    firm.Books.find(
                                      (book) =>
                                        String(book.Id) ===
                                        String(values.BookId)
                                    )?.Name || ""
                                  );
                                }
                                return acc;
                              },
                              ""
                            ),
                          }}
                          defaultValue={{
                            value: values.BookId,
                            name: masterDataList.masterData?.data?.records?.Firms?.reduce(
                              (acc: string, firm: firmModel) => {
                                if (String(firm.Id) === String(values.FirmId)) {
                                  return (
                                    firm.Books.find(
                                      (book) =>
                                        String(book.Id) ===
                                        String(values.BookId)
                                    )?.Name || ""
                                  );
                                }
                                return acc;
                              },
                              ""
                            ),
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Start Date
                      </label>

                      <div className="">
                        <CustomDatePicker
                          name="startDate"
                          placeholder="Start Date"
                          currentValue={
                            values.startDate ? new Date(values.startDate) : null
                          }
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6 required">
                        End Date
                      </label>

                      <div className="">
                        <CustomDatePicker
                          name="endDate"
                          placeholder="End Date"
                          currentValue={
                            values.endDate ? new Date(values.endDate) : null
                          }
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end pb-6 px-6 gap-3">
                  <ResetButton
                    name="Reset"
                    className="btn btn-light btn-active-light-primary btn-sm"
                    onClickCallback={() => {
                      resetHandler(resetForm);
                      setShowDropDown(false);
                    }}
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Apply"
                    className="btn btn-primary btn-sm"
                    onClickCallback={() => setShowDropDown(false)}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterDataList: state.masterData,
  };
};

export default connect(mapStateToProps)(TransactionReportFilter);
