import { connect } from "react-redux";
import { FC, useEffect } from "react";

import { adminHomePage } from "../../main/admin/AdminMain";
import { dailyTaskListPage } from "../DailyTaskList";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import { emptyObject } from "app/helpers/helperFunction";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import { getDailyTask } from "app/reducers/dailyTask/dailyTaskAction";
import Loader from "app/components/commonComponent/loader/Loader";
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers";
import { PageLink, PageTitle } from "_metronic/layout/core";
import { UserCustomHeader } from "../../../commonComponent/table/UserCustomHeader";
import { useParams } from "react-router-dom";

const HEADERS = [
  {
    columnName: "Task",
    id: "Task",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Work Status",
    id: "Status",
    className: "min-w-125px",
  },
];

interface props {
  dailyTaskDetail: any;
  getDailyTaskData: Function;
}

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  dailyTaskListPage,
  emptyObject,
];

const DailyTaskByArticleTable: FC<props> = ({
  dailyTaskDetail,
  getDailyTaskData,
}) => {
  const params = useParams();

  useEffect(() => {
    getDailyTaskData(params.id);
  }, [getDailyTaskData]);

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Assignee Task</PageTitle>
      <KTCard>
        <KTCardBody className="py-4">
          <div className="table-responsive horizontal-scroll">
            <table
              id="kt_table_users"
              className="table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer table-striped"
            >
              <thead>
                <tr className="text-start text-muted fw-bolder fs-7 gs-0">
                  <UserCustomHeader headers={HEADERS} />
                </tr>
              </thead>
              <ErrorBoundary>
                <tbody className="text-gray-600">
                  {dailyTaskDetail.loading && (
                    <tr>
                      <td colSpan={HEADERS.length}>
                        <Loader />
                      </td>
                    </tr>
                  )}
                  {dailyTaskDetail.error && (
                    <tr>
                      <td colSpan={HEADERS.length}>
                        <ErrorDisplay error={dailyTaskDetail.error} />
                      </td>
                    </tr>
                  )}
                  {dailyTaskDetail?.dailyTask?.success &&
                    !dailyTaskDetail?.dailyTask?.data?.records[0]?.Tasks
                      ?.length && (
                      <tr>
                        <td colSpan={HEADERS.length}>
                          <div className="d-flex text-center w-100 align-content-center justify-content-center">
                            No matching records found
                          </div>
                        </td>
                      </tr>
                    )}
                  {!dailyTaskDetail.loading &&
                    dailyTaskDetail?.dailyTask?.success &&
                    dailyTaskDetail?.dailyTask?.data?.records[0]?.Tasks
                      ?.length !== 0 &&
                    dailyTaskDetail?.dailyTask?.data?.records[0]?.Tasks?.map(
                      (row: any, i: number) => {
                        return (
                          <tr key={row.id || i}>
                            <td className="ps-2">{row.Description}</td>
                            <td className="ps-2">
                              {row.IsCompleted ? (
                                <span className="badge badge-light-success">
                                  Completed
                                </span>
                              ) : (
                                <span className="badge badge-light-warning">
                                  Pending
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </ErrorBoundary>
            </table>
          </div>
        </KTCardBody>
      </KTCard>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    dailyTaskDetail: state.dailyTask,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getDailyTaskData: (userNumber: number) =>
      dispatch(getDailyTask(userNumber)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyTaskByArticleTable);
