import DatePicker from "react-datepicker";
import { FC, ForwardedRef, forwardRef, useEffect, useState } from "react";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
// import TimePicker from "react-time-picker";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePickerProps } from "@mui/x-date-pickers/TimePicker";
import { TextFieldProps } from "@mui/material/TextField";
import { darkTheme, lightTheme } from "./MUITheme";

import { DOCUMENT_TYPE_REPEATATION } from "app/helpers/constants";
import moment from "moment";
import "./customTimePicker.scss";
import { createTheme, ThemeProvider } from "@mui/material";

interface props {
  name: string;
  placeholder: string;
  setFieldValue: Function;
  currentValue?: Date | null | undefined;
  timePeriod?: DOCUMENT_TYPE_REPEATATION;
  disabled?: boolean;
  TimeDisable?: boolean;
  TimeValue?: string;
}

const CustomDatePickerInput = forwardRef(
  (
    { onKeyDown, value, onClick, onChange, placeholder }: any,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <div
        className="d-flex align-items-center form-control form-control-lg form-control-solid p-0 px-3 cursor-pointer"
        onClick={onClick}
      >
        <input
          value={value}
          className="border-0 form-control form-control-solid p-0 custom-date-picker cursor-pointer"
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          ref={ref}
        />
        <i className="fa-solid fa-calendar-days"></i>
      </div>
    );
  }
);

// const CustomDatePicker: FC<props> = ({
//   disabled,
//   TimeDisable=true,
//   currentValue,
//   name,
//   placeholder,
//   setFieldValue,
//   timePeriod,
//   TimeValue
// }) => {
//   const [startDate, setStartDate] = useState<Date>();
//   const [time, setTime] = useState<string>("00:00");
//   const years = range(1990, getYear(new Date()) + 3, 1);
//   const months = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   const [selectedTime, setSelectedTime] = useState<string | null>(null);

//   const handleTimeChange: TimePickerProps<Date>['onChange'] = (newTime) => {
//     if (newTime) {
//       const formattedTime = newTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit',second : '2-digit' });

//       setSelectedTime(formattedTime);
//       setFieldValue(name, formattedTime);
//     } else {
//       setSelectedTime(null);
//     }
//   };

//   const [isDarkMode, setIsDarkMode] = useState<boolean>(true);

//   useEffect(() => {
//     const savedTheme = localStorage.getItem('kt_theme_mode_menu');

//     if (savedTheme === 'system') {
//       const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
//       setIsDarkMode(prefersDark);

//       const handleSystemThemeChange = (e: MediaQueryListEvent) => {
//         setIsDarkMode(e.matches);
//       };
//       window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleSystemThemeChange);

//       // Cleanup listener on component unmount
//       return () => {
//         window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handleSystemThemeChange);
//       };
//     } else if (savedTheme) {
//       setIsDarkMode(savedTheme === 'dark');
//     }
//   }, []);

//   return (
//     <>
//     {TimeDisable && (
//   <DatePicker
//   renderCustomHeader={({ date, changeYear, changeMonth }) => (
//     <div
//       style={{
//         margin: 10,
//         display: "flex",
//         justifyContent: "center",
//         gap: "1rem",
//       }}
//     >
//       <select
//         value={getYear(date)}
//         onChange={({ target: { value } }) => changeYear(Number(value))}
//         className="py-1 px-2 rounded-1"
//       >
//         {years.map((option: number) => (
//           <option key={option} value={option}>
//             {option}
//           </option>
//         ))}
//       </select>

//       {!(timePeriod === DOCUMENT_TYPE_REPEATATION.Yearly) && (
//         <select
//           className="py-1 px-2 rounded-1"
//           value={months[getMonth(date)]}
//           onChange={({ target: { value } }) =>
//             changeMonth(months.indexOf(value))
//           }
//         >
//           {months.map((option) => (
//             <option key={option} value={option}>
//               {option}
//             </option>
//           ))}
//         </select>
//       )}
//     </div>
//   )}
//   selected={currentValue !== undefined ? currentValue : startDate}
//   onChange={(date: Date) => {
//     setStartDate(date);
//     setFieldValue(name, moment(date).format("YYYY/MM/DD"));
//   }}
//   customInput={<CustomDatePickerInput />}
//   name={name}
//   placeholderText={placeholder}
//   onKeyDown={(e: any) => e.preventDefault()}
//   dateFormat={
//     (timePeriod === DOCUMENT_TYPE_REPEATATION.Yearly && "yyyy") ||
//     (timePeriod === DOCUMENT_TYPE_REPEATATION.Monthly && "MM/yyyy") ||
//     (timePeriod === DOCUMENT_TYPE_REPEATATION.Quarterly && "yyyy, QQQ") ||
//     "dd/MM/yyyy"
//   }
//   showYearPicker={timePeriod === DOCUMENT_TYPE_REPEATATION.Yearly}
//   showQuarterYearPicker={timePeriod === DOCUMENT_TYPE_REPEATATION.Quarterly}
//   showMonthYearPicker={timePeriod === DOCUMENT_TYPE_REPEATATION.Monthly}
//   disabled={disabled}

//   //dateFormat="dd/MM/yyyy"
//   // dateFormat="yyyy, QQQ"
//   // dateFormat="MM/yyyy"
//   // dateFormat="yyyy"
// />
//     )}

// { !TimeDisable && (
//   <>
//   {/* <TimePicker
//    onChange={handleTimeChange}
//    value={TimeValue}
//    className="mt-2 form-control form-control-lg form-control-solid w-100"
//    clockIcon={<i className="fa-solid fa-clock"></i>}
//  /> */}

// <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
// <LocalizationProvider dateAdapter={AdapterDateFns}>
//       <TimePicker
//         className="w-100 w-sm-auto"
//         // label="Basic time picker"
//         value={TimeValue ? new Date(`1970-01-01T${TimeValue}`) : null}
//         onChange={handleTimeChange}
//         // renderInput={(params:any) => <TextField {...params} />}
//       />
//     </LocalizationProvider>
// </ThemeProvider>
//  </>
// )}

//   </>
//   );
// };

const CustomDatePicker: FC<props> = ({
  disabled,
  TimeDisable = true,
  currentValue,
  name,
  placeholder,
  setFieldValue,
  timePeriod,
  TimeValue,
}) => {
  const [startDate, setStartDate] = useState<Date | null | undefined>(currentValue);
  const [time, setTime] = useState<string>("00:00");
  const years = range(1990, getYear(new Date()) + 3, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [selectedTime, setSelectedTime] = useState<string | null>(null);

  const handleTimeChange: TimePickerProps<Date>["onChange"] = (newTime) => {
    if (newTime) {
      const formattedTime = newTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      setSelectedTime(formattedTime);
      setFieldValue(name, formattedTime);
    } else {
      setSelectedTime(null);
    }
  };

  const [isDarkMode, setIsDarkMode] = useState<boolean>(true);

  useEffect(() => {
    setStartDate(currentValue);
}, [currentValue]);

useEffect(() => {
  if (!currentValue) return;

  const date = new Date(currentValue);
  if (isNaN(date.getTime())) return;

  const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

  setFieldValue(name, utcDate.toISOString().split("T")[0]); 
}, []);


  useEffect(() => {
    const savedTheme = localStorage.getItem("kt_theme_mode_menu");

    if (savedTheme === "system") {
      const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
      setIsDarkMode(prefersDark);

      const handleSystemThemeChange = (e: MediaQueryListEvent) => {
        setIsDarkMode(e.matches);
      };
      window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", handleSystemThemeChange);

      return () => {
        window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", handleSystemThemeChange);
      };
    } else if (savedTheme) {
      setIsDarkMode(savedTheme === "dark");
    }
  }, []);

  const handleClearDate = () => {
    setStartDate(null);
    setFieldValue(name, null);
  };

  return (
    <>
      {TimeDisable && (
        <div className="position-relative d-flex align-items-center">
          <DatePicker
            renderCustomHeader={({ date, changeYear, changeMonth }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <select
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(Number(value))}
                  className="py-1 px-2 rounded-1"
                >
                  {years.map((option: number) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                {!(timePeriod === DOCUMENT_TYPE_REPEATATION.Yearly) && (
                  <select
                    className="py-1 px-2 rounded-1"
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            )}
            selected={currentValue !== undefined ? currentValue : startDate}
            onChange={(date: Date | null) => {
              setStartDate(date);
              setFieldValue(name, date ? moment(date).format("YYYY/MM/DD") : "");
            }}
            customInput={<CustomDatePickerInput />}
            name={name}
            placeholderText={placeholder}
            onKeyDown={(e: any) => e.preventDefault()}
            dateFormat={
              (timePeriod === DOCUMENT_TYPE_REPEATATION.Yearly && "yyyy") ||
              (timePeriod === DOCUMENT_TYPE_REPEATATION.Monthly && "MM/yyyy") ||
              (timePeriod === DOCUMENT_TYPE_REPEATATION.Quarterly && "yyyy, QQQ") ||
              "dd/MM/yyyy"
            }
            showYearPicker={timePeriod === DOCUMENT_TYPE_REPEATATION.Yearly}
            showQuarterYearPicker={timePeriod === DOCUMENT_TYPE_REPEATATION.Quarterly}
            showMonthYearPicker={timePeriod === DOCUMENT_TYPE_REPEATATION.Monthly}
            disabled={disabled}
          />
         
          {startDate && (
            <i
              className="fa-solid fa-xmark position-absolute cursor-pointer"
              style={{right: "30px", top: "50%", transform: "translateY(-50%)",
              }}
              onClick={handleClearDate}
            ></i>
          )}
        </div>
      )}

      {!TimeDisable && (
        <>
          <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                className="w-100 w-sm-auto"
                value={TimeValue ? new Date(`1970-01-01T${TimeValue}`) : null}
                onChange={handleTimeChange}
              />
            </LocalizationProvider>
          </ThemeProvider>
        </>
      )}
    </>
  );
};


export default CustomDatePicker;
