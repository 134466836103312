import { absolutePath } from "app/helpers/relativePath";
import { adminHomePage } from "../main/admin/AdminMain";
import { BILL_LIST } from "app/helpers/routes";
import BillingTable from "./component/BillingTable";
import BillFilter from "./component/BillFilter";
import { emptyObject } from "app/helpers/helperFunction";
import { KTCard } from "_metronic/helpers";
import { PageLink, PageTitle } from "_metronic/layout/core";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent";
import { PAYMENT_STATUS } from "app/helpers/constants";
import { connect } from "react-redux";
import { billListState } from "app/reducers/billing/getBillListReducer";
import { FC, useMemo } from "react";
import InvoiceAmountDetail from "./component/InvoiceAmountDetail";

interface props {
  billingDetails: billListState;
}

export const billingListPage = {
  title: "Invoice",
  path: absolutePath(BILL_LIST),
  isSeparator: true,
  isActive: true,
};

const BILL_STATUS_OPTIONS = [
  { value: PAYMENT_STATUS["Payment Pending"], name: "Payment Pending" },
  { value: PAYMENT_STATUS["Partially Paid"], name: "Partially Paid" },
  { value: PAYMENT_STATUS["Fully Paid"], name: "Fully Paid" },
];

const accountBreadCrumbs: Array<PageLink> = [adminHomePage, emptyObject];

const BillingList: FC<props> = (billingDetails) => {
  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Invoice</PageTitle>
      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4 position-relative">
        <UsersListSearchComponent />
        <BillFilter BILL_STATUS_OPTIONS={BILL_STATUS_OPTIONS} />
      </div>
      <InvoiceAmountDetail Total={{TotalAmount: billingDetails?.billingDetails?.billList?.data?.records?.TotalAmount, TotalDueAmount: billingDetails?.billingDetails?.billList?.data?.records?.TotalDueAmount}} /> 
      <KTCard>
        <BillingTable />
      </KTCard>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    billingDetails: state.billList,
  };
};

export default connect(mapStateToProps, null)(BillingList);
