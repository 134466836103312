import { response } from "app/helpers/commonInterface";
import {
  GET_ADVANCE_PAYMENT_MAPPINGS_FAILURE,
  GET_ADVANCE_PAYMENT_MAPPINGS_REQUEST,
  GET_ADVANCE_PAYMENT_MAPPINGS_SUCCESS,
  GET_CLIENT_ADVANCE_PAYMENT_FAILURE,
  GET_CLIENT_ADVANCE_PAYMENT_REQUEST,
  GET_CLIENT_ADVANCE_PAYMENT_SUCCESS,
} from "../actionTypes";
import { billData } from "./billingDataInterface";
import { AdvancePaymentMapping } from "../transaction/transactionDataInterface";

export interface advancePaymentMappingState{
  loading: boolean;
  advancePaymentMappings: response<AdvancePaymentMapping[]>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: advancePaymentMappingState = {
  loading: false,
  advancePaymentMappings: {},
  error: "",
};

export const getAdvancePaymentMappingsReducer = (
  state = initialState,
  action: action
) => {
  switch (action.type) {
    case GET_ADVANCE_PAYMENT_MAPPINGS_REQUEST:
      return {
        loading: true,
      };
    case GET_ADVANCE_PAYMENT_MAPPINGS_SUCCESS:
      return {
        loading: false,
        advancePaymentMappings: action.payload,
        error: "",
      };
    case GET_ADVANCE_PAYMENT_MAPPINGS_FAILURE:
      return {
        loading: false,
        advancePaymentMappings: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
