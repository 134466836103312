import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import AddFirm from "./component/AddFirm";
import {
  addFirm,
  getFirm,
  updateFirm,
} from "app/reducers/firmMaster/firmMasterAction";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import Loader from "app/components/commonComponent/loader/Loader";
import { generalFileUpload } from "app/reducers/task/taskAction";
import { addFileObject, searchParams } from "app/helpers/commonInterface";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import { getLegalStatusList } from "app/reducers/client/clientAction";

export interface addFirmValue {
  id?: number;
  name: string;
  hasGST: boolean;
  taxRate: number;
  address: string;
  isCAFirm: boolean;
  gstin: string;
  // bankDetails: string;
  ifscCode: string;
  accountNumber: string;
  bankName: string;
  panNumber: string;
  qrCodeFile?: addFileObject | null;
  signatureFile?: addFileObject | null;
  personName: string;
  designation: string;
  billSeries: string;
  billTypeSeries: string;
}

interface editFirmData {
  Id: number;
  Name: string;
  HasGST: boolean;
  TaxRate: number;
  QRCodeFile?: addFileObject | null;
  SignatureFile?: addFileObject | null;
  PersonName: string;
  Designation: string;
  Address: string;
  IsCAFirm: boolean;
  GSTIN: string;
  // BankDetails: string;
  PANNumber: string;
  IFSCCode: string;
  AccountNumber: string;
  BankName: string;
  BillSeries: string;
  BillTypeSeries: string;
}

interface props {
  updateFirmDispatch: Function;
  addFirmDispatch: Function;
  getFirmDetailDispatch: Function;
  firmDetails: any;
  uploadFileDispatch: Function;
  legalStatusList: any;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
  getLegalStatusListDispatch:Function
}

export const Designation = [
  { value: "", name: "Select Designation" },
  { value: "Partner", name: "Partner" },
  { value: "Proprietor", name: "Proprietor" },
  { value: "Karta", name: "Karta" },
  { value: "Director", name: "Director" },
];


const initialValue = {
  name: "",
  hasGST: false,
  taxRate: 0,
  address: "",
  isCAFirm: false,
  gstin: "",
  // bankDetails: "",
  ifscCode: "",
  accountNumber: "",
  bankName: "",
  panNumber: "",
  qrCodeFile: null,
  billSeries: "",
  billTypeSeries: "",
  designation: "",
  personName: "",
  signatureFile: null,
};
const getValueForEdit = (firmData: editFirmData): addFirmValue => {
  return {
    id: firmData.Id,
    name: firmData.Name,
    hasGST: firmData.HasGST,
    taxRate: firmData.TaxRate,
    address: firmData.Address,
    isCAFirm: firmData.IsCAFirm,
    gstin: firmData.GSTIN,
    // bankDetails: firmData.BankDetails,
    ifscCode: firmData.IFSCCode,
    accountNumber: firmData.AccountNumber,
    bankName: firmData.BankName,
    panNumber: firmData.PANNumber,
    qrCodeFile: firmData.QRCodeFile,
    billSeries: firmData.BillSeries,
    billTypeSeries: firmData.BillTypeSeries,
    signatureFile: firmData.SignatureFile,
    personName: firmData.PersonName,
    designation: firmData.Designation,
  };
};

const FirmMasterForm: FC<props> = ({
  firmDetails,
  updateFirmDispatch,
  addFirmDispatch,
  getFirmDetailDispatch,
  uploadFileDispatch,
  legalStatusList, 
  masterDataList,
  getLegalStatusListDispatch
}) => {
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getFirmDetailDispatch(params.id);
    }
  }, [getFirmDetailDispatch, params.id]);

  useEffect(()=>{
    getLegalStatusListDispatch({IsActive : true})
  },[getLegalStatusListDispatch])

  return (
    <ErrorBoundary>
      {params.id && firmDetails.loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {params.id && firmDetails.error && (
        <ErrorDisplay error={firmDetails.error} />
      )}
      {(params.id ? firmDetails?.firm?.success : true) && (
        <>
          {params.id && (
            <AddFirm
              initialValue={getValueForEdit(firmDetails.firm.data.records)}
              firmDispatch={updateFirmDispatch}
              title="Edit Firm"
              uploadFileDispatch={uploadFileDispatch}
              legalStatusList={legalStatusList.legalStatusList}
              masterDataList={masterDataList}
            />
          )}
          {!params.id && (
            <AddFirm
              initialValue={initialValue}
              firmDispatch={addFirmDispatch}
              title="Add Firm"
              uploadFileDispatch={uploadFileDispatch}
              legalStatusList={legalStatusList.legalStatusList}
              masterDataList={masterDataList}
            />
          )}
        </>
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    firmDetails: state.firm,
    legalStatusList : state.legalStatusList,
    masterDataList: state.masterData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getFirmDetailDispatch: (firmId: number) => dispatch(getFirm(firmId)),
    addFirmDispatch: (data: addFirmValue, successCallback: Function) =>
      dispatch(addFirm(data, successCallback)),
    updateFirmDispatch: (data: addFirmValue, successCallback: Function) =>
      dispatch(updateFirm(data, successCallback)),
    uploadFileDispatch: (file: any, successCallback: Function) =>
      dispatch(generalFileUpload(file, successCallback)),
    getLegalStatusListDispatch:(SearchObj:searchParams)=>
          dispatch(getLegalStatusList(SearchObj))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmMasterForm);
