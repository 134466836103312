import { connect } from "react-redux";
import { FC, useEffect } from "react";

import ClientTableRow from "./ClientTableRow";
import { getClientList } from "../../../../reducers/client/clientAction";
import { searchParams } from "../../../../helpers/commonInterface";
import Table from "app/components/commonComponent/table/Table";
import { clientListState } from "app/reducers/client/getClientListReducer";
import { useGetSearchObject } from "app/helpers/customHooks";

const HEADERS = [
  {
    columnName: "Client Name",
    id: "Name",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Group Name",
    id: "GroupName",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Firm Name",
    id: "NULL", 
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Coordinator 1",
    id: "NULL", 
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Coordinator 2",
    id: "NULL", 
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Legal Status",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Work Status",
    id: "IsActive",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-sm-125px min-w-400px text-end pe-5",
  },
];

interface props {
  clientDetails: clientListState;
  getClientList: Function;
}

const ClientTable: FC<props> = ({ clientDetails, getClientList }) => {
  const { searchObj } = useGetSearchObject();

  useEffect(() => {
    getClientList(searchObj);
  }, [getClientList, searchObj]);

  return (
    <>
      <Table
        checkBoxColumn={{ show: false }}
        error={clientDetails.error}
        headers={HEADERS}
        success={clientDetails.clientList?.success}
        loading={clientDetails.loading}
        paginate={{
          show: true,
          pagination: clientDetails.clientList?.pagination,
          PageNumber: Number(searchObj.PageNumber || 1),
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={clientDetails.clientList?.data?.records}
        tableRow={(row: any, index: number) => (
          <ClientTableRow row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    clientDetails: state.clientList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getClientList: (searchObj: searchParams) =>
      dispatch(getClientList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientTable);
