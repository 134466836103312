import { PageLink, PageTitle } from "_metronic/layout/core";
import { searchParams } from "app/helpers/commonInterface";
import {
  getDocumentReportList,
  selectAllDocument,
  unSelectAllDocument,
} from "app/reducers/clientDocuments/clientDocumentsAction";
import { documentReportState } from "app/reducers/clientDocuments/clientDocumentsReportListReducer";
import { FC, useEffect } from "react";
import { connect } from "react-redux";
import DocumentGrouping from "./component/DocumentGrouping";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent";
import DocumentFilter from "./component/DocumentFilter";
import { KTCard } from "_metronic/helpers";
import { useGetSearchObject } from "app/helpers/customHooks";
import Table from "app/components/commonComponent/table/Table";
import DocumentTableRow from "./component/DocumentTableRow";
import { selctedDocumentList } from "./DocumentsList";
import { adminHomePage } from "../main/admin/AdminMain";
import { emptyObject } from "app/helpers/helperFunction";

interface props {
  documentReportList: documentReportState;
  getDocumentReportListDispatch: Function;
  selectedDocumentDetails: selctedDocumentList;
  selectAllDocumentDispatch: Function;
  unSelectAllDocumentDispatch: Function;
}

const HEADERS = [
  {
    columnName: "Document Name",
    id: "DocumentType",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Document Year",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Task",
    id: "TaskCode",
    className: "min-w-125px",
  },
  {
    columnName: "Client Name",
    id: "ClientName",
    className: "min-w-125px",
  },
  {
    columnName: "Upload Date",
    id: "DateAdded",
    className: "min-w-125px",
  },
  {
    columnName: "Upload By",
    id: "UploadedBy",
    className: "min-w-125px",
  },
];

const accountBreadCrumbs: Array<PageLink> = [adminHomePage, emptyObject];

const DocumentsReportList: FC<props> = ({
  getDocumentReportListDispatch,
  documentReportList,
  selectedDocumentDetails,
  selectAllDocumentDispatch,
  unSelectAllDocumentDispatch,
}) => {
  const { searchObj } = useGetSearchObject();

  useEffect(() => {
    return () => unSelectAllDocumentDispatch();
  }, []);

  useEffect(() => {
    getDocumentReportListDispatch(searchObj);
  }, [searchObj]);

  const onChangeSelectAllHandler = (e: any) => {
    if (e.target.checked) {
      selectAllDocumentDispatch();
    } else {
      unSelectAllDocumentDispatch();
    }
  };

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Document Report</PageTitle>
      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4 position-relative">
        {selectedDocumentDetails.selected.length > 0 ? (
          <>
            <DocumentGrouping />
          </>
        ) : (
          <>
            <UsersListSearchComponent />
            <DocumentFilter />
          </>
        )}
      </div>
      <KTCard>
        <Table
          checkBoxColumn={{
            show: true,
            checked: selectedDocumentDetails.isCheckedAll,
            onChangeFun: onChangeSelectAllHandler,
          }}
          paginate={{
            show: true,
            pagination: documentReportList.documentReportDetails?.pagination,
            PageNumber: Number(searchObj.PageNumber || 1),
            PageSize: Number(searchObj.PageSize) || 10,
          }}
          error={documentReportList.error}
          headers={HEADERS}
          success={documentReportList.documentReportDetails?.success}
          loading={documentReportList.loading}
          tableData={documentReportList.documentReportDetails?.data?.records}
          tableRow={(row: any, index: number) => (
            <DocumentTableRow
              row={row}
              key={`row-${index}-${row.id}`}
              selectedDocumentDetails={selectedDocumentDetails}
              isReport={true}
            />
          )}
        />
      </KTCard>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    documentReportList: state.clientDocumentReportDetails,
    selectedDocumentDetails: state.selectedDocumentList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getDocumentReportListDispatch: (searchObj: searchParams) =>
      dispatch(getDocumentReportList(searchObj)),
    unSelectAllDocumentDispatch: () => dispatch(unSelectAllDocument()),
    selectAllDocumentDispatch: () => dispatch(selectAllDocument()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsReportList);
