import {
  ADD_CHAT_LIST_DATA_SOCKET_STATE,
  ADD_DATA_SOCKET_STATE,
  ADD_SOCKET_INSTANCE,
} from "../actionTypes";
import { success } from "../dispatchFunctions";
import { socketChatState, socketDataChangeState } from "./websocketReducer";

export const addWebsocketData = (
  socketData: socketChatState | socketDataChangeState
) => {
  return (dispatch: any) => {
    dispatch(success(ADD_DATA_SOCKET_STATE, socketData));
  };
};

export const addChatListData = (socketData: {
  taskId: number | null;
  chatList: socketChatState[];
  currentPage: number;
  totalRecords: number;
}) => {
  return (dispatch: any) => {
    dispatch(success(ADD_CHAT_LIST_DATA_SOCKET_STATE, socketData));
  };
};

export const addSocketInstance = (socketInstance: WebSocket) => {
  return (dispatch: any) => {
    dispatch(success(ADD_SOCKET_INSTANCE, socketInstance));
  };
};
