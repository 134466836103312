import Table from "app/components/commonComponent/table/Table";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import ReimbursementInvoiceTableRow from "./AdvancePaymentMappingsTableRow";
import { searchParams } from "app/helpers/commonInterface";
import { getReimbursementInvoiceList } from "app/reducers/reimbursement/reimbursementAction";
import { useGetSearchObject } from "app/helpers/customHooks";
import { ClassNames } from "@emotion/react";
import AdvancePaymentMappingsTableRow from "./AdvancePaymentMappingsTableRow";
import { getAdvancePaymentMappings } from "app/reducers/billing/billingAction";

interface props {
  advancePaymentMappingDetails: any;
  advancePaymentMappingListDispatch: Function;
}

const HEADERS = [
  {
    columnName: "Client Name",
    id: "ClientName",
    className: "min-w-125px",
  },
  {
    columnName: "Firm Name",
    id: "FirmName",
    className: "min-w-125px",
  },
  {
    columnName: "Invoice/Reimb. No.",
    id: "BillNumber",
    className: "min-w-150px",
  },
  {
    columnName: "Total Advance",
    id: "ReceiptAmount",
    className: "min-w-125px",
  },
  {
    columnName: "Used Amount",
    id: "UsedAmount",
    className: "min-w-125px",
  },
  {
    columnName: "Date",
    id: "Date",
    className: "min-w-125px",
  },
];

const AdvancePaymentMappingTable: FC<props> = ({
  advancePaymentMappingDetails,
  advancePaymentMappingListDispatch,
}) => {
  const { searchObj } = useGetSearchObject();
  const [showBillEditForm, setShowBillEditForm] = useState<{
    show: boolean;
    billId: number | undefined;
  }>({
    show: false,
    billId: 0,
  });

  useEffect(() => {
    advancePaymentMappingListDispatch(searchObj);
  }, [advancePaymentMappingListDispatch, searchObj]);

  return (
    <>
      <Table
        checkBoxColumn={{ show: false }}
        error={advancePaymentMappingDetails.error}
        headers={HEADERS}
        success={advancePaymentMappingDetails.advancePaymentMappings?.success}
        loading={advancePaymentMappingDetails.loading}
        paginate={{
          show: true,
          pagination:
            advancePaymentMappingDetails.advancePaymentMappings?.pagination,
          PageNumber: Number(searchObj.PageNumber || 1),
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={
          advancePaymentMappingDetails.advancePaymentMappings?.data?.records
        }
        tableRow={(row: any, index: number) => (
          <AdvancePaymentMappingsTableRow
            row={row}
            key={`row-${index}-${row.id}`}
            //setShowBillEditForm={setShowBillEditForm}
          />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    advancePaymentMappingDetails: state.advancePaymentMappings,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    advancePaymentMappingListDispatch: (searchObj: searchParams) =>
      dispatch(getAdvancePaymentMappings(searchObj)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancePaymentMappingTable);
